import React, { FC, ReactElement, useState } from 'react';
import { Upload as AntUpload, Button, message, Spin } from 'antd';
import { UploadProps, ShowUploadListInterface } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import { downloadFile, showFile } from '@/utils/mainUtils'
import styles from './UploadComponent.module.scss';
import { baseURL, getToken } from '@/request'
import { useTranslation } from 'react-i18next';
import {IState} from "@/redux/interface";
import {useMappedState} from "redux-react-hook";

const showTypes = ['text/plain', 'application/pdf', 'text/html'];
export interface IUploadComponentProps extends UploadProps {
  name?: string;
  action?: string,
  icon?: React.ReactNode;
  text?: string;
  extra?: string;
  accept?: string;
  buttonClass?: any;
  extraClassName?: string;
  acceptMultiple?: boolean,
  disabled?: boolean,
  onChange?: (args: any) => void,
  fileList?: any[],
  children?: any;
  multiple?: boolean;
  data?: any; // Parameters carried in addition to file
  maxCount?: number; // How many are uploaded
  uploadNow?: boolean; // Upload now
  isOcr?: boolean; // is Ocr
  fileSize?: number; // File size
  setFileList?: (data: any[]) => void;
  userId?: number | string;
  isNoMessage?: boolean;
}

interface IGetUploadProps {
  name: string;
  action: string,
  accept: string;
  disabled: boolean,
  formItemOnChange?: (args: any) => void,
  uploadNow?: boolean; // Upload now
  isOcr?: boolean; // is Ocr
  setUploadLoading?: (flag: boolean) => void;
  setLoadingText?: (text: string) => void;
  setShowList?: (flag: boolean) => void;
  fileSize?: number; // File size
  setFileList?: (data: any[]) => void;
  isNoMessage?: boolean;
}

const getUploadProps = (e: IGetUploadProps) => {
  const { name = 'file', action, accept, disabled, formItemOnChange, uploadNow, isOcr, setLoadingText, setUploadLoading, setShowList, fileSize = 20, setFileList, isNoMessage } = e;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {t, i18n} = useTranslation();

  const props = {
    name,
    action,
    headers: {
      authorization: 'authorization-text',
    },
    accept,
    beforeUpload(file: any, fileList: any) {
      if (disabled) {
        return false;
      }
      if (!uploadNow) { // Instead of uploading immediately, the current data is called back through the onchange method passed down from the component
        formItemOnChange && formItemOnChange(file);
        return false;
      }
      if (file.size > fileSize * 1024 * 1024) {
        message.error(`${t("上传失败。附件的上载大小限制为")} ${fileSize} M`, 2)
        setShowList && setShowList(false);
        return false;
      }
      const exts = (file.name || '').split('.');
      if (exts.length > 0) {
        const ext = exts.pop().toLowerCase(); // Convert all to lowercase
        const ok = accept.split(',').some(e => {
          return e && e.trim().substr(1) === ext;
        });
        if (!ok) {
          setShowList && setShowList(false);
          message.error(`${t("请上传")}"${accept}"${t("类型文件")}`, 2);
          return false;
        }
        message.loading({
          content: t("正在上传，请稍候。。。"),
          key: 'uploadLoading',
          duration: 0,
        });
        setUploadLoading?.(true);
        return true;
      }
      return false;
    },
    disabled: disabled || false,
    onChange(...args: any) {
      const info = args[0] || {};
      // if (info?.fileList?.length === maxCount) {
      //   message.destroy('uploadLoading');
      // }
      const { fileList = [] } = info || {};
      setFileList?.([...fileList])
      if (info.file.status && info.file.status !== 'uploading') {
        if (isOcr) {
          const timer: any = setTimeout(() => {
            setLoadingText && setLoadingText('')
            setUploadLoading && setUploadLoading(false);
            timer && clearTimeout(timer);
          }, 1000);
        } else {
          message.destroy('uploadLoading');
        }
        if (formItemOnChange && info.file.status === 'done') {
          formItemOnChange(info);
          setUploadLoading?.(false);
        }
        if (info.file.status === 'done') {
          // message.success(`${info.file.name} upload succeed`, 2);
          if (!isNoMessage) message.success(t("上传成功！"), 2);
        } else if (info.file.status === 'error') {
          // message.error(`${info.file.name} upload fail, ${info.file?.response?.error?.details}`, 2);
          message.error(`${t("上传失败！")} ${info.file?.response?.error?.details}`, 2);
        }
      }
    },
    onPreview(file: any) {
      const { type, response: { data } } = file;
      const { fileUrl } = data;
      const newType = type.toLowerCase();
      const types = newType?.split("/");
      const type1 = types.length > 1 && types[0];
      if (type1 === 'image' || showTypes.includes(type)) {
        showFile(fileUrl);
      } else {
        downloadFile(fileUrl, file.name);
      }
    },
    onRemove() {
      
    },
  };
  return props;
}

const UploadComponent: FC<IUploadComponentProps> = (props: IUploadComponentProps) => {
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const {
    name = 'file',
    action = 'remit-service/api/file/fileUpload',
    data,
    icon = <UploadOutlined />,
    text = 'Upload file',
    extra,
    accept = '.jpg,.png,.jpeg,.bmp,.gif',
    buttonClass = '',
    extraClassName = '',
    disabled = false,
    onChange: formItemOnChange,
    fileList,
    multiple = false,
    children,
    maxCount,
    uploadNow = true,
    isOcr,
    showUploadList = true,
    fileSize = 20,
    userId,
    setFileList,
    isNoMessage = false,
    ...uploadProps
  } = props;
  const extraText = extra || `Support extension: ${accept}`;
  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>('');
  const [showList, setShowList] = useState<boolean | ShowUploadListInterface>(showUploadList);
  return (
    <AntUpload
      className={styles.root}
      {...getUploadProps({ name, action, accept, disabled, formItemOnChange, uploadNow, isOcr, setUploadLoading, setLoadingText, setShowList, fileSize, setFileList, isNoMessage })}
      action={`${baseURL}${action}`}
      data={data}
      maxCount={maxCount}
      showUploadList={showList}
      fileList={fileList}
      multiple={multiple}
      headers={{
        Authorization: getToken(),
        Version: '2.0.0',
        userId: userId ? String(userId) : String(userInfoState.id || '')
      }}
      {...uploadProps}
    >
      <>
        {
          children ? children : (
            <Button className={styles.disabledBtn} disabled={disabled}>
              <Button className={`${styles.bgWhite} ${buttonClass}`}>
                {icon}
                {text}
              </Button>
              <span className={`${styles.extra} ${extraClassName}`}>
                {extraText}
              </span>
            </Button>
          )
        }
      </>
    </AntUpload>
  )
}

export default UploadComponent;
