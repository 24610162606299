import React, {FC, useState, useEffect, useMemo, useImperativeHandle, forwardRef} from 'react'
import {Table, Pagination, Form, Space} from 'antd';
import styles from './Table.module.scss';
import moment from 'moment';
// import { FilterForm, Buttons } from '@/components';
import {IBtns} from '@/components/FormWrap/components/FooterBtns/FooterBtns';
import _ from 'lodash';

// const { SearchButton, ResetButton } = Buttons;

interface TableComponentProps {
	filterData?: any;
	columns: any;
	pageTitle?: string;
	pageHeadBtn?: React.ReactNode;
	titleStyle?: React.CSSProperties;
	formClassName?: string;
	showPagination?: boolean;
	showSizeChanger?: boolean;
	getListFun?: (formData?: any, secondParameter?: any) => any;
	btnArr?: IBtns[];
	paginationStyle?: React.CSSProperties;
	formInitialValues?: any;
	dataSource?: any;
	tableStyle?: React.CSSProperties;
	isUpdate?: boolean; // Re request
	setIsUpdate?: (flag: boolean) => void; // Modify status after request
	isShowAddBtn?: boolean;
	tabsNode?: React.ReactNode;
	parentForm?: any;
	cRef?: any; // Ref received from parent component
	secondParameter?: any
	isHasAll?: boolean;
	filterWarpStyle?: React.CSSProperties;
	recordKey?: string;
	totalTips?: string;
	isEmpty?: (e: boolean) => void;
	scrollX?: number | string;
}


const TableComponent: FC<TableComponentProps> = (props) => {
	const formatText = "DD-MM-YYYY"
	const [form] = Form.useForm();
	const currentForm = props?.parentForm || form;
	const defaultBtn: IBtns[] = [
		{
			title: 'Search',
			hide: false,
			type: 'primary',
			submit: true,
			className: styles.btnItem
		},
		{
			title: "Reset",
			hide: false,
			className: styles.btnItem,
			click: () => {
				currentForm.resetFields();
				getList(1, 8, formInitialValues);
			},
		}
	]
	const {
		columns,
		filterData = [],
		pageTitle,
		pageHeadBtn,
		titleStyle,
		formClassName,
		showPagination = true,
		showSizeChanger = true,
		getListFun,
		paginationStyle,
		btnArr = defaultBtn,
		formInitialValues,
		dataSource = [],
		tableStyle,
		isUpdate,
		setIsUpdate,
		isShowAddBtn,
		tabsNode,
		cRef,
		secondParameter,
		isHasAll,
		filterWarpStyle,
		recordKey = 'id',
		totalTips = 'items',
		scrollX = "auto",
		isEmpty,
		...tableProps
	} = props;
	const [tableData, setTableData] = useState(dataSource);
	const [tableLoading, setTableLoading] = useState<boolean>(false);

	const returnFormData = (formObj: any) => {
		let values = _.cloneDeep(formObj);
		const initialValues = _.cloneDeep(formInitialValues);
		if (initialValues) values = Object.assign(initialValues, values)
		return values;
	}

	const onShowSizeChange = (current?: number, pageSize?: number) => {
		if (current) {
			// window.scrollTo(0, 0)
			const formObj = localStorage.getItem('formValue');
			if (formObj) {
				const newObject = JSON.parse(formObj);
				delete newObject.pageNum;
				delete newObject.pageSize;
				localStorage.setItem('formValue', _.isEmpty(newObject) ? '' : JSON.stringify(newObject))
			}
			const values = returnFormData(filterData.length > 0 ? currentForm.getFieldsValue() : {})
			getList(current, pageSize, returnForm(values));
		}
	};

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 8,
		pageSizeOptions: ['8', '20', '50', '80'],
		showSizeChanger,
		total: 8,
		showTotal: (total: any) => `Total ${total} ${totalTips}`,
		// onChange: handlePaginationChange,
		// showSizeChanger: true,
		// onShowSizeChange: handlePaginationShowSizeChange
	});

	useImperativeHandle(cRef, () => ({
		// setFilterConditions is a method exposed to the parent component to obtain filter conditions. It is mainly used for caching filter conditions. The parent component can call it through childref.current.setFilterConditions
		setFilterConditions: () => {
			const obj: any = {};
			const {current, pageSize} = pagination;
			const filterObj = {pageNum: current, pageSize, ...currentForm.getFieldsValue()}
			for (const key in filterObj) {
				if (filterObj[key]) obj[key] = filterObj[key];
			}
			localStorage.setItem('formValue', JSON.stringify(obj))
		},
		setLoading: (boolean: boolean) => {
			setTableLoading(boolean)
		}
	}));

	const getList = async (pageNum: number = 1, pageSize: number = 20, values?: any) => {
		if (!getListFun) {
			setPagination(Object.assign(pagination, {
				current: 1,
				pageSize: 8,
				total: 0,
			}))
			setIsUpdate?.(false);
			setTableData(dataSource || [])
			return;
		}
		try {
			setTableLoading(true);
			const obj: any = {};
			if (values) {
				for (const key in values) {
					obj[key] = ['all', 'undefined'].includes(values[key]) && !isHasAll ? undefined : values[key]
				}
			}
			let newPageNo = pageNum;
			let newPageSize = pageSize;
			const formData: any = Object.assign({pageNum: newPageNo, pageSize: newPageSize}, obj ?? {});
			const formObj = localStorage.getItem('formValue')
			if (formObj) {
				const newObject = JSON.parse(formObj)
				newPageNo = newObject?.pageNum ? Number(newObject?.pageNum) : pageNum;
				newPageSize = newObject?.pageSize ? Number(newObject?.pageSize) : pageSize;
				currentForm.setFieldsValue(newObject);
				Object.assign(formData, newObject)
			}
			for (const key in formData) {
				if (!formData[key]) delete formData[key];
			}
			let listFun = secondParameter ? getListFun(formData, secondParameter) : getListFun(formData)
			const {list, total} = await listFun;
			setTableData(list ?? [])
			isEmpty?.(!!total)
			const realPagination = Object.assign(pagination, {
				current: newPageNo,
				total,
				pageSize: newPageSize,
			});
			setPagination(realPagination);
			setTableLoading(false);
			setIsUpdate?.(false);
		} catch (error) {
			setTableLoading(false);
			setIsUpdate?.(false);
		}
	}

	const returnForm = (values: any) => {
		const {rangePicker} = values;
		let formData = {...values};
		if (rangePicker && rangePicker.length) {
			formData = Object.assign(formData, {
				beginTimeStr: moment(rangePicker[0]).format(formatText),
				endTimeStr: moment(rangePicker[1]).format(formatText),
			})
			delete formData.rangePicker;
		}
		return formData;
	}

	useEffect(() => {
		getList(1, 8, formInitialValues);
	}, []);

	useMemo(() => {
		if (isUpdate) {
			const values = returnFormData(filterData.length > 0 ? currentForm.getFieldsValue() : {})
			getList(pagination.current, pagination.pageSize, returnForm(values));
		}
	}, [isUpdate])

	// const onSearch = () => {
	// 	const values = currentForm.getFieldsValue();
	// 	const formData = returnFormData(values)
	// 	getList(1, 20, returnForm(formData));
	// }

	// const renderFilterBtn = (
	// 	<Space size="small" style={{ marginBottom: 12 }}>
	// 		<SearchButton noSubmit onClick={onSearch} />
	// 		<ResetButton
	// 			onClick={() => {
	// 				localStorage.setItem('formValue', '')
	// 				currentForm.resetFields();
	// 				getList(1, 20, formInitialValues);
	// 			}}
	// 		/>
	// 	</Space>
	// )

	return (
		<div className={styles.root}>
			{/* {
				filterData.length > 0 && (
					<Form
						form={currentForm}
						// onFinish={onSearch}
						labelCol={filterData.length > 4 ? {xs: { span: 6 }} : undefined}
						className={formClassName}
						initialValues={formInitialValues}
					>
						<FilterForm
							filterData={filterData}
							btnArr={btnArr}
							pageHeadBtn={pageHeadBtn}
							renderFilterBtn={renderFilterBtn}
							warpStyle={filterWarpStyle}
						/>

					</Form>
				)
			} */}
			<div className={styles.tableWarp} style={tableStyle}>
				{
					(pageTitle || isShowAddBtn) && (
						<div className={styles.pageHead} style={titleStyle}>
							<div className={styles.pageTitle}>{pageTitle}</div>
							{/* { pageHeadBtn } */}
							{
								isShowAddBtn && pageHeadBtn && (
									<div className={styles.pageHeadWarp}>
										{pageHeadBtn}
									</div>
								)
							}
						</div>
					)
				}
				{/* {
					isShowAddBtn && pageHeadBtn && (
						<div className={styles.pageHeadWarp}>
							{pageHeadBtn}
						</div>
					)
				} */}
				{tabsNode}
				<Table
					// bordered
					loading={tableLoading}
					scroll={{ x: scrollX }}
					columns={columns}
					dataSource={getListFun ? tableData : dataSource}
					className={styles.formTable}
					pagination={false}
					rowKey={record => `${record[recordKey]}`}
					{...tableProps}
				/>
				{
					showPagination && tableData.length > 0 && (
						<div className={styles.pageSizeBox} style={paginationStyle}>
							<Pagination
								onChange={onShowSizeChange}
								{...pagination}
							/>
						</div>
					)
				}
			</div>
		</div>
	)
}

export default TableComponent;
