import React, {FC, useState, useMemo, useEffect} from 'react'
import {Space, Pagination, Modal as ModalCom, message, Spin, Image} from 'antd';
import styles from './index.module.scss'
import {NoDataCom, Table} from '@/components';
import {Modal} from '@/components';
import {DeleteOutlined, EyeOutlined, PlusOutlined, LinkOutlined} from '@ant-design/icons';
import {deliveryOptions, countryOptions} from '@/utils/defaultData';
import UpdateRecipient from './components/UpdateRecipient'
import InviteLink from './components/InviteLink'
import {isMobile} from '@/utils/utils';
import TelIcon from '@/assets/image/recipient/tel-icon.png'
import emailIcon from '@/assets/image/recipient/email-icon.png'
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";
import {queryRecipients, deleteRecipient} from '@/api/recipient/recipient';
import {useMappedState} from "redux-react-hook";
import {IState} from "@/redux/interface";
import {useHistory} from 'react-router-dom';
import {RegistrationStatus} from '@/api/auth/auth'
import copyCom from "copy-to-clipboard";

const {confirm} = ModalCom;

interface IRecipientProps {}

const Recipient: FC<IRecipientProps> = (props) => {
	const userInfoState = useMappedState(
		(state: IState) => state.UserInfoReducer.userInfo
	);
	const {registrationStatus, slug} = userInfoState || {};
	const fullName = `${userInfoState?.firstName}-${userInfoState?.lastName}`;
	const slugLink = `${slug}&fullName=${fullName}`;
	const {t, i18n} = useTranslation();
	const [isUpdate, setIsUpdate] = useState(false);
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [linkVisible, setLinkVisible] = useState(false);
	const [isViewRecipient, setIsViewRecipient] = useState(false);
	const [currentRecipient, setCurrentRecipient] = useState<any>();
	// const arrData = [{country: 1, type: 1, id: '1', name: 'Siliu Jis '},{country: 2, type: 2, id: '2', name: 'Siliu Jis '},{country: 3, type: 3, id: '3', name: 'Siliu Jis '}]
	const [recipientData, setRecipientDara] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 8,
		total: 0,
		showTotal: (total: any) => `Total ${total} recipient`,
	});

	const getList = async (current: number = 1, pageSize: number = 8) => {
		try {
			setLoading(true);
			const {total, list} = await queryRecipients({
				Status: 0,
				pageSize,
				pageNum: current
			})
			setRecipientDara(list ?? [])
			setPagination({...pagination, current, pageSize, total})
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (registrationStatus === RegistrationStatus.Finished) getList(1, 8)
	}, [])

	const deleteRecipientFn = async (id: number | string, userId: number | string) => {
		try {
			message.loading({
				content: t("正在删除，请稍后"),
				key: 'deleteLoading',
				duration: 0,
			});
			await deleteRecipient(id, userId);
			message.success(t('删除成功'));
			message.destroy('deleteLoading');
			getList(pagination.current, pagination.pageSize);
		} catch (error) {
			message.destroy('deleteLoading');
		}
	}

	const confirmDel = (item: any) => {
		confirm({
			title: t('您确定删除收款人吗?'),
			icon: <ExclamationCircleOutlined style={{color: '#FF5C00'}} />,
			content: t('如果您删除此收件人，您将无法恢复它。但是，所有相关的汇款仍然是可访问的。'),
			okText: t('确定'),
			okType: 'danger',
			cancelText: t('取消'),
			maskClosable: true,
			closable: true,
			centered: true,
			className: 'default-confirm',
			onOk() {
				deleteRecipientFn(item.id, userInfoState.id);
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const onShowSizeChange = (current?: number, pageSize?: number) => {
		if (current) {
			getList(current, pageSize);
		}
	};

	const columns = [
		{
			title: t("姓名"),
			render: (text: any, record: any) => {
				const {firstName, lastName, idPhoto} = record || {};
				return (
					<div className={styles.photoBox}>
						{/* <Image className={styles.idPhone} alt='' src={idPhoto} /> */}
						<span>{firstName} {lastName}</span>
					</div>
				)
			}
		},
		{
			title: t("收款人编号"),
			dataIndex: 'beneficiaryNo',
			width: 380,
		},
		{
			title: t("国家"),
			render: (text: any, record: any) => {
				const {label, icon} = countryOptions.find(item => item.codeVal === Number(record.country)) || {};
				return (
					<>
						<div className='flex-center'>
							<img src={icon} style={{width: 24}} className={styles.receiveIcon} alt='' />{label}
						</div>
					</>
				)
			}
		},
		{
			title: t("手机号"),
			dataIndex: 'handPhone',
		},
		{
			title: t("收款方式"),
			render: (text: any, record: any) => {
				const {label, icon} = deliveryOptions.find(item => item.value === record.receiveType) || {};
				return (
					<>
						<div className='flex-center'><img src={icon} className={styles.receiveIcon} alt='' />{label}</div>
					</>
				)
			}
		},
		{
			title: t("操作"),
			key: 'action',
			fixed: 'right',
			width: 130,
			render: (text: any, record: any) => (
				<Space size="small">
					<EyeOutlined
						onClick={() => {
							record.country = Number(record.country)
							setCurrentRecipient(record);
							setIsModalVisible(true);
							setIsViewRecipient(true)
						}}
						className={styles.viewIconClass}
					/>
					<DeleteOutlined
						onClick={() => confirmDel(record)}
						className={styles.delIconClass}
					/>
				</Space>
			)
		},
	];

	useMemo(() => {
		document.body.style.overflow = isModalVisible ? 'hidden' : 'auto'
		if (!isModalVisible) {
			setCurrentRecipient(undefined)
		}
	}, [isModalVisible])

	return (
		<div className={`${styles.root} ${recipientData.length ? '' : styles.NoData}`}>
			<Spin spinning={loading} tip="Loading">
				{
					recipientData.length > 0 ? (
						<>
							{
								isMobile() ? (
									<div className={styles.recipientWarp}>
										<div className={styles.title}>{t("收款人")}</div>
										<div className={styles.recipientList}>
											{
												recipientData.map((item: any, index: number) => (
													<div className={styles.recipientLi} key={`${index}`}>
														<div className={styles.liLeft}>
															<div className={styles.name}>{item.firstName} {item.lastName}</div>
															<div className={styles.tel}>
																<img src={emailIcon} alt='' />
																<span>{item.email}</span>
															</div>
															<div className={styles.tel}>
																<img src={TelIcon} alt='' />
																<span>{item.handPhone}</span>
															</div>
														</div>
														<div className={styles.liRight}>
															<Space size="small">
																<EyeOutlined
																	onClick={() => {
																		setCurrentRecipient(item);
																		setIsModalVisible(true);
																		setIsViewRecipient(true)
																	}}
																	className={styles.viewIconClass}
																/>
																<DeleteOutlined
																	onClick={() => confirmDel(item)}
																	className={styles.delIconClass}
																/>
															</Space>
														</div>
													</div>
												))
											}
										</div>
									</div>
								) : (
									<Table
										columns={columns}
										// getListFun={queryRecipients}
										dataSource={recipientData}
										isUpdate={isUpdate}
										isShowAddBtn
										setIsUpdate={setIsUpdate}
										pageTitle={t("收款人")}
										totalTips="recipient"
										showPagination={false}
										pageHeadBtn={
											<div className='flex-center'>
												<div className={styles.linkBtnWarp} onClick={() => {
													setLinkVisible(true)
												}}>
													<LinkOutlined className={styles.linkIconClass} /><span>{t("通过链接邀请")}
													</span>
												</div>
												<div className={styles.addBtnWarp} onClick={() => {
													setIsModalVisible(true)
													setIsViewRecipient(false)
												}}>
													<PlusOutlined className={styles.addIconClass} /><span>{t("添加收款人")} </span>
												</div>
											</div>
										}
									/>
								)
							}
							{
								recipientData.length > 0 && (
									<div className={styles.pageSizeBox}>
										<Pagination
											onChange={onShowSizeChange}
											{...pagination}
										/>
									</div>
								)
							}
							{
								isMobile() && (
									<div className='flex-center' style={{marginTop: 30}}>
										<div className={styles.linkBtnWarp} onClick={() => {
											setLinkVisible(true)
										}}>
											<LinkOutlined className={styles.linkIconClass} /><span>{t("通过链接邀请")}
											</span>
										</div>
										<div
											className={styles.addBtnBox}
											onClick={() => {
												if (!userInfoState?.id) {
													history.push('/login');
												} else {
													if (registrationStatus === RegistrationStatus.Unfinished) history.push('/register');
													if (registrationStatus === RegistrationStatus.Finished) {
														setIsModalVisible(true)
														setIsViewRecipient(false)
													}
												}
											}}
										>
											<PlusOutlined className={styles.addIconClass} /><span>{t("添加收款人")} </span>
										</div>
									</div>
								)
							}
						</>
					) : (
						<>
							{
								loading ? (
									<div style={{minHeight: 500}} />
								) : (
									<NoDataCom
										title={t('没有收款人')}
										subtitle={t('您还没有收款人，请先新增一个收款人')}
										btnClick={() => {
											if (registrationStatus === RegistrationStatus.Unfinished) {
												message.warning(t("请完成注册后操作！"))
												// history.push('/register');
											} else if (userInfoState.id) {
												setIsModalVisible(true)
												setIsViewRecipient(false)
											} else {
												message.warning(t("您未登录，请登录后操作！"))
											}
										}}
										btnTitle={t('新增收款人')}
										btnChildren={(
											<div className={styles.NoDataLinkBtn} onClick={() => {
												setLinkVisible(true)
											}}>
												{t("通过链接邀请")}
											</div>
										)}
									/>
								)
							}
						</>
					)
				}
			</Spin>
			{
				isModalVisible && (
					<UpdateRecipient
						setIsModalVisible={setIsModalVisible}
						currentRecipient={currentRecipient}
						isViewRecipient={isViewRecipient}
						getList={getList}
					/>
				)
			}
			{linkVisible && <InviteLink setVisible={setLinkVisible} slugLink={slugLink} />}
		</div>
	)
}

export default Recipient
