export const toFixed2 = (n: number) => {
  return `${Math.ceil(n * 100) / 100}`
}

export const toFixed6 = (n: number) => {
  let num = n
  const str = `${n}`
  const c = str.indexOf('.')
  if (str.length - c > 7) {
    num = Math.ceil(n * 1000000) / 1000000
  }
  return num;
}

export const computedRate = (rate: number) => {
  if (!rate) return 0
  const r1 = toFixed6(rate);
  return toFixed6(r1 * 1.01);
  // return toFixed6(1 / r2)
}

export const fixed6ToFillZero = (n: number | string) => {
  const str = `${n}`
  const c = str.indexOf('.') + 7
  return str.padEnd(c, '0');
}

export const formatToNumber = (str: string) => {
  const nums = str.split(',')
  const t = nums.reduce((prev, current) => prev + current)
  return t.replace('.00', '')
}