import React, {FC, useEffect, useState} from 'react'
import {Timeline, Row, Col, Button, Modal} from 'antd';
import {LeftOutlined, ExclamationCircleOutlined} from '@ant-design/icons';
import {history} from "@/route";
import Finish from "@/assets/image/order/finish.png";
import Fail from "@/assets/image/order/fail.png";
import Phone from "@/assets/image/order/phone.png";
import {isMobile} from '@/utils/utils';
import cls from "classnames";
import {ordertApi} from '@/api'
import {localFormat} from '@/utils/moment';
import BankTransfer from "@/assets/image/remittance/BankTransfer.png";
import AliPay from "@/assets/image/order/alipay.svg";
import WeChat from "@/assets/image/remittance/WeChat.png";
import MPESA from "@/assets/image/remittance/MPESA.png";
import Binance from "@/assets/image/remittance/binance.png";
import Exchange from "@/assets/image/order/exchange.png";
import S1 from "@/assets/image/order/step1.png";
import S2 from "@/assets/image/order/step2.png";
import S3 from "@/assets/image/order/step3.png";
import S4 from "@/assets/image/order/step4.png";
import S5 from "@/assets/image/order/step5.png";
import S6 from "@/assets/image/order/step6.png";
import S7 from "@/assets/image/order/step7.png";
import S8 from "@/assets/image/order/step8.png";
import S9 from "@/assets/image/order/step9.png";
import S10 from "@/assets/image/order/step10.png";
import {Month, formatCurrency} from "@/utils/utils";
import {fixed6ToFillZero} from "@/pages/HomePage/utils";
import ConfirmModal from "@/pages/Remittance/components/ConfirmModal/ConfirmModal";
import type {Status} from "../../Order";
import styles from "./OrderDetail.module.scss";
import {useTranslation} from "react-i18next";
import Receipt from '../Receipt/Receipt'

interface IPcDetailProps {
  getStatus: (s: string) => string;
  getParamsId: (s: string) => string | undefined;
  setTableKey: (s: number) => void;
  status: Status;
  isSubOrder?: boolean;
}

const baseKey = {
  Recipient: '收款人 ',
  trnNo: '订单号',
  createTime: '订单时间',
}

const sendKey = {
  deliveryMethod: '收款方式',
  paymentMethod: '汇款方法',
  sendingAmount: '汇款金额',
}

const detailKey = {
  instAmount: '收款金额',
  exchangeRate: '汇率',
  commission: '手续费',
  totalAmount: '总金额',
  status: '状态',
}

export interface OpsType {
  [key: number]: {
    key: string;
    icon: string;
  }
}

export const ops: OpsType = {
  1: {key: 'Wechat pay', icon: WeChat},
  2: {key: 'M-PESA', icon: MPESA},
  3: {key: 'Bank Transfer', icon: BankTransfer},
  4: {key: 'Alipay', icon: AliPay},
  5: {key: 'Binance Pay', icon: Binance},
}

interface IDefault {
  finish?: number;
  icon?: string;
  label?: string;
  successIcon?: string;
}

interface IProgress extends IDefault {
  id?: number;
  createTime?: string;
  currentStatus?: string;
  remarks?: string;
}

const PcDetail: FC<IPcDetailProps> = ({status, getStatus, getParamsId, setTableKey, isSubOrder}) => {
  const {t} = useTranslation();
  const [detail, setDetail] = useState<any>({})
  const [visible, setVisible] = useState(false);
  const [progress, setProgress] = useState<IProgress[]>([]);
  const [receiptVisible, setReceiptVisible] = useState<boolean>(false);

  const dp = [
    {icon: S1, successIcon: S6, label: '等待汇款'},
    {icon: S2, successIcon: S7, label: '款项核对中'},
    {icon: S3, successIcon: S8, label: '已收到您的付款'},
    {icon: S4, successIcon: S9, label: '汇款处理中'},
    {icon: S5, successIcon: S10, label: '汇款成功'},
  ]

  const statusValue: Record<string, string> = {
    '0': '汇款订单',
    '1': '等待汇款',
    '2': '支付检验',
    '3': '已收到款项',
    '4': '支付错误 ',
    '5': '提交退款',
    '6': '退款成功',
    '7': '订单处理中',
    '8': '交易成功',
    '9': '订单取消 ',
    '10': '交易错误',
    '11': '等待确认',
    '12': '被拒绝',
    '-1': '其他',
    // 5：Refund Made  6：Refund Succesful  12：Rejected by superior
  }

  const getDetails = async () => {
    const str = history.location.search
    const id = getParamsId(str)
    if (!id) return;
    const data = await ordertApi.getOrderDetail(id);
    setDetail({...data, createTime: data.createTime ? localFormat(data.createTime, true) : '', Recipient: `${data?.recipient?.firstName ?? ''} ${data?.recipient?.lastName ?? ''}`} ?? {})
    if (data?.id) {
      orderHistory(data.id, data.paymentMethod)
    }
  };

  const orderHistory = async (id: number, paymentMethod: number) => {
    const {list} = await ordertApi.getOrderHistory(id)
    const p: IProgress[] = []
    // const currentProgress = paymentMethod === 2 ? dp.filter((v, i) => i !== 1) : dp;
    dp.forEach((v: IDefault, i) => {
      let item: IProgress = v
      const cr = list?.[i]
      const pr = list?.[i + 1]
      if (cr) {
        const {id, currentStatus, createTime, remarks} = cr
        const month = Month(localFormat(createTime, 'MM') as string)
        const day = localFormat(createTime, 'DD')
        item = {...v, id, currentStatus, createTime: `${month} ${day}`, remarks, finish: pr ? 1 : 2}
      }
      p.push(item)
    })
    setProgress(p)
  }

  const confirmDel = () => {
    Modal.confirm({
      title: t('取消汇款 '),
      icon: <ExclamationCircleOutlined style={{color: '#FF5C00'}} />,
      okText: t('确定'),
      okType: 'danger',
      content: t('您确定要撤回这笔转账吗？'),
      cancelText: t('取消'),
      maskClosable: true,
      closable: true,
      centered: true,
      className: 'default-confirm',
      onOk() {
        handleOrderPayment(ordertApi.cancelPayment, detail.id, '取消汇款失败')
      }
    });
  }

  const handleOrderPayment = async (method: any, params: any, errMsg: string) => {
    await method(params)
    getDetails()
    setTableKey(Math.random() * 100)
  }

  useEffect(() => {
    getDetails()
  }, [])

  const ReceiptCard = () =>
    <div className={styles.detail}>
      <div className={styles.base}>
        {
          Object.entries(baseKey).map(([key, value], i) =>
            <div className={styles.baseItem} key={i}>
              <span>{t(value).trim()}：</span>
              <span>{detail[key]}</span>
            </div>
          )
        }
      </div>
      <div className={styles.send}>
        {
          Object.entries(sendKey).map(([key, value], i) =>
            <div className={`flex-between ${styles.sendItem}`} key={i}>
              <span>{t(value)}</span>
              {key === 'paymentMethod' && <span className='flex-center'>
                <img src={ops[detail.paymentMethod]?.icon} alt="" />
                {ops[detail.paymentMethod]?.key ?? ''}
              </span>}
              {key === 'deliveryMethod' && <span className='flex-center'>
                <img src={ops[detail.deliveryMethod]?.icon} alt="" />
                {ops[detail.deliveryMethod]?.key ?? ''}
              </span>}
              {key === 'sendingAmount' && <span className={styles.sendAmount}>
                {`${formatCurrency(detail.sendingAmount)} ${detail.sendingCurrency ?? ''} `}</span>}
            </div>
          )
        }
      </div>
      <div className={styles.status}>
        {
          Object.entries(detailKey).map(([key, value], i) =>
            <div className={`flex-between ${styles.statusItem} ${key === 'instAmount' ? styles.majoy : ''}`} key={i}>
              <span className={styles.label}>{t(value)}</span>
              {key === 'exchangeRate' && <span className='flex-center'>
                <span>1 {detail.instCurrency ?? ''}</span>
                <img src={Exchange} alt="" />
                <span>{`${fixed6ToFillZero(detail.exchangeRate) ?? ''} ${detail.sendingCurrency ?? ''}`}</span>
              </span>}
              {['commission', 'instAmount', 'totalAmount'].includes(key) && <span>
                {`${formatCurrency(detail[key])} ${key === 'instAmount' ? detail.instCurrency : detail.sendingCurrency}`}
              </span>}
              {key === 'status' && <span style={{color: '#FF5C00'}}>{t(statusValue[detail.status])}</span>}
            </div>
          )
        }
      </div>
    </div>

  const progressIcon = (v: IProgress) => {
    if (['4', '10', '-1', '9', '12'].includes(v.currentStatus ?? '')) {
      return Fail
    } else if (v.finish) {
      return v.finish === 1 ? v.successIcon : Finish
    } else {
      return v.icon
    }
  }

  const rColor = (code: string, finish: any) => {
    let c = !!finish ? '#333333' : '#777777'
    if (['4', '10', '-1'].includes(code)) {
      c = '#FF5C00'
    } else if (code === '9') {
      c = '#999999'
    }
    return c;
  }

  const ProgressCard = () =>
    <div className={styles.progress}>
      <div className={styles.timeLine}>
        <Timeline mode='left'>
          {
            (Array.isArray(progress) ? progress : []).map((v: IProgress, i: number) =>
              <Timeline.Item
                key={i}
                className={v.finish ? '' : styles.newBor}
                dot={<img src={progressIcon(v)} alt="" style={{width: 26, height: 26}} />
                }>
                <span className={styles.timeLabel}>
                  <span className={styles.date}>{v.createTime}</span>
                  <>
                    <span style={{color: rColor(v.currentStatus || '', v.finish)}}>
                      {t(statusValue[v.currentStatus ?? ''] ?? t(v.label ?? ''))}
                    </span> <br />
                    <span className={styles.remarks}>{v.remarks && t(v.remarks)}</span> <br />
                    {detail.paymentMethod === 3 && detail.bankRefNo && i === 1 && <span className={styles.remarks}>Ref No. {detail.bankRefNo}</span>}
                  </>
                </span>
              </Timeline.Item>)
          }
        </Timeline>
      </div>
      <div className={styles.btnContent}>
        {/* Payment Method = MPESA && Waiting */}
        {getStatus(detail.status) === 'Waiting' && <div className={styles.handleBtn}>
          {<Button type="primary" shape="round" className={`${styles.b} ${styles.b1}`}
            onClick={() => {setVisible(true)}}>
            {t("付款")}
          </Button>}
          <Button type="primary" className={`${styles.b} ${styles.b2}`} ghost onClick={() =>
            confirmDel()}>
            {t("取消汇款")}
          </Button>
        </div>}
        {getStatus(detail.status) === 'Complete' && <div className={styles.handleBtn}>
          <Button type="primary" className={`${styles.b} ${styles.b3}`} ghost onClick={() => {
            setReceiptVisible(true)
          }}>
            {t("收据回执")}
          </Button>
          {/* <Button type="primary" className={`${styles.b} ${styles.b3}`} ghost onClick={() => {}}>
            {t("发票回执")}
          </Button> */}
        </div>}
        <div className={styles.phoneBtn}>
          <img src={Phone} alt="" />
          {t("客服热线：")}+254 768-985-998
        </div>
      </div>
    </div >

  const detailCard = (label: string) =>
    <div key={label} className={label === '收款信息' ? styles.c1 : styles.c2} >
      <div className={cls(styles.container)}>
        <div className={styles.itemBox}>
          <div className={cls(styles.itemLabel, label === '进度' ? styles.proLable : '')}>
            {t(label)}
          </div>
          {label === '收款信息' ? ReceiptCard() : ProgressCard()}
        </div>
      </div>
    </div>

  return (
    <div className={styles.detailRoot}>
      {receiptVisible && <Receipt setVisible={setReceiptVisible} data={detail} />}
      <span onClick={() => isSubOrder ? history.goBack() : history.replace('/order')} className={styles.nav}>
        <LeftOutlined style={{color: '#333333', marginRight: 5}} />
        <span>{t(isSubOrder ? "返回" : "返回订单列表")}</span>
      </span>
      <div className={styles.bg} />
      <div className={`${styles.row}`}>{['收款信息', '进度'].map(v => detailCard(v))}</div>
      <ConfirmModal
        visible={visible}
        type={detail.paymentMethod}
        dataSource={{
          trnNo: detail?.trnNo,
          totalAmount: detail?.totalAmount,
          instCurrency: detail?.instCurrency,
          sendingCurrency: detail?.sendingCurrency,
          recipientName: `${detail?.recipient?.firstName ?? ''} ${detail?.recipient?.lastName ?? ''}`
        }}
        setVisible={(finish: boolean) => {
          setVisible(false)
          if (finish) {
            getDetails()
            setTableKey(Math.random() * 100)
          }
        }} />
    </div>)
}

export default PcDetail;