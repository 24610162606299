import React, { FC } from 'react'
import HeadPic from '@/assets/image/about/head-pic.png'
import AboutInfo1 from '@/assets/image/about/about-info1.png'
import AboutInfo2 from '@/assets/image/about/about-info2.png'
import AboutInfo3 from '@/assets/image/about/about-info3.png'
import AboutInfo4 from '@/assets/image/about/about-info4.png'
import AboutInfo5 from '@/assets/image/about/about-info5.png'
import FounderIcon from '@/assets/image/about/founder-icon.png'
import { OverPack } from 'rc-scroll-anim';
import QueueAnim from 'rc-queue-anim';
import styles from './About.module.scss'
import { isMobile } from '@/utils/utils';
import { useTranslation } from "react-i18next";

interface IAboutProps {
}

const dataList = [
  { num: 5, unit: '十亿', info: '总成交量' },
  { num: 396, unit: '%', info: '同比增长' },
  { num: 6194, unit: '商户', info: '亚洲受益者' },
  { num: 1.5, unit: '亿', info: '从亚洲流入东非' },
]

const courseList = [
  { title: '启发与筹备', subtitle: '启动 Wapi Pay', pic: AboutInfo3, info: '2019年，在金融科技和银行业背景的启发和准备下，我们开始启动Wapi Pay——一个亚非支付平台，以帮助连接两大洲之间的经济价值交换。' },
  { title: '传统的支付方式', subtitle: '交易失败率高', pic: AboutInfo4, info: '非洲和中国之间的传统支付渠道包括：缓慢-从非洲到中国的付款可能需要长达一周的时间来处理交易并且失败率高-必要的中介机构使用传统昂贵的交易成本-由于费用和外汇兑换费用高达支付价值的15%。' },
  { title: 'Wapi Pay 支付', subtitle: '无缝和无摩擦，成本更低', pic: AboutInfo5, info: '我们让您在非洲和亚洲之间的汇款过程尽可能简单：无缝、无摩擦、成本更低。' }
]

const About: FC<IAboutProps> = (props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className={styles.root}>
      <div className={styles.head}>
        <div className={`${styles.contentWrap} ${styles.headContentWrap}`}>
          <div className={styles.headL}>
            <div className={`${styles.infoTitle} ${styles.headTitle}`}>
              {t("关于我们")}<div className={styles.line} />
            </div>
            <div className={styles.infoText}>
              {t("Wapi Pay主要为从非洲到亚洲的跨境汇款和为个人付款的公司服务，目前大部分需求来自大型中国公司的跨境采购和海外工资支付。")}

            </div>
            {
              !isMobile() && (
                <div className={styles.infoText}>
                  {t("Wapi Pay为小商户、大公司以及与亚洲开展贸易的任何用户提供最好的支付解决方案。")}

                </div>
              )
            }
          </div>
          <img src={HeadPic} alt='' className={styles.headR} />
        </div>
      </div>
      <div className={styles.ourGoals}>
        <div className={`${styles.contentWrap} ${styles.ourGoalsWrap}`}>
          <img alt='' src={AboutInfo1} className={styles.ourGoalsL} />
          <div className={styles.ourGoalsR}>
            <div className={`${styles.infoTitle} ${styles.ourGoalsTitle}`}>{t("我们的愿景")}</div>
            <div className={styles.infoText}>
              {t("在东非市场，尤其是在以肯尼亚为首的东非，移动钱包正逐渐取代现金或银行账户，成为支持数字/第三方支付创新的基石。Wapi Pay允许用户使用手机号码作为身份识别码使用移动钱包账户（移动货币）的存款、取款、转账等功能，无需通过银行等金融机构开户。")}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.features}>
        <div className={`${styles.contentWrap} ${styles.featuresWrap}`}>
          <div className={`${styles.infoTitle} ${styles.featuresTitle}`}>{t("主要特点")}</div>
          <div className={styles.infoText}>{t("自上市以来，我们的总成交量超过50亿，同比增长396%，在亚洲注册的供应商和受益人超过6194家，")}</div>
          <div className={styles.infoText}>{t("以及超过9000名在非洲注册的商人、贸易商和企业。")} </div>
          <div className={styles.infoText}>“{t("Wapi Pay是一家专注于亚洲地带的支付网关，它处理了约超过1.5亿美元的亚洲资金流入")}</div>
          <div className={styles.infoText}>{t("自2019年初上线以来，这是一个惊人的数字。")}”</div>
          <div className={styles.dataList}>
            {
              dataList.map((item: any, index: number) => (
                <div key={`data-${index}`} className={styles.dataLi}>
                  <div className={styles.liHead}>
                    <div className={styles.num}>{item.num}</div>
                    <div className={styles.unit}>{t(item.unit)}</div>
                  </div>
                  <div className={styles.line} />
                  <div className={styles.info}>{t(item.info)}</div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className={styles.founder}>
        <div className={`${styles.contentWrap} ${styles.founderWrap}`}>
          <div className={styles.founderL}>
            <div className={styles.synopsis}>
              {t("我们希望我们的产品能简化你们的汇款流程，进而促进我们大陆和亚洲之间的贸易。")}
              <img alt='' src={FounderIcon} className={styles.founderIcon} />
            </div>
            <div className={styles.sourceWarp}>
              <div>
                <div className={styles.sourceT}>
                  <div className={styles.line} />
                  <div style={{fontWeight: 600}}>{t("保罗·恩迪丘，埃迪·恩迪丘")}</div>
                </div>
                <div className={styles.sourceB}>{t("Wapi Pay创始人")}</div>
              </div>
            </div>
          </div>
          <img alt='' src={AboutInfo2} className={styles.founderR} />
        </div>
      </div>
      <div className={styles.content}>
        <div className={`${styles.contentWrap} ${styles.bottomContentWrap}`}>
          {
            courseList.map((item: any, index: number) => (
              <OverPack
                style={{ minHeight: 200 }}
                playScale={[0.2, 0.8]}
                key={`course-${index}`}
              >
                <QueueAnim
                  key={`queueAnim-${index}`}
                  leaveReverse
                  type={['right', 'left']}
                  ease={['easeOutQuart', 'easeInOutQuart']}
                >
                  <div className={`${styles.courseLi} ${index % 2 === 1 && styles.courseLiR}`}>
                    <img alt='' src={item.pic} className={styles.coursePic} />
                    <div className={styles.courseLiRight}>
                      <div className={`${styles.infoTitle} ${styles.headTitle}`}>{t(item.title)}</div>
                      <div className={styles.subtitle}>{t(item.subtitle)}</div>
                      <div className={styles.infoText}>{t(item.info)}</div>
                    </div>
                  </div>
                </QueueAnim>
              </OverPack>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default About
