
import UnitedStates from '@/assets/image/login/UnitedStates.png'
import China from '@/assets/image/login/China.png'
import Kenya from '@/assets/image/login/Kenya.png'
import LocalBank from '@/assets/image/recipient/local-bank.png'
import Wechat from '@/assets/image/recipient/wechat.png'
import MPESA from '@/assets/image/recipient/m-pesa.png'
import Uganda from '@/assets/image/login/Uganda.png'
import Ghana from '@/assets/image/login/Ghana.png'
import JPY from '@/assets/image/login/JPY.png'
import INR from '@/assets/image/login/INR.png'
import IDR from '@/assets/image/login/IDR.png'
import VND from '@/assets/image/login/VND.png'
import MYR from '@/assets/image/login/MYR.png'
import PHP from '@/assets/image/login/PHP.png'
import TWD from '@/assets/image/login/TWD.png'
import Naira from '@/assets/image/login/Naira.png'
import ZAR from '@/assets/image/login/ZAR.png'
import EGP from '@/assets/image/login/EGP.png'
import ETB from '@/assets/image/login/ETB.png'
import Zambia from '@/assets/image/login/Zambia.png'
import AliPay from "@/assets/image/order/alipay.svg";
import Binance from "@/assets/image/remittance/binance.png";
import {Nationality} from '@/api/auth/auth'

export const countryOptions = [
	{code: '+86', label: 'China (中国)', nationality: 'Chinese', icon: China, value: 86, codeVal: Nationality.China, currency: 'CNY'},
	{code: '+254', label: 'Kenya', nationality: 'Kenyan', icon: Kenya, value: 254, codeVal: Nationality.Kenya, currency: 'KES'},
	{code: '+1', label: 'United States', nationality: 'American', icon: UnitedStates, value: 1, codeVal: Nationality.America, currency: 'USD'},
	{code: '+256', label: 'Uganda', nationality: 'Ugandan', icon: Uganda, value: 256, codeVal: Nationality.Uganda, currency: 'USH'},
	{code: '+233', label: 'Ghana', nationality: 'Ghanaian', icon: Ghana, value: 233, codeVal: Nationality.Ghana, currency: 'CEDI'},
	{code: '+260', label: 'Zambia', nationality: 'Zambian', icon: Zambia, value: 260, codeVal: Nationality.Zambia, currency: 'ZK'},
	{code: '+81', label: 'Japan', nationality: 'Japanese', icon: JPY, value: 81, codeVal: Nationality.Japan, currency: 'JPY'},
	{code: '+91', label: 'India', nationality: 'Indian', icon: INR, value: 91, codeVal: Nationality.India, currency: 'INR'},
	{code: '+62', label: 'Indonesia', nationality: 'Indonesian', icon: IDR, value: 62, codeVal: Nationality.Indonesia, currency: 'IDR'},
	{code: '+84', label: 'Vietnam', nationality: 'Vietnamese', icon: VND, value: 84, codeVal: Nationality.Vietnam, currency: 'VND'},
	{code: '+60', label: 'Malaysia', nationality: 'Malaysian', icon: MYR, value: 60, codeVal: Nationality.Malaysia, currency: 'MYR'},
	{code: '+63', label: 'Philippines', nationality: 'Filipino', icon: PHP, value: 63, codeVal: Nationality.Philippines, currency: 'PHP'},
	{code: '+886', label: 'Taiwan', nationality: 'Taiwanese', icon: TWD, value: 886, codeVal: Nationality.Taiwan, currency: 'TWD'},
	{code: '+234', label: 'Nigeria', nationality: 'Nigerian', icon: Naira, value: 234, codeVal: Nationality.Nigeria, currency: 'Naira'},
	{code: '+27', label: 'South Africa', nationality: 'South African', icon: ZAR, value: 27, codeVal: Nationality['South Africa'], currency: 'ZAR'},
	{code: '+20', label: 'Egypt', nationality: 'Egyptian', icon: EGP, value: 20, codeVal: Nationality.Egypt, currency: 'EGP'},
	{code: '+251', label: 'Ethiopia', nationality: 'Ethiopian', icon: ETB, value: 251, codeVal: Nationality.Ethiopia, currency: 'ETB'},
]

export const receiveTypes = [
	{label: 'Local Bank', icon: LocalBank, value: 1},
	{label: 'Wechat', icon: Wechat, value: 2},
	{label: 'M-PESA', icon: MPESA, value: 3},
]

export const deliveryOptions = [
	{value: 3, label: "Bank Transfer", disabled: false, icon: LocalBank},
	{value: 2, label: "M-PESA", disabled: false, icon: MPESA},
	{value: 1, label: "Wechat pay", disabled: true, icon: Wechat},
	{value: 4, label: "Alipay", disabled: true, icon: AliPay},
	{value: 5, label: "Binance Pay", disabled: true, icon: Binance},
]

export const exchangeRateOptions = [
	{value: "CNY", label: "CNY", disabled: false, remarks: "China (中国)", icon: China, support: true},
	{value: "KES", label: "KES", disabled: false, remarks: "Kenya", icon: Kenya, support: true},
	{value: "USD", label: "USD", disabled: false, remarks: "United States", icon: UnitedStates},
	{value: "ZK", label: "ZK", disabled: false, remarks: "Zambia", icon: Zambia},
	{value: "USH", label: "USH", disabled: false, remarks: "Uganda", icon: Uganda},
	{value: "CEDI", label: "CEDI", disabled: false, remarks: "Ghana", icon: Ghana},
	{value: "JPY", label: "JPY", disabled: false, remarks: "Japan", icon: JPY},
	{value: "INR", label: "INR", disabled: false, remarks: "India", icon: INR},
	{value: "IDR", label: "IDR", disabled: false, remarks: "Indonesia", icon: IDR},
	{value: "VND", label: "VND", disabled: false, remarks: "Vietnam", icon: VND},
	{value: "MYR", label: "MYR", disabled: false, remarks: "Malaysia", icon: MYR},
	{value: "PHP", label: "PHP", disabled: false, remarks: "Philippines", icon: PHP},
	{value: "TWD", label: "TWD", disabled: false, remarks: "Taiwan", icon: TWD},
	{value: "Naira", label: "Naira", disabled: false, remarks: "Nigeria", icon: Naira},
	{value: "ZAR", label: "ZAR", disabled: false, remarks: "South Africa", icon: ZAR},
	{value: "EGP", label: "EGP", disabled: false, remarks: "Egypt", icon: EGP},
	{value: "ETB", label: "ETB", disabled: false, remarks: "Ethiopia", icon: ETB},
];

export const allowCurrency = ['KES', 'CNY']