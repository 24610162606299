import $request from '@/request';

export interface IApplication {
  completedCount: number;
  newCount: number;
  totalFundingAmount: number;
  inProgressCount: number;
}

export interface IGetUserSummaryRes {
  application: IApplication,
  company: {
    cgxSubmitted: boolean
    copyrightCount: number
    ggxSubmitted: boolean
    patentCount: number
    name: string
    id: string
  }
}

export const getUserSummary = (): Promise<IGetUserSummaryRes> => {
  return $request.get('/users/summary')
}


export interface IUserInfoParams {
  id: number
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  handPhone?: string
  address1?: string
  address2?: string
  country?: string
  pinCode?: string
}

export const userInfoSave = (d: IUserInfoParams): Promise<any> => {
  return $request.post('/remit-service/remit/user/edit', d)
}


export const getUserInfo = (): Promise<any> => {
  return $request.get('/remit-service/remit/user/getUserByToken', {})
}