import React, { FC, useState } from 'react'
import { DatePicker, Form } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './DefaultDatePicker.scss'
import { useTranslation } from 'react-i18next';

interface IDefaultDatePickerProps {
	setDateVal?: (str: any) => void;
	formItemName?: string;
	formItemLabel?: string;
	formItemTips?: string;
	placeholder?: string;
	notes?: string;
	tipsIsAbsolute?: boolean;
	pickerClassName?: string;
	bordered?: boolean;
	picker?: 'date' | 'week' | 'month' | 'quarter' | 'year';
	tipsStyle?: React.CSSProperties;
	popupStyle?: React.CSSProperties;
	disabled?: boolean;
  format?: string;
}

const DefaultDatePicker: FC<IDefaultDatePickerProps> = (props) => {
	const { setDateVal, formItemName, notes, formItemTips, placeholder, tipsIsAbsolute, pickerClassName = '', bordered, picker = 'date', tipsStyle, popupStyle, disabled, format = 'DD-MM-YYYY' } = props;
  const [haveDate, setHaveDate] = useState<boolean>(true);
  const [iSDateFocus, setIsDateFocus] = useState<boolean>(false);
	const {t, i18n} = useTranslation();

	const onDateChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(!value ? true : false)
  }

  const onDateFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  return (
    <div className='form-item-warp'>
			<Form.Item
				name={formItemName}
				label=""
				className={`${!haveDate && 'form-item-tips'} ${iSDateFocus && 'form-item-focus'}`}
			>
				<DatePicker
					className={pickerClassName}
					dropdownClassName="default-date-picker"
					placeholder={placeholder}
					popupStyle={popupStyle}
					bordered={bordered}
					disabled={disabled}
					picker={picker}
					onChange={(val) => {
						onDateChange(val, setHaveDate)
						setDateVal?.(val);
						onDateFocus(setIsDateFocus, false)
					}}
					onFocus={() => onDateFocus(setIsDateFocus, true)}
					onBlur={() => onDateFocus(setIsDateFocus, false)}
          format={format}
				/>
			</Form.Item>
			{
				!haveDate && (
					<span style={!tipsIsAbsolute ? tipsStyle : {}} className={!tipsIsAbsolute ? 'tips' : 'tips-relative'}>
						<ExclamationCircleOutlined /> {formItemTips ? formItemTips : `${t("请选择")}${placeholder}`}
					</span>
				)
			}
			{
				notes && (
					<div className='notes'>
						{notes}
					</div>
				)
			}
		</div>
  )
}

export default DefaultDatePicker
