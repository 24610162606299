import $request from '@/request';

export interface IOrderParams {
  pageSize?: number;
  pageNum: number;
}

export interface ICommonData {
  code: number;
  msg?: string;
}

export interface IRecipientRow {
  id: number;
  trnNo: string;
  status: string;
  sendingCurrency: string;
  sendingAmount: number;
  instCurrency: string;
  instAmount: number;
  transactionTime: string;
  createTime: string;
  exchangeRate: number;
  commission: number;
  deliveryMethod: number;
  paymentMethod: number;
  totalAmount: number;
  recipientName?: string;
  recipient: {
    firstName: string;
    lastName: string;
  }
}

export interface IOrderData {
  total: number;
  code: number;
  list: IRecipientRow[];
  msg?: string;
}

export interface IHistoryData {
  total: number;
  code: number;
  list: IHistoryRow[];
  msg?: string;
}

export interface IDetailData {
  action: number;
  bankRefNo: null | string;
  beneficiaryName: null | string;
  beneficiaryNo: string;
  cid: null | string;
  cityId: null | string;
  clientUserId: number;
  code: string;
  comments: string;
  commission: number;
  createBy: string;
  createTime: string;
  deliveryMethod: number;
  exchangeRate: number;
  fomoBeneRefNo: string;
  fomoCustRefNo: null | string;
  fomoTrnRefNo: null | string;
  geoLocation: null | string;
  id: number;
  instAmount: number;
  instCurrency: string;
  isViewed: number;
  mpCheckoutRequestId: null | string;
  mpConversationId: null | string;
  mpFirstName: null | string;
  mpLastName: null | string;
  mpMerchangeRequestId: null | string;
  mpMsisdn: string;
  mpOrigConversationId: null | string;
  mpResultCode: null | string;
  mpResultDesc: null | string;
  mpTransAmount: null | string;
  mpTransId: null | string;
  mpTransTime: null | string;
  mpTransactionId: null | string;
  orderId: null | string;
  params: any;
  parentId: null | string;
  passNo: null | string;
  paymentMethod: number;
  pd: null | string;
  pendingPaymentId: null | string;
  processDateTime: null | string;
  recipient: any;
  relationship: null | string;
  remark: null | string;
  remitPurpose: string;
  resultCode: number;
  riskCategory: null | string;
  searchValue: null | string;
  senderAccountName: null | string;
  senderAccountNo: null | string;
  senderBankBranch: null | string;
  senderBankCode: null | string;
  senderBankCountry: null | string;
  senderBankName: null | string;
  senderEmail: null | string;
  senderName: null | string;
  sendingAmount: number;
  sendingCurrency: string;
  settleTime: null | string;
  snippet: null | string;
  sourceOfFund: string;
  status: string;
  subuser: false
  supportingDoc: null | string;
  thirdPartyAmount: null | string;
  thirdPartyRate: null | string;
  totalAmount: number;
  transactionScore: null | string;
  transactionTime: string;
  transactionTimeStr: string;
  trnNo: string;
  trnSource: number;
  trnType: string;
  type: number;
  updateBy: string;
  updateTime: null | string;
  userId: number;
  wechatAuditNumber: null | string;
  wechatError: null | string;
  wechatQrcodeUrl: null | string;
  wechatReason: null | string;
  wechatTransaction: null | string;
}

export interface IHistoryRow {
  bankPayload: null | string;
  createBy: null | string;
  createTime: string;
  currentCode: string;
  currentStatus: string;
  id: number;
  mpCheckoutRequestId: null | string;
  mpConversationId: null | string;
  mpFirstName: null | string;
  mpLastName: null | string;
  mpMerchangeRequestId: null | string;
  mpMsisdn: null | string;
  mpOrigConversationId: null | string;
  mpResultCode: null | string;
  mpResultDesc: null | string;
  mpTransAmount: null | string;
  mpTransId: null | string;
  mpTransTime: null | string;
  mpTransactionId: null | string;
  mpesaPayload: null | string;
  pendingPaymentId: null | string;
  prevCode: string;
  prevStatus: string;
  remarks: string;
  trnId: number;
  trnNo: string;
  wechatAuditNumber: null | string;
  wechatError: null | string;
  wechatPayload: null | string;
  wechatQrcodeUrl: null | string;
  wechatReason: null | string;
  wechatTransaction: null | string;
}

export const queryOrders = (d: IOrderParams): Promise<IOrderData> => {
  return $request.post('/remit-service/remit/transaction/list/page', {
    pd: {
      pageSize: d.pageSize ?? 10,
      pageNum: d.pageNum,
      orderByColumn: 'create_time',
      isAsc: 'desc'
    }
  })
}

export const getOrderDetail = (transactionId: string): Promise<IDetailData> => {
  return $request.post(`/remit-service/remit/transaction/getById/${transactionId}`, {})
}

export const getOrderHistory = (transactionId: number): Promise<IHistoryData> => {
  return $request.get(`/remit-service/remit/transaction/history/${transactionId}`)
}

export const cancelPayment = (id: string | number): Promise<ICommonData> => {
  return $request.post(`/remit-service/remit/transaction/cancel`, {id})
}