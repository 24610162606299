import React, {FC, useState, useEffect} from 'react'
import {List, message, Pagination} from 'antd';
import {localFormat} from '@/utils/moment';
import {history} from "@/route";
import Arrow from "@/assets/image/order/arrow.png";
import {useTranslation} from "react-i18next";
import type {IRecipientRow} from '@/api/order/order'
import {ordertApi} from '@/api'
import {formatCurrency} from "@/utils/utils";
import {ops} from '../OrderDetail/OrderDetail'
import type {Status} from "../../Order";
import styles from "../../Order.module.scss";

interface IH5TableProps {
  status: Status;
  getStatus: (s: string) => string;
  setEmptyStatus: (s: boolean) => void;
  color: {
    [key: string]: string
  }
}
const H5Table: FC<IH5TableProps> = ({status, getStatus, setEmptyStatus, color}) => {
  const {t} = useTranslation();
  const [dataSource, setDataSource] = useState<IRecipientRow[]>([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showTotal: (total: any) => `Total ${total} orders`,
  });

  const getOrders = async () => {
    try {
      const {list, total} = await ordertApi.queryOrders({pageNum: pagination.current, pageSize: 10})
      setDataSource(list ?? [])
      setPagination({...pagination, total})
      setEmptyStatus(!!total)
    } catch (error) {
      setEmptyStatus(false)
    }
  }

  const returnIcon = (item: IRecipientRow) => {
    const s = getStatus(item.status)
    return (<div className={`${styles.statusContent}`}>
      <span style={{borderColor: color[s]}} className={`${styles.status} flex-all-center`}>
        <img src={status[s as string]} alt="" />
        <span style={{color: color[s]}}>{s}</span>
      </span>
    </div>)
  }

  useEffect(() => {
    getOrders()
  }, [pagination.current])

  return (
    <div className={styles.h5Root}>
      <div className={styles.container}>
        <span className={styles.label}>{t('订单')}</span>
        <List
          dataSource={dataSource}
          renderItem={item => (
            <List.Item>
              <div className={styles.card} onClick={() => history.replace(`/order?id=${item.id}`)}>
                <div className={styles.middle}>
                  <span className={styles.name}>
                    {`${item.recipient?.firstName ?? ''} ${item.recipient?.lastName ?? ''}`}
                  </span>
                  <span className={styles.method}>
                    {`${ops[item.paymentMethod]?.key} → ${ops[item.deliveryMethod]?.key}`}
                  </span>
                </div>
                <div className={styles.middle}>
                  <div className={styles.createTime}>{item.createTime ? localFormat(item.createTime, true) : ''}</div>
                  <div className={styles.right}>
                    <span className={`${styles.send} ellipsis`}>{`${formatCurrency(item.sendingAmount)} ${item.sendingCurrency}`}</span>
                    <img src={Arrow} alt="" />
                  </div>
                </div>
                {returnIcon(item)}
              </div>
            </List.Item>
          )}
        />
        <div className={styles.pageSizeBox}>
          <Pagination
            onChange={(current: number) => {
              setPagination({...pagination, current})
            }}
            hideOnSinglePage={true}
            size={'small'}
            {...pagination}
          />
        </div>
      </div>
    </div>)
}


export default H5Table;