import axios, {AxiosInstance} from 'axios'
import {message} from 'antd'
import {signOut} from '@/utils/utils'
import i18next from "i18next";
import { store } from './index'
import urljoin from 'url-join'

export const picURL = "https://wpg.wapipay.com/remit-service/profile/" // 图片url

export const productionUrl = "https://global-api-new.wapipay.com/" // 线上环境
export const stagingUrl = "https://global-api-staging.beblur.com/" // 测试环境

export const baseURL = process.env.REACT_APP__ENV === 'production' ? productionUrl : stagingUrl;

export const getToken = () => {
  const tokenItem = document.cookie.split(';').find(item => ~item.substring(0, 10).indexOf('token'))
  const token = tokenItem && tokenItem.slice(tokenItem.indexOf('=') + 1)
  if (token) {
    return token
  }
  return ''
}

let params = {
  baseURL,
  timeout: 100000,
  headers: {
  }
}

let instance: AxiosInstance = axios.create(params)

instance.interceptors.request.use(function (config) {
  const token = getToken()
  const { userInfo: { id } = {} } = store.getState().UserInfoReducer
  token && (config.headers['Authorization'] = `Bearer ${token}`)
  id && (config.headers['userId'] = `${id}`)
  config.headers.Version = '2.0.0'
  const lang = localStorage.getItem("wapipay-website-locale") || 'zh'
  config.url = urljoin(config.url || '', `?lang=${lang}`)
  return config;
}, function (error) {
  message.error(i18next.t("网络异常！"))
  return Promise.reject(error);
})

instance.interceptors.response.use(function (response) {
  const data = response?.data;
  // if (response.config.$sourceData) {
  //   return data
  // }
  // console.log('response=>', response)
  try {
    switch (data.code) {
      case 200:
        return data && data.data ? data.data : data;
      case 401:
        message.warning(i18next.t('登录已过期，请重新登录！'))
        signOut()
        setTimeout(() => {
          window.location.reload()
        }, 2000);
        break;
      default:
        message.warning(data.msg)
        return Promise.reject(data);
    }
  } catch (error) {
    return Promise.reject(error);
  }
}, function (error) {
  const errorRes = error?.response
  const errorInfo = errorRes?.data
  try {
    // 登陆过期
    if (errorRes.status === 401) {
      message.warning(i18next.t('登录已过期，请重新登录！'))
      signOut()
      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
    if (errorInfo.msg) {
      message.error(errorInfo.msg)
    } else {
      message.error(i18next.t('网络异常！请稍后重试！'))
    }
  } catch (error) {
    message.error(i18next.t('网络异常！请刷新后重试！'))
  }
  return Promise.reject(errorInfo);
})

export default instance
