import React, {FC, useRef, useState} from 'react'
import cls from "classnames";
import {CheckCircleOutlined} from '@ant-design/icons';
import styles from './Steps.module.scss'

declare type Placement = 'horizontal' | 'vertical'
interface StepsOptions {
  label: string;
  [key: string]: string | number;
}
export interface StepsProps {
  current: number;
  placement?: Placement;
  steps: StepsOptions[];
  className?: string;
  component?: (option: StepsOptions) => React.ReactNode;
  onChange?: (step: StepsOptions) => void;
}

const ReactSelect: FC<StepsProps> = (props) => {
  const {steps, current, placement = 'horizontal', onChange, component, className} = props;
  return (
    <div className={cls(styles.root, className, placement === 'vertical' ? styles.vertical : null)}>
      {
        steps.map((v, i) =>
          <div
            key={i}
            className={cls(styles.container, current === i && styles.active, current > i && styles.success)}
            onClick={() => onChange?.(v)}
          >
            {component ? component(v) :
              <div className={styles.content}>
                <div className={styles.item}>
                  {/* {
                    current > i ?
                      <CheckCircleOutlined style={{fontSize: placement === 'vertical' ? 22 : 32, color: '#10C002'}} /> :
                      <div className={styles.index}>{i + 1}</div>
                  } */}
                  <div className={styles.index}>{i + 1}</div>
                  {placement === 'vertical' && <div className={styles.label}>{v.label}</div>}
                </div>
                {placement === 'horizontal' && <div className={styles.label}>{v.label}</div>}
                {i !== steps.length - 1 &&
                  <div className={styles.border} style={{borderColor: current > i ? '#10C002' : '#f8f8f8'}} />}
              </div>
            }
          </div>)
      }
    </div>
  )
}

export default ReactSelect
