import React, { FC, useState } from 'react'
import { Button, message } from 'antd'
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import styles from './index.module.scss'
import ProofAddressIcon from '@/assets/image/login/selfie-photo.png';
import { useTranslation } from 'react-i18next';

interface IProofAddressProps{
	setFileData: (arr: any[]) => void;
	fileData: any[];
	onFileChange: (e: any, record: any, fileDataArr: any[], setFileDataArr: (arr: any[]) => void) => void;
}

const ProofAddress: FC<IProofAddressProps> = (props) => {
	const { setFileData, fileData = [], onFileChange } = props;
  const {t, i18n} = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.registerTitle}>{t("地址证明")}</div>
			<div className={styles.subtitle}>{t("上传一份您的银行结单，水电帐单或电话帐单的副本")}</div>
      <div className="login-form-container">
				<div className={styles.container}>
					<div className={styles.containerL}>
						<div className={styles.tipsLi}>
							<span className={styles.circular} />
							<span>{t("姓名和地址必须与您注册的档案相同")}</span>
						</div>
						<div className={styles.tipsLi}>
							<span className={styles.circular} />
							<span>{t("日期不超过3个月")}</span>
						</div>
						<div className={styles.tipsLi}>
							<span className={styles.circular} />
							<span>{t("图片必须清晰")}</span>
						</div>
					</div>
					<div className={`${styles.selectList}`}>
						{
							fileData.map((item: any) => (
								<div
									className={styles.selectLi}
									key={item.name}
								>
									<UploadComponent
										onChange={(e) => onFileChange(e, item, fileData, setFileData)}
										maxCount={1}
										showUploadList={false}
									>
										{
											item.src ? (
												<div className={styles.picWarp}>
													<img src={item.src} className={styles.pic} alt='' />
													<div className={styles.text}>{t("重新上传")}</div>
												</div>
											) : (
												<div className={styles.uploadWarp}>
													<div>
														<img src={ProofAddressIcon} className={styles.icon} alt='' />
														<div className={styles.fileName}>{item.name}</div>
													</div>
												</div>
											)
										}
									</UploadComponent>
								</div>
							))
						}
					</div>
				</div>
				<Button disabled={fileData.filter((item: any) => item.src).length > 0 ? false : true} type="primary" htmlType="submit" className='submit-button'>
					{t("下一步")}
				</Button>
      </div>
    </div>
  )
}

export default ProofAddress
