import React, {FC} from 'react'
import styles from './index.module.scss'
import { isMobile } from "@/utils/utils";
import NoDataPic from '@/assets/components/no-data.png'
import { IDefaultSelectProps } from '../DefaultSelect/DefaultSelect'

interface INoDataComProps {
	title?: string;
	subtitle?: string;
	imgSrc?: any;
	btnTitle?: string;
	btnClick?: any;
	warpClassName?: string;
	imgClassName?: string;
	titleWarpClassName?: string;
	btnClassName?: string;
	subBtnTitle?: string
	subBtnClick?: any;
	btnChildren?: any;
}

const NoDataCom: FC<INoDataComProps & IDefaultSelectProps> = (props) => {
	const {
		title,
		subtitle,
		imgSrc = NoDataPic,
		btnTitle,
		btnClick,
		warpClassName,
		imgClassName,
		titleWarpClassName,
		btnClassName,
		subBtnTitle,
		subBtnClick,
		btnChildren,
    children,
	} = props;

	const titleWarp = (
		<div className={`${styles.titleWarp} ${titleWarpClassName}`}>
			<div className={styles.title}>{ title }</div>
			<div className={styles.subtitle}>{ subtitle }</div>
		</div>
	)

  return (
    <div className={`${styles.root} ${warpClassName}`}>
			{ !isMobile() && titleWarp }
			<img className={`${styles.pic} ${imgClassName}`} src={imgSrc} alt="" />
			{ isMobile() && titleWarp }
			{
				btnTitle && (
					<div className={`${styles.btn} ${btnClassName}`} onClick={btnClick}>{btnTitle}</div>
				)
			}
			{ children }
			{
				subBtnTitle && (
					<div className={`${styles.btn} ${styles.subBtn}`} onClick={subBtnClick}>{subBtnTitle}</div>
				)
			}
			{ btnChildren }
		</div>
  )
}

export default NoDataCom
