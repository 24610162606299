import React, {FC, useState, useEffect} from 'react'
import { Pagination, message, Spin, Table} from 'antd';
import styles from './index.module.scss'
import {NoDataCom} from '@/components';
import {isMobile} from '@/utils/utils';
import BackIcon from '@/assets/image/login/back-icon.png';
import {useTranslation} from "react-i18next";
import {querySubOrder} from '@/api/batchOrder';
import {useMappedState} from "redux-react-hook";
import {IState} from "@/redux/interface";
import {useHistory} from 'react-router-dom';
import {RegistrationStatus} from '@/api/auth/auth'
import {formatCurrency} from "@/utils/utils";
import { status as statusArr } from '@/pages/Order/Order';
import {fixed6ToFillZero} from "@/pages/HomePage/utils";
import {localFormat} from '@/utils/moment';
import Arrow from "@/assets/image/order/arrow.png";
import { getParameters } from '@/utils/mainUtils';

interface ISubOrderProps {}

const colorArr: any = {
	Waiting: '#FF9900',
	Processing: '#3279E5',
	Failed: '#FF5C00',
	Complete: '#0FC000',
	Cancelled: '#7F68FB',
}

const SubOrder: FC<ISubOrderProps> = (props) => {
	const userInfoState = useMappedState(
		(state: IState) => state.UserInfoReducer.userInfo
	);
	const {registrationStatus} = userInfoState || {};
	const {t, i18n} = useTranslation();
	const history = useHistory();
	const [recipientData, setSubOrder] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const [totalUSD, setTotalUSD] = useState<number | string>(0);
	const [totalKES, setTotalKES] = useState<number | string>(0);
	const [totalCNY, setTotalCNY] = useState<number | string>(0);
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 8,
		total: 0,
		showTotal: (total: any) => `Total ${total} suborder`,
	});
	const { orderId, fileName } = getParameters();
	const formatEl = (text: any, records: any, key: string) => {
    const type = records[key];
    return <div className={styles.tableEl}>
      <img src={statusArr[type]} style={{width: 24, height: 24}} alt="" />
      <span className={styles.label}>{formatCurrency(text)}</span>
      <span className={styles.type}>{type}</span>
    </div>
  }
	const returnState = (status: string) => {
    const v = +status ?? 0;
    let s = '';
    if ([0, 1].includes(v)) {
      s = 'Waiting'
    } else if ([2, 3, 7].includes(v)) {
      s = 'Processing'
    } else if ([4, 10, -1].includes(v)) {
      s = 'Failed'
    } else if ([8, 11].includes(v)) {
      s = 'Complete'
    } else if ([9].includes(v)) {
      s = 'Cancelled'
    }
    return s
  }

	const setTotal = (list: any[], key: string, setTotalFn: (total: number | string) => void) => {
		let newTotal = 0;
		const arr = list.filter((item: any) => item.sendingCurrency === key);
		arr.forEach((item: any) => { newTotal += item.sendingAmount; })
		setTotalFn(formatCurrency(newTotal))
	}

	const getList = async (current: number = 1, pageSize: number = 8) => {
		try {
			setLoading(true);
			const {total, list = []} = await querySubOrder({
				orderId: orderId ? Number(orderId) : undefined,
				pageSize,
				pageNum: current
			})
			setTotal(list, 'KES', setTotalKES);
			setTotal(list, 'USD', setTotalUSD);
			setTotal(list, 'CNY', setTotalCNY);
			setSubOrder(list ?? [])
			setPagination({...pagination, current, pageSize, total})
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (registrationStatus === RegistrationStatus.Finished) getList(1, 8)
	}, [])

	const onShowSizeChange = (current?: number, pageSize?: number) => {
		if (current) {
			getList(current, pageSize);
		}
	};

	const columns: any = [
		{
      title: t("收款人"),
      dataIndex: 'recipientName',
      render: (text: any, records: any) => `${records.recipient?.firstName ?? ''} ${records.recipient?.lastName ?? ''}`,
    },
    {
      title: t("订单号"),
      dataIndex: 'trnNo',
    },
    {
      title: t("汇款金额"),
      dataIndex: 'sendingAmount',
      render: (text: any, records: any) => formatEl(text, records, 'sendingCurrency'),
    },
    {
      title: t("收款金额"),
      dataIndex: 'instAmount',
      render: (text: any, records: any) => formatEl(text, records, 'instCurrency'),
    },
    {
      title: t("汇率"),
      dataIndex: 'exchangeRate',
      render: (text: any, records: any) => fixed6ToFillZero(text),
    },
    {
      title: t("状态"),
      dataIndex: 'status',
      width: 160,
      render: (text: any, record: any) => {
        const s = returnState(text)
        return (<span className={`${styles.statusContent} flex-center`}>
          <div style={{borderColor: colorArr[s]}} className={`${styles.status} flex-all-center`}>
            <img src={statusArr[s as string]} alt="" />
            <span style={{color: colorArr[s]}}>{s}</span>
          </div>
        </span>)
      },
    },
    {
      title: t("订单时间"),
      dataIndex: 'createTime',
      render: (text: any, records: any) => text ? localFormat(text, true) : '',
    },
	];

	const gotoOrderDetail = (item?: any) => {
		history.push(`/batchOrders/subOrder/detail?id=${item.id}`)
	}

	return (
		<div className={styles.root}>
			<Spin spinning={loading} tip="Loading">
				{
					recipientData.length > 0 ? (
						<>
							{
								isMobile() ? (
									<div className={styles.recipientWarp}>
										{ fileName && fileName !== 'null' && <div className={`${styles.title} ellipsis`}>{fileName}</div> }
										<div className={styles.totalWarpMobile}>
											<div className={styles.totalLiMobile}>
												<div className={styles.liTitle}>Total USD</div>
												<div className={styles.num}>{ totalUSD }</div>
											</div>
											<div className={styles.totalLiMobile}>
												<div className={styles.liTitle}>Total KES</div>
												<div className={styles.num}>{ totalKES }</div>
											</div>
											<div className={styles.totalLiMobile}>
												<div className={styles.liTitle}>Total CNY</div>
												<div className={styles.num}>{ totalCNY }</div>
											</div>
											{/* <div className={styles.totalLiMobile}>Total KES: { totalKES }</div>
											<div className={styles.totalLiMobile}>Total CNY: { totalCNY }</div> */}
										</div>
										<div className={styles.recipientList}>
											{
												recipientData.map((item: any, index: number) => (
													<div onClick={() => gotoOrderDetail(item)} className={styles.recipientLi} key={`${index}`}>
														<div className={styles.liLeft}>
															<div className={styles.name}>{item?.recipient?.firstName} {item?.recipient?.lastName}</div>
															<div className={styles.time}>{item?.createTime ? localFormat(item.createTime, true) : ''}</div>
															<div className={styles.statusWarp}>
																<span className={`${styles.statusContent} flex-center`}>
																	<div style={{borderColor: colorArr[returnState(item.status)]}} className={`${styles.status} flex-all-center`}>
																		<img src={statusArr[returnState(item.status) as string]} alt="" />
																		<span style={{color: colorArr[returnState(item.status)]}}>{returnState(item.status)}</span>
																	</div>
																</span>
															</div>
														</div>
														<div className={styles.liRight}>
															<span className={`${styles.send} ellipsis`}>{`${formatCurrency(item.sendingAmount)} ${item.sendingCurrency}`}</span>
															{/* <img src={Arrow} alt="" /> */}
														</div>
													</div>
												))
											}
										</div>
									</div>
								) : (
									<>
										<div className={styles.goBack} onClick={() => history.goBack()}>
											<img src={BackIcon} className={styles.backIcon} alt='' /> {t("返回")}
										</div>
										<Table
											columns={columns}
											dataSource={recipientData}
											pagination={false}
											className={styles.formTable}
											scroll={{ x: 1200 }}
											onRow={r => {
												return {
													onClick: () => {
														gotoOrderDetail(r)
													},
												};
											}}
										/>
										<div className={styles.totalWarp}>
											<div className={styles.totalLi}>Total USD: { totalUSD }</div>
											<div className={styles.totalLi}>Total KES: { totalKES }</div>
											<div className={styles.totalLi}>Total CNY: { totalCNY }</div>
										</div>
									</>
								)
							}
							{
								recipientData.length > 0 && (
									<div className={styles.pageSizeBox}>
										<Pagination
											onChange={onShowSizeChange}
											{...pagination}
										/>
									</div>
								)
							}
							{/* {
								isMobile() && (
									<div
										className={styles.addBtnBox}
										onClick={() => {
											if (!userInfoState?.id) {
												history.push('/login');
											} else {
												if (registrationStatus === RegistrationStatus.Unfinished) history.push('/register');
												if (registrationStatus === RegistrationStatus.Finished) {
													setIsModalVisible(true)
												}
											}
										}}
									>
										<PlusOutlined className={styles.addIconClass} /><span>{t("添加收款人")} </span>
									</div>
								)
							} */}
						</>
					) : (
						<>
							{/* {
								loading ? (
									<div style={{ minHeight: 500 }} />
								) : (
									<NoDataCom
										title={t('没有批量订单')}
										subtitle={t('你还没有批量订单。')}
										btnClick={() => {
                      if (registrationStatus === RegistrationStatus.Unfinished) {
                        message.warning(t("请完成注册后操作！"))
                        // history.push('/register');
                      } else if (userInfoState.id) {
                        setIsModalVisible(true)
                      } else {
                        message.warning(t("您未登录，请登录后操作！"))
                      }
                    }}
									/>
								)
							} */}
						</>
					)
				}
			</Spin>
		</div>
	)
}

export default SubOrder
