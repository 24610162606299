import type {IConfigurationRow} from "@/api/public/public";
import {SET_REMIT_PURPOSE, SET_SOURCE_OF_FUN, SET_REMIT_SYS_PARAMS, SET_BANK_DATA} from '../action'

const initialState = {
  publicConfig: {
    name: 'Wapi Pay'
  },
  remitPurpose: [],
  sourceOfFund: [],
  remitSysParams: {},
  bankData: [],
  TencentCaptchaConfig: {
    TencentCaptchaAppId: '198497256'
  }
}

export interface IPublicConfig {
  publicConfig: {
    name: string;
  };
  remitPurpose: IConfigurationRow[];
  sourceOfFund: IConfigurationRow[];
  remitSysParams: any;
  bankData: any[];
}

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_REMIT_PURPOSE:
      return Object.assign({}, state, {
        remitPurpose: action.remitPurpose,
      })
    case SET_SOURCE_OF_FUN:
      return Object.assign({}, state, {
        sourceOfFund: action.sourceOfFund,
      })
    case SET_REMIT_SYS_PARAMS:
      return Object.assign({}, state, {
        remitSysParams: action.remitSysParams,
      })
    case SET_BANK_DATA:
      return Object.assign({}, state, {
        bankData: action.bankData,
      })
    default:
      return state
  }
}
export default reducer