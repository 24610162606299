import moment, {Moment} from 'moment'

// Defining Kenya time Zone
const timeZone = 3 * 60


type TTime = Moment | string | Date

// Read back-end time data
export const localFormat = (time: TTime, format?: string | boolean): Moment | string => {
  const localTime = moment(time).utcOffset(moment().utcOffset() * 2 - timeZone)
  return formatTime(localTime, format)
}

// Upload back-end time data
export const uploadLocalFormat = (time: TTime, format?: string | boolean): Moment | string => {
  const localTime = moment(time).utcOffset(180)
  return formatTime(localTime, format)
}


function formatTime (localTime: Moment, format?: string | boolean): Moment | string {
  if (format === undefined) {
    return localTime
  } else if (typeof format === 'string') {
    return localTime.format(format)
  } else {
    return localTime.format('DD-MM-YYYY HH:mm:ss')
  }
}