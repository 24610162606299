import React, {useState,useLayoutEffect} from 'react'
import type { MutableRefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill'

type TargetValue<T> = T | undefined | null;

type TargetType = HTMLElement | Element | Window | Document;

type BasicTarget<T extends TargetType = Element> =
  | (() => TargetValue<T>)
  | TargetValue<T>
  | MutableRefObject<TargetValue<T>>;

export interface Size {
    width?:number,
    height?:number,
}

function getTargetElement<T extends TargetType>(target: BasicTarget<T>, defaultElement?: T) {
  if (!target) return defaultElement;
  let targetElement: TargetValue<T>;
  if (typeof target === 'function') {
    targetElement = target();
  } else if ('current' in target) {
    targetElement = target.current;
  } else {
    targetElement = target;
  }
  return targetElement;
}

export default function useSize(target: BasicTarget):Size{
    const [state,setState]=useState<Size>(()=>{
        const el = getTargetElement(target)
        return {
            width:((el||{}) as HTMLElement).clientWidth,
            height:((el||{}) as HTMLElement).clientWidth,
        }
    })

    useLayoutEffect(()=>{
        const el = getTargetElement(target)
        if(!el)return () => {}
        const resizeObserver=new ResizeObserver((entries)=>{
            entries.forEach((entry)=>{
                setState({
                    width:entry.target.clientWidth,
                    height:entry.target.clientHeight,
                })
            })
        })
        resizeObserver.observe(el)
        return ()=>{
            resizeObserver.disconnect()
        }
    },[target])

    return state
}