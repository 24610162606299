import React, { FC } from 'react'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import NoDataCom from '@/components/NoDataCom';
import RegisterSuccessIcon from "@/assets/image/login/register-success.png";
import styles from './RegisterSuccess.module.scss'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IRegisterSuccessProps{
}

const RegisterSuccess: FC<IRegisterSuccessProps> = (props) => {
	const history = useHistory();
	const {t, i18n} = useTranslation();

  return (
    <div className={styles.root}>
      <HeadComponent />
			<div className={styles.rootContent}>
				<NoDataCom
					title={t("创建成功")}
					subtitle={t('恭喜你，你已成功创建Wapi Pay账号')}
					imgSrc={RegisterSuccessIcon}
					btnTitle={t("完成")}
					btnClick={() => history.push('/home')}
				/>
			</div>
			<FooterComponent />
    </div>
  )
}

export default RegisterSuccess
