import React, {FC, useState} from 'react'
import styles from './Input.module.scss'
import deleteIcon from '@/assets/declare/delete_icon.svg';
import {Input} from 'antd'

interface IInputProps {
  style?: React.CSSProperties;
  placeholder?: string;
  className?: string;
  isControl?: boolean;
  value?: string;
  inputType?: string;
  onChange?: (v: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
}

export const CounterInput: FC<IInputProps> = (props) => {
  const {placeholder, value, onChange, onBlur, onFocus, isControl = false, inputType} = props
  return (
    <div className={styles.countInputWrap}>
      {isControl ?
        <input
          type={inputType}
          value={value}
          className={`${styles.countInputField} ${value && styles.validInputField}`}
          onBlur={() => {
            onBlur?.()
          }}
          onFocus={() => {
            onFocus?.()
          }}
          onChange={(e) => {
            const t = e.target.value
            if (value && +t >= 10000000 && +t > +value) return
            onChange?.(t)
          }}
        />
        :
        <input
          type={inputType}
          className={`${styles.countInputField} ${value && styles.validInputField}`}
          onBlur={() => {
            onBlur?.()
          }}
          onFocus={() => {
            onFocus?.()
          }}
          onChange={(e) => {
            onChange?.(e.target.value)
          }}
        />
      }
      <label className={styles.countInputLabel}>{placeholder}</label>
    </div>
  )
}
