import React, {FC, useEffect, useRef} from 'react'
import {Row, Col, Select, message} from 'antd';
import cls from "classnames";
import {useMappedState} from "redux-react-hook";
import type {IState} from "@/redux/interface";
import {useTranslation} from "react-i18next";
import Exchange from "@/assets/image/remittance/exchange.png";
import {exchangeType} from "@/pages/HomePage/HomePage";
import {CounterInput} from '@/components/Input/Input'
import useSize from "@/utils/useSize";
import {useRemittanceContext} from "@/pages/Remittance/RemittanceContext";
import {remittanceApi} from '@/api'
import {toFixed2, computedRate, fixed6ToFillZero} from "@/pages/HomePage/utils";
import {formatCurrency} from "@/utils/utils";
import styles from './CurrencyExchange.module.scss'
import turnDown from "@/assets/components/turn-down.png";
import {exchangeRateOptions} from "@/utils/defaultData";

interface ICurrencyExchangeProps {
  onChange?: (t: string) => void;
  defaultValue?: any;
}

const {Option} = Select;

const CurrencyExchange: FC<ICurrencyExchangeProps> = ({onChange, defaultValue}) => {
  const {t} = useTranslation();
  const {payment, setPayment} = useRemittanceContext();
  const ref1 = useRef<HTMLDivElement>(null);
  const {width: w1} = useSize(ref1);
  const ref2 = useRef<HTMLDivElement>(null);
  const {width: w2} = useSize(ref2);

  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );

  useEffect(() => {
    if (!defaultValue) {
      localStorage.setItem('home-incoming-rate', '0')
    }
  }, [defaultValue])

  useEffect(() => {
    if (localStorage.getItem('home-incoming-rate') === '1') {
      return
    };
    setPayment({
      ...payment,
      totalAmount: `${((+payment.commission ?? 0) + (+payment.sendingAmount ?? 0))}`
    })
  }, [payment.sendingAmount, payment.commission])

  useEffect(() => {
    if (!+payment.sendingAmount || localStorage.getItem('home-incoming-rate') === '1') return
    const instAmount = toFixed2((+payment.sendingAmount || 1) / payment.exchangeRate);
    setPayment({
      ...payment,
      instAmount,
      formatInst: formatCurrency(+instAmount)
    })
  }, [payment.exchangeRate])

  const onExchange = () => {
    localStorage.setItem('home-incoming-rate', '0')
    const instCurrency = payment.instCurrency;
    setPayment({
      ...payment,
      instCurrency: payment.sendingCurrency,
      sendingCurrency: instCurrency,
    })
  }

  const getRate = async (foreignCurrency: string, localCurrency: string) => {
    const noKes = payment.instCurrency !== 'KES' && payment.deliveryMethod === 2
    const {list} = await remittanceApi.queryExchangeRate({
      localCurrency,
      foreignCurrency,
      custType: userInfoState.custType
    })
    const r = list?.[0]?.exchangeRate
    const exchangeRate = computedRate(r);
    if (!exchangeRate) {
      setPayment({
        ...payment,
        exchangeRate,
        beneficiaryNo: '',
        recipientName: '',
        instAmount: '',
        sendingAmount: '',
        formatSend: '',
        formatInst: '',
        deliveryMethod: noKes ? 3 : payment.deliveryMethod
      })
      message.warning(t('汇率查询失败'))
    } else {
      setPayment({
        ...payment,
        exchangeRate,
        beneficiaryNo: '',
        recipientName: '',
        deliveryMethod: noKes ? 3 : payment.deliveryMethod
      })
    }
  }

  const inputFormat = (type?: string) => {
    const {sendingAmount, instAmount} = payment
    if (type === 'focus') {
      setPayment({...payment, inputType: 'number'})
    } else {
      if (!sendingAmount) return;
      setPayment({
        ...payment,
        formatSend: formatCurrency(+sendingAmount),
        formatInst: formatCurrency(+instAmount),
        instAmount: instAmount ? `${Math.floor(+instAmount * 100) / 100}` : instAmount,
        sendingAmount: sendingAmount ? `${Math.floor(+sendingAmount * 100) / 100}` : sendingAmount,
        inputType: 'text',
      })
    }
  }

  useEffect(() => {
    if (localStorage.getItem('home-incoming-rate') === '1') return;
    getRate(payment.sendingCurrency, payment.instCurrency)
  }, [payment.sendingCurrency, payment.instCurrency])

  const optionItem = (v: any, className: string, isOption: boolean, disabled: boolean, i?: number) =>
    <div className={`flex-center ${className} ${disabled && styles.disabled} ${i === 2 && styles.group}`}>
      <img src={v.icon} alt="" />
      <span className={styles.label}>{v.label}</span>
      {isOption ? <span>{v.remarks}</span> : <img className={styles.turnDownIcon} src={turnDown} alt="" />}
    </div>

  const exchangeCard = (label: string, key: number, type: string, td: string) =>
    <div className={styles.wrap}>
      <Row>
        <Col className={styles.inputWrap} xs={14} sm={15} ref={ref1}>
          <CounterInput
            placeholder={label}
            value={key ?
              (payment.inputType === 'number' ? payment.instAmount : payment.formatInst) :
              (payment.inputType === 'number' ? payment.sendingAmount : payment.formatSend)}
            isControl={true}
            inputType={payment.inputType}
            onBlur={() => inputFormat('blur')}
            onFocus={() => inputFormat('focus')}
            onChange={(count) => {
              localStorage.setItem('home-incoming-rate', '0')
              if (!payment.exchangeRate) return;
              if (!count) {
                setPayment({...payment, instAmount: '', sendingAmount: '', totalAmount: '0', commission: 0})
                return;
              }
              const sendingAmount = key ? toFixed2((+count ?? 0) * payment.exchangeRate) : count
              const instAmount = key ? count : toFixed2((+count ?? 0) / payment.exchangeRate)
              setPayment({...payment, sendingAmount, instAmount})
            }}
          />
        </Col>
        <Col className={styles.selectWrap} xs={10} sm={9} ref={ref2}>
          <Select
            optionLabelProp="label"
            bordered={false}
            getPopupContainer={(triggerNode: {parentNode: any;}) => triggerNode.parentNode || document.body}
            dropdownMatchSelectWidth={(w1 ?? 0) + (w2 ?? 0) + 2}
            dropdownStyle={{right: 0}}
            listHeight={400}
            showArrow={false}
            {...(type ? {value: type} : {})}
            onChange={(f: string) => {
              localStorage.setItem('home-incoming-rate', '0')
              const e = exchangeRateOptions.filter(t => t.value === f)[0]
              const sendingCurrency = key ?
                (e.value === payment.sendingCurrency ? exchangeType[e.value] : payment.sendingCurrency) : e.value;
              const instCurrency = key ?
                e.value : (e.value === payment.instCurrency ? exchangeType[e.value] : payment.instCurrency);
              setPayment({...payment, sendingCurrency, instCurrency})
              if (key) onChange?.(f)
            }}
            onSelect={(f: any) => {
              const item = exchangeRateOptions.filter(t => t.value === f)[0]
              if (item && !item.support) {
                message.warning(t('即将推出...'))
              }
            }}
          >
            {
              exchangeRateOptions.map((v, i) =>
                <Option
                  value={v.value}
                  key={v.value}
                  disabled={v.disabled}
                  label={optionItem(v, styles.opValue, false, false)}>
                  {optionItem(v, styles.opItem, true, !!v.disabled, i)}
                </Option>)
            }
          </Select>
        </Col>
      </Row>
    </div>

  return (
    <div className={cls(styles.exchangeRoot, styles.flexBetween)}>
      {exchangeCard(t('收款金额'), 1, payment.instCurrency, payment.sendingCurrency,)}
      <div onClick={onExchange} className={styles.exchangeTips}>
        <img src={Exchange} alt="" />
        <span>{`1 ${payment.instCurrency} = ${payment.exchangeRate ? fixed6ToFillZero(payment.exchangeRate) : ''} ${payment.sendingCurrency}`}</span>
      </div>
      {exchangeCard(t('汇款金额'), 0, payment.sendingCurrency, payment.instCurrency)}
    </div>
  )
}

export default CurrencyExchange
