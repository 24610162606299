import $request from '@/request';

export interface IPolicyMetaRes {
  expireAt: string;
}

type TMobile = string

export interface IPolicyMetaReq {
  captchaRandstr: string;
  captchaTicket: string;
  mobile: TMobile;
}

export interface IAuthLoginInReq {
  userName: TMobile;
  password: string;
}

export interface ILoginPhoneReq {
  handPhone: number;
  smsCode: number | string;
}

export enum CustType {
  Individual = 1,
  Agent = 2,
  Corporate = 3
}

export enum IdentificationTypes {
  IdNumber = 1,
  PassNo = 2
}

export enum IsDelete {
  NotDeleted = 0,
  Deleted = 1,
}

export enum Nationality {
  China = 1,
  Kenya = 2,
  America = 3,
  Uganda = 4,
  Ghana = 5,
  Zambia = 6,
  Japan = 7,
  India = 8,
  Indonesia = 9,
  Vietnam = 10,
  Malaysia = 11,
  Philippines = 12,
  Taiwan = 13,
  Nigeria = 14,
  'South Africa' = 15,
  Egypt = 16,
  Ethiopia = 17,
}

export enum RegistrationStatus {
  Unfinished = 1,
  Finished = 2
}

export enum Status {
  unauthorized = 0,
  certified = 1,
  invalid = 2
}

export enum Gender {
  Male = 10,
  Female = 20,
}

export interface IUserInfo {
  address1: string;
  address2: string;
  annualIncome: string;
  bankAccount: string;
  businessNumber: string;
  certOfIncorporation: string;
  certOfIncorporationNumber: string;
  companyName: string;
  contactNumber: string;
  contactPerson: string;
  country: string;
  custType: CustType;
  dateOfBirth: string;
  doc1: string;
  doc2: string;
  email: string;
  firstName: string;
  gender: null;
  handPhone: string;
  homePhone: string;
  id: number;
  idBack: string;
  idFront: string;
  identificationTypes: IdentificationTypes;
  isDelete: IsDelete;
  isNotifDisabled: null;
  jgUsername: string;
  kraPin: string;
  kraPinNumber: string;
  lastName: string;
  middleName: string;
  nationalId: string;
  nationality: Nationality;
  nickname: null;
  occupation: null;
  parentId: null;
  passExpiryDate: null;
  passIssueDate: null;
  passNo: string;
  passType: null;
  passportImg: string;
  password: string;
  passwordResetDate: null;
  pinCode: null;
  placeOfBirth: null;
  postalCode: string;
  qrCode: null;
  registrationStatus: RegistrationStatus;
  riskCategory: null;
  roles: IRoleItem[];
  selfie: string;
  smsCode: null;
  status: Status;
  title: string;
  userName: string;
  slug: string;
}

export interface IRoleItem {
  id: number;
  name: string;
}

export interface IAuthLoginInRes {
  token: string;
  user: IUserInfo;
}

export interface IApplication {
  newCount: number;
  inProgressCount: number;
  completedCount: number;
  totalFundingAmount: number;
}

export interface ICompany {
  id: number;
  name: string;
  copyrightCount: number;
  patentCount: number;
  ggxSubmitted: boolean;
  cgxSubmitted: boolean;
}

export interface ICheckCodeParams {
  verificationCode?: string | number;
  mobile?: string | number;
}

export const authLoginIn = (params: IAuthLoginInReq): Promise<IAuthLoginInRes> => {
  return $request.post('remit/api/v1/login', params)
}

export const loginByPhone = (params: ILoginPhoneReq): Promise<IAuthLoginInRes> => {
  return $request.post('remit/api/v1/loginByPhone', params)
}

export const checkVerificationCode = (params: ICheckCodeParams): Promise<any> => {
  return $request.get('remit/api/v1/checkVerificationCode', { params })
}
