import React, { FC, useState } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { countryOptions } from '@/utils/defaultData'
import { useHistory } from 'react-router';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DefaultInput } from '@/components';
import { checkDisabled } from '@/utils/mainUtils'
import './index.scss'
import {useTranslation} from "react-i18next";
import pinIcon from '@/assets/image/resetPin/pin-icon.png';
const { Option } = Select;

interface IResetPinProps{
	
}

const ResetPin: FC<IResetPinProps> = (props) => {
  const {t, i18n} = useTranslation();
  const [form] = Form.useForm();
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [haveCode, setHaveCode] = useState<boolean>(true);
  const [isCodeFocus, setIsCodeFocus] = useState<boolean>(false);
  const [codeVal, setCodeVal] = useState<string>('');
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [sendLoading, setSendLoading] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false);
  const timeCount = 60
  let timer = timeCount

	const onFinish = async (values: any) => {
		try {
      const { password, confirmPassword } = values || {};
      if (password !== confirmPassword) {
        message.warning(t('两次输入的新密码不一致'))
        return;
      }
    } catch (error) {
      
    }
	}

	const validateCode = async () => {
    if (count) return
		countTime()
  }

	const countTime = () => {
    timer = timeCount
    setCount(timer)
    const interval = setInterval(() => {
      if (timer <= 0) {
        clearInterval(interval)
      } else {
        timer--
        setCount(timer)
      }
    }, 1000)
  }

	const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(value ? true : false)
  }

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  return (
    <div className='reset-pin-page login-form-container'>
      <div className='register-wrapper'>
        <div className='register-box'>
					<img src={pinIcon} alt='' className='pin-icon' />
          <div className='register-box-title'>{t('修改密码')}</div>
          <Form form={form} onValuesChange={() => setIsUpdate(!isUpdate)} initialValues={{ prefix: 1 }} onFinish={onFinish} className="login-form">
						<div className='tel-tips-warp'>
							<div className='aa'>	{t('我们将会以短信的形式将验证码发送至手机')}</div>
							<div className='tel'>+8613**** 6309</div>
						</div>
						<div className='form-item-warp'>
              {
                !havePhone && (
                  <span className='tips'>
                    <ExclamationCircleOutlined /> {t('请输入您的手机号！')}
                  </span>
                )
              }
            </div>
            <div className={`form-item-warp verification-code ${!haveCode && 'form-item-tips'} ${isCodeFocus && 'form-item-focus'}`}>
              <Form.Item
                name="code"
              >
                <Input
                  placeholder={t('验证码')}
                  onChange={(e) => {
                    onInputChange(e, setHaveCode)
                    setCodeVal(e?.target?.value);
                  }}
                  onFocus={() => onInputFocus(setIsCodeFocus, true)}
                  onBlur={() => onInputFocus(setIsCodeFocus, false)}
                />
              </Form.Item>
              <div className={`code-box ${count && 'code-time-box'}`} onClick={validateCode}>
                {count ? `${count}s` : t('发送')}
              </div>
              {
                !haveCode && (
                  <span className='tips'>
                    <ExclamationCircleOutlined /> {t('请输入您的验证码！')}
                  </span>
                )
              }
            </div>
            {/* <DefaultInput
              formItemName="oldPassword"
              placeholder={t('旧密码')}
              tipsIsAbsolute={true}
              isPassword
              notes=''
            /> */}
            <DefaultInput
              formItemName="password"
              placeholder={t('新密码')}
              isPassword
            />
            <DefaultInput
              formItemName="confirmPassword"
              formItemTips={t('请再次输入您的新密码')}
              placeholder={t('确认密码')}
              isPassword
            />
            <Button disabled={!checkDisabled(form, 4)} type="primary" htmlType="submit" className='submit-button'>
						{t('修改密码')}
            </Button>
          </Form>
        </div>
      </div>
		</div>
  )
}

export default ResetPin
