import React, {FC} from 'react'
import Table from "@/components/Table/Table";
import {history} from "@/route";
import {useTranslation} from "react-i18next";
import {ordertApi} from '@/api'
import {formatCurrency} from "@/utils/utils";
import {ops} from '../OrderDetail/OrderDetail'
import {localFormat} from '@/utils/moment';
import {fixed6ToFillZero} from "@/pages/HomePage/utils";
import type {Status} from "../../Order";
import styles from "../../Order.module.scss";


interface IPcTableProps {
  status: Status;
  getStatus: (s: string) => string;
  setEmptyStatus: (s: boolean) => void;
  color: {
    [key: string]: string
  }
}

const WebTable: FC<IPcTableProps> = ({status, color, getStatus, setEmptyStatus}) => {
  const {t} = useTranslation();
  const formatEl = (text: any, records: any, key: string) => {
    const type = records[key];
    return <div className={styles.tableEl}>
      <img src={status[type]} style={{width: 24, height: 24}} alt="" />
      <span className={styles.label}>{formatCurrency(text)}</span>
      <span className={styles.type}>{type}</span>
    </div>
  }
  const formatMethod = (text: any) => {
    return <div className={styles.tableEl}>
      <img src={ops[text]?.icon} style={{width: 24, height: 24}} alt="" />
      <span className={styles.label}>{ops[text]?.key}</span>
    </div>
  }
  const columns = [
    {
      title: t("收款人"),
      dataIndex: 'recipientName',
      render: (text: any, records: any) => `${records.recipient?.firstName ?? ''} ${records.recipient?.lastName ?? ''}`,
    },
    {
      title: t("订单号"),
      dataIndex: 'trnNo',
    },
    {
      title: t("汇款金额"),
      dataIndex: 'sendingAmount',
      render: (text: any, records: any) => formatEl(text, records, 'sendingCurrency'),
    },
    {
      title: t("收款金额"),
      dataIndex: 'instAmount',
      render: (text: any, records: any) => formatEl(text, records, 'instCurrency'),
    },
    {
      title: t("汇款方法"),
      dataIndex: 'paymentMethod',
      render: (text: any, records: any) => formatMethod(text),
    },
    {
      title: t("收款方式"),
      dataIndex: 'deliveryMethod',
      render: (text: any, records: any) => formatMethod(text),
    },
    {
      title: t("汇率"),
      dataIndex: 'exchangeRate',
      render: (text: any, records: any) => fixed6ToFillZero(text),
    },
    {
      title: t("状态"),
      dataIndex: 'status',
      width: 160,
      render: (text: any, record: any) => {
        const s = getStatus(text)
        return (<span className={`${styles.statusContent} flex-center`}>
          <div style={{borderColor: color[s]}} className={`${styles.status} flex-all-center`}>
            <img src={status[s as string]} alt="" />
            <span style={{color: color[s]}}>{s}</span>
          </div>
        </span>)
      },
    },
    {
      title: t("订单时间"),
      dataIndex: 'createTime',
      render: (text: any, records: any) => text ? localFormat(text, true) : '',
    },
  ]
  return <div className={styles.pcRoot}>
    <h4>{t("订单")}</h4>
    <Table
      columns={columns}
      tableStyle={{padding: 0}}
      showPagination={true}
      getListFun={ordertApi.queryOrders}
      showSizeChanger={false}
      isEmpty={(s) => setEmptyStatus(s)}
      scrollX={1200}
      // @ts-ignore
      onRow={r => {
        return {
          onClick: () => {
            history.replace(`/order?id=${r.id}`)
          },
        };
      }}
    />
  </div>
}

export default WebTable;
