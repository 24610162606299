import React, { FC } from 'react'
import { Modal, ModalProps } from 'antd';
import { isMobile } from '@/utils/utils';
import styles from './Modal.module.scss'

interface IStyleWrapper {
  containerStyle?: React.CSSProperties;
  iconClassName?: string;
  iconSrc?: string;
  setIsModalVisible?: (flag: boolean) => void;
  modalClassName?: string;
  getContainer?: any;
}

const ResetModal: FC<ModalProps & IStyleWrapper> = (props) => {
  const {
    containerStyle,
    setIsModalVisible,
    getContainer = false,
    modalClassName,
  } = props;
  return (
    <Modal
      getContainer={getContainer}
      visible
      footer={null}
      centered={true}
      className={`${styles.modalWrap} ${modalClassName}`}
      // style={{ top: isMobile() ? 56 : 0 }}
      style={{ top: 0 }}
      onCancel={() => setIsModalVisible?.(false)}
      {...props}
    >
      <div className={styles.container} style={containerStyle}>
        {props.children}
      </div>
    </Modal>
  )

}

export default ResetModal
