import React, {FC, useState, useMemo, useEffect} from 'react'
import {Button, Select, Form, message, Input} from 'antd';
import styles from './index.module.scss'
import {deliveryOptions, countryOptions} from '@/utils/defaultData';
import {DefaultInput, DefaultSelect, SelectPicture} from '@/components';
import {checkDisabled, regularData} from '@/utils/mainUtils';
import {useTranslation} from "react-i18next";
import {getBankList} from '@/api/recipient/recipient';
import {addRecipient} from '@/api/invitation';
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import _ from 'lodash';
// import moment from 'moment'
import {IState} from '@/redux/interface';
import {useMappedState} from 'redux-react-hook';
import {ExclamationCircleOutlined, SyncOutlined} from '@ant-design/icons';
import { publicApi } from '@/api';
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import Card2 from '@/assets/image/recipient/card2.png';
import { getParameters } from '@/utils/mainUtils';
import { useHistory } from 'react-router-dom';

const {Option} = Select;

interface IInvitationPageProps {
  
}

const InvitationPage: FC<IInvitationPageProps> = (props) => {
	const history = useHistory();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  // const bankData = useMappedState(
  //   (state: IState) => state.PublicConfigReducer.bankData
  // );
  const {t, i18n} = useTranslation();
	const { fullName, slug } = getParameters();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [isPhoneFocus, setIsPhoneFocus] = useState<boolean>(false);
  const [genderOptions, setGenderOptions] = useState<any[]>([]);
  const [relationshipOptions, setRelationshipOptions] = useState<any[]>([]);
  const [accountTypeOptions, setAccountTypeOptions] = useState<any[]>([]);
  const [bankData, setBankData] = useState<any[]>([]);
  const locale = localStorage.getItem('wapipay-website-locale');
  const selectData = [
    { name: t("上传身份证/护照"), icon: Card2, key: 'idPhoto', src: '', val: '' },
  ]
	const [fileData, setFileData] = useState<any>(selectData)
  const [deliveryOptionsData, setDeliveryOptionsData] = useState<any[]>(deliveryOptions);

  const onFinish = async (values: any) => {
    const {receiveType, bankCode, prefix, handPhone, country} = values || {};
    if (receiveType === 2 && country !== 2) {
      message.warning(t("抱歉，M-PESA不适用于此收件人的国家/地区。"))
      return;
    }
    try {
      setIsLoading(true);
      // const {receiveType, bankCode} = values || {};
      const { currency } = countryOptions.find((item: any) => item.codeVal === country) || {};
      const formData = _.cloneDeep(values);
      // formData.nationality = values?.country;
      formData.FomoCustRefNo = "CF885";
      formData.handPhone = `${prefix}${handPhone}`
      formData.idPhoto = fileData[0]?.val;
      formData.accountCurrency = currency;
      formData.token = slug;
      if (receiveType === 4) {
        formData.bankCode = "EWALIPAY";
        formData.bankName = "支付宝";
      }
      if (receiveType === 3 && country === 1) {
        const {bankName} = bankData.find((item: any) => item.bankCode === bankCode) || {};
        formData.bankName = bankName;
      }
      delete formData?.prefix;
      console.log(formData)
      const {recipient} = await addRecipient(formData);
      message.success(t('添加成功'));
      setIsLoading(false);
      history.push(`/invitationSuccess?fullName=${fullName}`)
    } catch (error) {
      setIsLoading(false);
    }
  }

  const getConfigData = async (name: string, setData: (arr: any[]) => void) => {
    const {list = []} = await publicApi.getConfiguration({name})
    setData(list);
  }
  
  const getBankListFn = async () => {
    getBankList()
      .then(({list}) => {
        const data = list && list.length ? list : [];
        setBankData(data);
      })
  }

  useEffect(() => {
    getConfigData('gender', setGenderOptions);
    getConfigData('relationship', setRelationshipOptions);
    getConfigData('accountType', setAccountTypeOptions);
    getBankListFn();
  }, [])

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const {value} = e.target || {};
    fn(value ? true : false)
  }

  const onFileChange = async ( e: any, record: any) => {
    const { response, status, originFileObj } = e.file;
		let url = await new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(originFileObj);
			reader.onload = () => resolve(reader.result);
		});
    if (status === 'done') {
			const { data = [] } = response || {}
			const newArr = [...fileData]
			const fileSrc = data && data.length ? data[0]?.filePath : '';
			if (!fileSrc) return;
			newArr.forEach((item: any) => {
				if (item.key === record.key) {
					item.val = fileSrc;
					item.src = url;
					form.setFieldsValue({ [item.key]: url });
				}
			})
			setFileData(newArr);
		}
  }

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{minWidth: 80}}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{width: 250}}
        disabled
      // onFocus={() => onInputFocus(setIsPhoneFocus, true)}
      // onBlur={() => onInputFocus(setIsPhoneFocus, false)}
      // onSelect={() => onInputFocus(setIsPhoneFocus, false)}
      >
        {
          countryOptions.map((item: any) => (
            <Option
              value={item.value}
              key={`${item.value}`}
              label={
                <div className='flex-center'>
                  <img
                    src={item.icon}
                    className="country-logo"
                    style={{width: 21, marginRight: 5}}
                    alt=''
                  /> <span>{item.code}</span>
                </div>
              }
            >
              <div className='flex-center'>
                <img
                  src={item.icon}
                  className="country-logo"
                  style={{width: 24, marginRight: 8}}
                  alt=''
                /> {item.label} {item.code}
              </div>
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  const onLocaleChange = (value: string) => {
    i18n.changeLanguage(value);
    localStorage.setItem("wapipay-website-locale", value);
  };
  
  return (
    <div className={`${styles.root}`}>
      <HeadComponent isHideHome />
      <div className='login-form-container'>
        <Form
          form={form}
          onFinish={onFinish}
          className="login-form"
          onValuesChange={() => setIsUpdate(!isUpdate)}
          initialValues={{prefix: 254, country: 2}}
        >
          <div className={styles.title}>{fullName ? `${fullName.replace("-"," ")}` : ''}{ t("想转帐给你，请在下面提供你的信息。") }</div>
          <div className={styles.formBox}>
              <SelectPicture
                formItemName="receiveType"
                placeholder={t("收款方式")}
                optionLabelProp="label"
                optionData={deliveryOptionsData}
              />
              <SelectPicture
                formItemName="country"
                placeholder={t("收款方国家")}
                optionLabelProp="label"
                optionData={countryOptions}
                valueKey="codeVal"
                onChange={(val: any) => {
                  const {value} = countryOptions.find((item: any) => item.codeVal === val) || {};
                  form.setFieldsValue({prefix: value});
                  const newDeliveryOptions = _.cloneDeep(deliveryOptions);
                  newDeliveryOptions.forEach((item: any) => {
                    if (item.value === 2) {
                      item.disabled = val !== 2;
                    }
                  })
                  setDeliveryOptionsData(newDeliveryOptions);
                }}
              />
              <div className={styles.nameWarp}>
                <DefaultInput
                  formItemName="firstName"
                  placeholder={t("名字")}
                  tipsStyle={{bottom: '-15px'}}
                />
                <DefaultInput
                  formItemName="lastName"
                  placeholder={t("姓氏")}
                  tipsStyle={{bottom: '-15px'}}
                />
              </div>
              {/* <DefaultInput
                formItemName="handPhone"
                placeholder={t("联系电话")}
              /> */}
              <div className='form-item-warp'>
                <Form.Item
                  name="handPhone"
                  className={`${!havePhone && 'form-item-tips'} ${isPhoneFocus && 'form-item-focus'}`}
                >
                  <Input
                    placeholder={t('联系电话')}
                    addonBefore={prefixSelector}
                    className='phone-input'
                    onChange={(e) => {
                      onInputChange(e, setHavePhone)
                    }}
                    onFocus={() => onInputFocus(setIsPhoneFocus, true)}
                    onBlur={() => {
                      onInputFocus(setIsPhoneFocus, false)
                      const phone = form.getFieldValue('handPhone')
                      if (phone && phone.charAt(0) === '0') {
                        form.setFieldsValue({handPhone: phone.slice(1)})
                      }
                    }}
                  />
                </Form.Item>
                {
                  !havePhone && (
                    <span className='tips'>
                      <ExclamationCircleOutlined /> {t('请输入您的联系电话！')}
                    </span>
                  )
                }
              </div>
              {
                form.getFieldValue('receiveType') === 3 && (
                  <>
                    <DefaultInput
                      formItemName="email"
                      placeholder={t("电子邮箱")}
                      regular={regularData.email}
                      inputBlurFn={() => {
                        const email = form.getFieldValue('email')
                        if (email) {
                          form.setFieldsValue({email: email.toLowerCase()})
                        }
                      }}
                    />
                    <DefaultInput
                      formItemName="accountNo"
                      placeholder={t("账号号码")}
                      // inputType='number'
                      regular={/^\d+$/}
                    />
                    {
                      form.getFieldValue('country') !== 1 ? (
                        <DefaultInput
                          formItemName="bankName"
                          placeholder={t("银行名称")}
                        />
                      ) : (
                        <DefaultSelect
                          formItemName="bankCode"
                          placeholder={t("银行名称")}
                        >
                          {
                            bankData.map((item: any) => (
                              <Option
                                value={item.bankCode}
                                key={item.id}
                              >
                                {item.bankName}
                              </Option>
                            ))
                          }
                        </DefaultSelect>
                      )
                    }
                    <DefaultInput
                      formItemName="bankBranch"
                      placeholder={t("银行支行")}
                    />
                    <DefaultInput
                      formItemName="idProof"
                      placeholder={t("收款方ID")}
                    />
                  </>
                )
              }
              <DefaultSelect
                formItemName="nationality"
                placeholder={t("国籍")}
                optionLabelProp="label"
              >
                { 
                  countryOptions.map((item: any) => (
                    <Option
                      value={item.codeVal}
                      key={`${item.value}`}
                      label={
                        <div className='flex-center'>
                          {/* <img
                            src={item.icon}
                            className="country-logo"
                            alt=''
                            style={{ height: 14, marginRight: 5 }}
                          />  */}
                          <span>{item.nationality}</span>
                        </div>
                      }
                    >
                      <div className='flex-center'>
                        <img
                          src={item.icon}
                          className="country-logo"
                          alt=''
                          style={{ width: 24, marginRight: 8 }}
                        /> {item.nationality}
                      </div>
                    </Option>
                  ))
                }
              </DefaultSelect>
              <DefaultSelect
                formItemName="gender"
                placeholder={t("性别")}
              >
                {
                  genderOptions.map((item: any) => (
                    <Option
                      value={item.confCode}
                      key={item.confCode}
                    >
                      {item.text}
                    </Option>
                  ))
                }
              </DefaultSelect>
              <DefaultSelect
                formItemName="relationshipToCustomer"
                placeholder={t("关系")}
              >
                {
                  relationshipOptions.map((item: any) => (
                    <Option
                      value={item.confCode}
                      key={item.confCode}
                    >
                      {item.text}
                    </Option>
                  ))
                }
              </DefaultSelect>
              <DefaultSelect
                formItemName="accountType"
                placeholder={t("账户类型")}
              >
                {
                  accountTypeOptions.map((item: any) => (
                    <Option
                      value={item.confCode}
                      key={item.confCode}
                    >
                      {item.text}
                    </Option>
                  ))
                }
              </DefaultSelect>
              <div className={styles.formTitle}>{t("身份证（可选）")}</div>
              <div className={styles.selectList}>
                {
                  fileData.map((item: any) => (
                    <div
                      className={styles.selectLi}
                      key={item.name}
                    >
                      <UploadComponent
                        // accept={each.accept ?? accept}
                        // action={each.action ?? action}
                        onChange={(e) => onFileChange(e, item)}
                        maxCount={1}
                        showUploadList={false}
                      >
                        {
                          item.src ? (
                            <div className={styles.picWarp}>
                              <img src={item.src} className={styles.pic} alt='' />
                              <div className={styles.text}>{t("重新上传")}</div>
                            </div>
                          ) : (
                            <img src={item.icon} className={styles.icon} alt='' />
                          )
                        }
                        
                      </UploadComponent>
                      <div className={styles.name}>{item.name}</div>
                    </div>
                  ))
                }
              </div>

            </div>
            <div className={styles.btnWarp}>
              <Button disabled={form.getFieldValue('receiveType') === 2 ? !checkDisabled(form, 10) : regularData.email.test(form.getFieldValue('email')) ? !checkDisabled(form, form.getFieldValue('receiveType') === 3 ? 15 : 10) : true} type="primary" htmlType="submit" loading={isLoading} className='submit-button'>
                    {t("提交")}
              </Button>
            </div>
        </Form>
      </div>
      <div className={`${styles['language-box']}`}>
        <div className={`${styles['language-li']}`} onClick={()=>{onLocaleChange('zh')}}>中文</div>
        <div className={`${styles['language-li']}`} onClick={()=>{onLocaleChange('en')}}>English</div>
      </div>
      <FooterComponent />
    </div>
  )
}

export default InvitationPage