import React, { FC, useState } from 'react'
import { Form, Select} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './DefaultSelect.scss'
import { useTranslation } from 'react-i18next';

export interface IDefaultSelectProps{
	setSelectVal?: (str: any) => void;
	formItemName?: string;
	formItemLabel?: string;
	formItemTips?: string;
	placeholder?: string;
	tipsIsAbsolute?: boolean;
	selectClassName?: string;
	dropdownMatchSelectWidth?: boolean;
	selectStyle?: React.CSSProperties;
	dropdownStyle?: React.CSSProperties;
	optionLabelProp?: any;
	onChange?: (val: any) => void;
	disabled?: boolean;
	
}

const DefaultSelect: FC<IDefaultSelectProps> = (props) => {
	const { setSelectVal, formItemName, selectStyle, formItemTips, placeholder, tipsIsAbsolute, selectClassName = '', dropdownMatchSelectWidth, dropdownStyle, children, optionLabelProp, onChange, disabled, formItemLabel = ''} = props;
  const [haveSelect, setHaveSelect] = useState<boolean>(true);
  const [iSSelectFocus, setIsSelectFocus] = useState<boolean>(false);
  const {t, i18n} = useTranslation();

	const onSelectChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(value ? false : true)
  }

  const onSelectFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  return (
    <div className='form-item-warp default-select-item'>
			<Form.Item
				name={formItemName}
				label={formItemLabel}
				className={`${!haveSelect && 'form-item-tips'} ${iSSelectFocus && 'form-item-focus'}`}
			>
				<Select
					className={selectClassName}
					style={selectStyle}
					optionLabelProp={optionLabelProp}
					dropdownMatchSelectWidth={dropdownMatchSelectWidth}
					dropdownStyle={dropdownStyle}
					onFocus={() => onSelectFocus(setIsSelectFocus, true)}
					onBlur={() => onSelectFocus(setIsSelectFocus, false)}
					onSelect={() => onSelectFocus(setIsSelectFocus, false)}
					placeholder={placeholder}
					disabled={disabled}
					onChange={(val) => {
						onSelectChange(val, setHaveSelect)
						setSelectVal?.(val);
						onChange?.(val);
					}}
        >
					{ children }
				</Select>
			</Form.Item>
			{
				!haveSelect && (
					<span className={!tipsIsAbsolute ? 'tips' : 'tips-relative'}>
						<ExclamationCircleOutlined /> {formItemTips ? formItemTips : `${t("请选择")}${placeholder}`}
					</span>
				)
			}
		</div>
  )
}

export default DefaultSelect
