import React, {FC, useState, useEffect} from 'react'
import {Button, Form, message, Input} from 'antd';
import styles from './index.module.scss'
import {Modal} from '@/components';
import {useTranslation} from "react-i18next";
import _ from 'lodash';
// import moment from 'moment'
import {IState} from '@/redux/interface';
import {useMappedState} from 'redux-react-hook';
import {importWechatOrder} from '@/api/batchOrder';
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import WeChatIcon1 from '@/assets/image/batchOrder/wechat-temp-icon1.png';
import WeChatIcon2 from '@/assets/image/batchOrder/wechat-temp-icon2.png';

interface IUploadWechatOrderProps {
  setIsModalVisible: (flag: boolean) => void;
  getList?: (current?: number, pageSize?: number) => void;
  pageSize?: number;
}

const UploadWechatOrder: FC<IUploadWechatOrderProps> = (props) => {
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const {t, i18n} = useTranslation();
  const {setIsModalVisible, getList} = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [excelFile, setExcelFile] = useState<any>();
  const locale = localStorage.getItem('wapipay-website-locale');
  const enterpriseData = [
    { name: t("导入承诺书"), icon: WeChatIcon1, key: 'wordUrl', src: '', val: '', accept: ".doc,.docx", subName: 'DOC、DOCX' },
    { name: t("导入模板"), icon: WeChatIcon2, key: 'excelUrl', src: '', val: '', accept: ".xls,.xlsx", subName: 'XLS、XLSX' },
  ]
	const [fileData, setFileData] = useState<any>(enterpriseData)

  useEffect(() => {
   
  }, [])

  const importWechatOrderFn = async () => {
    try {
      setIsLoading(true);
      const formData: any = new FormData();
      const { val } = fileData.find((item: any) => item.key === 'wordUrl') || {}
      if (val) formData.append('wordUrl', val);
      formData.append('file', excelFile);
      const { code, msg } = await importWechatOrder(formData);
      if (code === 200) {
        message.success(msg)
        setIsModalVisible(false)
        getList?.(1, 8);
      } else {
        message.warning(msg)
        getList?.(1, 8);
      }
      setIsLoading(false);
    } catch (error: any) {
      if (error && error.code === '-22401') {
        setIsModalVisible(false)
        getList?.(1, 8);
      }
    }
  }

  const onFileChange = async ( e: any, record: any) => {
    const { response, status, name, originFileObj } = e.file;
    if (record.key === 'excelUrl') setExcelFile(originFileObj);
    if (status === 'done') {
			const { data = [] } = response || {}
			const newArr = [...fileData]
			const fileSrc = data && data.length ? data[0]?.filePath : '';
			if (!fileSrc) return;
			newArr.forEach((item: any) => {
				if (item.key === record.key) {
					item.val = fileSrc;
					item.src = fileSrc;
          item.fileName = name;
					form.setFieldsValue({ [item.key]: fileSrc });
				}
			})
			setFileData(newArr);
		}
  }
  
  return (
    <div className={styles.root}>
      <Modal
        title={t("微信模板导入")}
        setIsModalVisible={setIsModalVisible}
        width={670}
      >
        <div className={styles.contentWarp}>
          <div className={styles.selectList}>
            {
              fileData.map((item: any, index: number) => (
                <div
                  className={styles.selectLi}
                  key={`file-${index}`}
                >
                  <UploadComponent
                    onChange={(e) => onFileChange(e, item)}
                    maxCount={1}
                    showUploadList={false}
                    accept={item.accept}
                    fileSize={5}
                    userId={userInfoState?.id || ''}
                  >
                    {
                      item.src ? (
                        <div className={`${styles.pdfWarp} ${styles.picWarp}`}>
                          <div>
                            <img src={item.icon} className={styles.pdfIcon} alt='' />
                            <div className={styles.name}>{item.fileName}</div>
                            <div className={styles.text}>{t("重新上传")}</div>
                          </div>
                        </div>
                      ) : (
                        <div className={styles.uploadWarp}>
                          <div>
                            <img src={item.icon} className={styles.icon} alt='' />
                            <div className={styles.fileName}>{item.name}</div>
                            <div className={styles.subName}>{t(`支持${item.subName}，文件大小必须小于5MB`)}</div>
                          </div>
                        </div>
                      )
                    }
                  </UploadComponent>
                </div>
              ))
            }
          </div>
          <div className={styles.btnWarp}>
            <div className={`${styles.cancelBtn} ${styles.btn}`} onClick={() => setIsModalVisible(false)}>
              {t("取消")}
            </div>
            <div onClick={importWechatOrderFn}  className={`${styles.okBtn} ${styles.btn}`}>{t("确认")}</div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UploadWechatOrder
