import React, {FC, useEffect, useState, useRef} from 'react'
import styles from "./Receipt.module.scss";
import {useTranslation} from "react-i18next";
import logoImg from "@/assets/components/nav-logo.svg";
import type {IState} from "@/redux/interface";
import {useMappedState} from "redux-react-hook";
import {Image} from "react-bootstrap";
import {ops} from '../OrderDetail/OrderDetail'
import Exchange from "@/assets/image/order/exchange.png";
import Thanks from "@/assets/image/order/thanks.png";
import {Modal} from '@/components';
import {html2pdf} from '@/utils/mainUtils'
import {Button, message} from 'antd'

interface IReceiptProps {
  setVisible: (v: boolean) => void;
  data: any;
}

const Receipt: FC<IReceiptProps> = (props) => {
  const {t} = useTranslation();
  const {setVisible, data} = props;
  const elRef = useRef<any>()
  const [loading, setLoading] = useState<boolean>(false);
  const {remitPurpose, sourceOfFund} = useMappedState(
    (state: IState) => state.PublicConfigReducer
  );
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const bankData = useMappedState(
    (state: IState) => state.PublicConfigReducer.bankData
  );
  const receipts = [
    {
      label: t('汇款人'),
      value: `${userInfoState?.firstName} ${userInfoState?.lastName}`
    },
    {
      label: t('收款人 ').trim(),
      value: data?.Recipient ?? ''
    },
    {
      label: t('银行名称 ').trim(),
      getValue: () => {
        const {bankCode} = data?.recipient || {}
        if (!bankCode) {
          return ''
        }
        const bankItem = bankData.find(i => i.bankCode === bankCode)
        if (!bankItem) {
          return ''
        }
        return bankItem.bankName
      },
      hide: data?.deliveryMethod !== 3
    },
    {
      label: t('银行账号 ').trim(),
      value: data?.recipient?.accountNo ? `*${data?.recipient?.accountNo.slice(data?.recipient?.accountNo.length - 4)}` : '',
      hide: data?.deliveryMethod !== 3
    },
    {
      label: t('银行支行'),
      value: data?.recipient.bankBranch ?? '',
      hide: data?.deliveryMethod !== 3
    },
    {
      label: t('订单单号'),
      value: data?.trnNo ?? ''
    },
    // {
    //   label: t('付款原因'),
    //   value: remitPurpose.filter(v => `${v.id}` === data?.remitPurpose)?.[0]?.text
    // },
    // {
    //   label: t('资金来源'),
    //   value: sourceOfFund.filter(v => `${v.id}` === data?.sourceOfFund)?.[0]?.text
    // },
    {
      label: t('订单提交时间'),
      value: data?.createTime ?? '',
    },
    {
      label: t('收款方式 ').trim(),
      value: <>
        <Image src={ops[data.deliveryMethod]?.icon} style={{height: 24}} />
        <span style={{marginLeft: 6}}>{ops[data?.deliveryMethod]?.key ?? ''}</span>
      </>
    },
    {
      label: t('汇款方法 ').trim(),
      value: <>
        <Image src={ops[data.paymentMethod]?.icon} style={{height: 24, transform: 'translateY(-2px)'}} />
        <span style={{marginLeft: 6}}>{ops[data?.paymentMethod]?.key ?? ''}</span>
      </>
    },
    {
      label: t('交付速度'),
      value: data?.paymentMethod === 2 ? t('即时') : t('第二天')
    },
    {
      label: t('汇款金额'),
      value: `${data?.sendingAmount ?? ''} ${data?.sendingCurrency ?? ''}`
    },
    {
      label: t('对方可收到 ').trim(),
      value: `${data?.instAmount ?? ''} ${data?.instCurrency ?? ''}`
    },
    {
      label: t('汇率 ').trim(),
      value: <>
        <span>{`1 ${data?.instCurrency}`}</span>
        <Image style={{margin: '0 6px', height: 14}} src={Exchange} />
        <span>{data?.exchangeRate ?? ''} {data?.sendingCurrency ?? ''}</span>
      </>
    },
    {
      label: t('服务费 ').trim(),
      value: `${data?.commission ?? ''}  ${data?.sendingCurrency ?? ''}`
    },
    {
      label: t('总支付金额'),
      value: `${data?.totalAmount ?? ''}  ${data?.sendingCurrency ?? ''}`
    }
  ]

  return (
    <Modal
      modalClassName={styles.modalWrap}
      title={t('收据回执')}
      setIsModalVisible={() => {
        setVisible(false)
      }}
    >
      <div className={styles.receiptRoot} ref={elRef}>
        <Image src={logoImg} className={styles.logoImg} />
        <div className={styles.description}>{t('感谢您的汇款。Wapi Pay为移动货币平台和银行账户提供最便捷的支付服务。如有问题 :')}</div>
        <div className={styles.link}>{t('访问')} <a href="https://global.wapipay.com/#/home">https://global.wapipay.com/#/home</a></div>
        <ul className={styles.receiptsContainer}>
          {receipts.map((item, index) => !item.hide && <li key={index} className={styles.receiptItem}>
            <div className={styles.receiptLabel}>{item.label}</div>
            <div className={styles.receiptValue}>{item.getValue ? item.getValue() : item.value}</div>
          </li>)}
        </ul>
        <Image src={Thanks} className={styles.thanksImg} />
        <div className={styles.footerTitle}>Wapi Pay</div>
        <div className={styles.footerDes}>
          <span>702, Westside Towers Lower Kabete</span>
          <a style={{marginLeft: 36}}>https://global.wapipay.com/#/order.</a>
        </div>
        <div className={styles.footerDes}>Road Westlands, Nairob</div>
      </div>
      <Button
        className={styles.confirmPrint}
        onClick={() => {
          setLoading(true)
          html2pdf(`Receipt-${data?.trnNo}`, elRef)
            .then(() => {
              message.success(t('下载成功！'))
            })
            .catch(err => {
              message.error(`${t('下载失败！')}${String(err)}`)
            })
            .finally(() => {
              setLoading(false)
              setVisible(false)
            })
        }}
        loading={loading}
      >{t('下载')}</Button>
    </Modal>
  )
}

export default Receipt;