import React, {FC, useState, useEffect} from "react";
import {OverPack} from "rc-scroll-anim";
import QueueAnim from "rc-queue-anim";
import {useMappedState, useDispatch} from "redux-react-hook";
import styles from "./Account.module.scss";
import {SAVE_USER_INFO} from '@/redux/action'
import {isMobile} from "@/utils/utils";
import {useTranslation} from "react-i18next";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Divider,
  Select,
  DatePicker,
  message,
  Spin
} from "antd";
import iconAccount from "@/assets/image/account/iconAccount.png";
import {countryOptions} from "@/utils/defaultData";
import moment from "moment";
import {userApi} from "@/api";
import {IState} from "@/redux/interface";
import {CustType} from '@/api/auth/auth'

interface IAccountProps {}
const {Option} = Select;

const Account: FC<IAccountProps> = (props) => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const [form] = Form.useForm();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [isPhoneFocus, setIsPhoneFocus] = useState<boolean>(false);
  const [phoneVal, setPhoneVal] = useState<string>("");
  const [userInfo, setUserInfo] = useState<any>({});
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );

  useEffect(() => {
    setUserInfo(userInfoState)
    let newInfo = Object.assign({}, userInfoState, {dateOfBirth: userInfoState.dateOfBirth ? moment(userInfoState.dateOfBirth) : null})
    form.setFieldsValue(newInfo);
  }, []);

  const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const {value} = e.target || {};
    fn(value ? true : false);
  };

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag);
  };

  const postUserInfo = async (values: any) => {
    setLoading(true)
    let postData = Object.assign({}, values, {dateOfBirth: values.dateOfBirth && moment(values.dateOfBirth).format('MM/DD/YYYY'), id: userInfo.id})
    userApi.userInfoSave({...postData})
    .then(() => {
      getUserInfo(userInfo.id)
    })
    .catch(() => {
      setLoading(false)
    })
  };

  const getUserInfo = async (id: number) => {
    const res = await userApi.getUserInfo();
    if (!!res.id) {
      dispatch({type: SAVE_USER_INFO, userInfo: res})
      window.localStorage.setItem('userInfo', JSON.stringify(res))
      setUserInfo(res)
      let newInfo = Object.assign({}, res, {dateOfBirth: res.dateOfBirth ? moment(res.dateOfBirth) : null})
      form.setFieldsValue(newInfo);
      message.success(t("账号信息更新成功！"))
    } else {
      message.error(t("获取用户信息失败"));
    }
    setIsUpdate(false)
    setLoading(false)
  };

  const renderCountry = () => {
    const item = countryOptions.find(i => i.codeVal === userInfo.nationality)
    return item ? <div className='flex-center'>
      <img
        src={item.icon}
        style={{width: 24, marginRight: 8}}
        alt=''
      /> {item.label}
    </div> : ''
  }
  return (
    <Spin spinning={loading}>
    <div className={styles.root}>
      <div className={styles.accountBox}>
        <div className={styles.title}>
          <img alt="" src={iconAccount} className={styles.iconAccount} />
          {t("账户")}
        </div>
        {+userInfoState.custType === CustType.Individual ? <>
          <div className={styles.subTitle}>{t('个人信息')}</div>
          <Form form={form} layout="vertical" onFinish={postUserInfo} size="large">
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={12}>
                <Form.Item label={t("名字")} name="firstName">
                  {isUpdate ? <Input /> : userInfo.firstName}
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label={t("姓氏")} name="lastName">
                  {isUpdate ? <Input /> : userInfo.lastName}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("出生日期")} name="dateOfBirth">
                  {isUpdate ? (
                    <DatePicker
                      placeholder="select"
                      inputReadOnly={true}
                      style={{width: "100%"}}
                      showToday={false}
                      format = 'DD-MM-YYYY'
                      disabledDate={(current) => {
                        return current > moment().subtract(1, "d");
                      }}
                    />
                  ) : (
                    userInfo.dateOfBirth
                  )}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("手机号")} name="handPhone">
                  {isUpdate ? (
                    <Input
                      placeholder={t("手机号")}
                      disabled={true}
                      className="phone-input"
                      onChange={(e) => {
                        onInputChange(e, setHavePhone);
                        setPhoneVal(e?.target?.value);
                      }}
                      type="number"
                      onFocus={() => onInputFocus(setIsPhoneFocus, true)}
                      onBlur={() => onInputFocus(setIsPhoneFocus, false)}
                    />
                  ) : (
                    userInfo.handPhone
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </> : <>
          <div className={styles.subTitle}>{t('公司信息')}</div>
          <Form form={form} layout="vertical" onFinish={postUserInfo}>
            <Row gutter={[32, 32]}>
              <Col xs={24} sm={12}>
                <Form.Item label={t("公司名称")} name="companyName">
                  {isUpdate ? <Input /> : userInfo.companyName}
                </Form.Item>
              </Col>

              <Col xs={24} sm={12}>
                <Form.Item label={t("公司注册编号")} name="certOfIncorporationNumber">
                  {isUpdate ? <Input /> : userInfo.certOfIncorporationNumber}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("联系人(业主/店员)")} name="contactPerson">
                  {isUpdate ? <Input /> : userInfo.contactPerson}
                </Form.Item>
              </Col>
              <Col xs={24} sm={12}>
                <Form.Item label={t("联系电话")} name="contactNumber">
                  {isUpdate ? <Input /> : userInfo.contactNumber}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </>}
        <div className={styles.subTitle}>{t("地址")}</div>
        <Form form={form} layout="vertical" onFinish={postUserInfo} size="large">
          <Row gutter={[32, 32]}>
            <Col xs={24} sm={12}>
              <Form.Item label={t("国家")} name="nationality">
                {isUpdate ? (
                  <Select
                    optionLabelProp="label"
                  >
                    {
                      countryOptions.map(item => (
                        <Option
                          value={item.codeVal}
                          key={`${item.codeVal}`}
                          label={
                            <div className='flex-center'>
                              <img
                                src={item.icon}
                                style={{width: 21, marginRight: 5}}
                                alt=''
                              /> <span>{item.label}</span>
                            </div>
                          }
                        >
                          <div className='flex-center'>
                            <img
                              src={item.icon}
                              style={{width: 24, marginRight: 8}}
                              alt=''
                            /> {item.label}
                          </div>
                        </Option>
                      ))
                    }
                  </Select>
                ) : renderCountry()}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t("城市")} name="city">
                {isUpdate ? (
                  <Input />
                ) : (
                  userInfo.city
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t("地址")} name="address1">
                {isUpdate ? (
                  <Input />
                ) : (
                  userInfo.address1
                )}
              </Form.Item>
            </Col>
            <Col xs={24} sm={12}>
              <Form.Item label={t("邮政编码")} name="postalCode">
                {isUpdate ? (
                  <Input />
                ) : (
                  userInfo.postalCode
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Divider className={styles.accountDivider} />
        <Form form={form} layout="vertical" onFinish={postUserInfo}>
          <Row gutter={[32, 32]}>
            <Col
              xs={24}
              sm={24}
              style={{
                display: "flex",
                justifyContent: isMobile() ? "center" : "flex-end",
              }}
            >
              {isUpdate ? (
                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    className={styles.saveBtn}
                    style={{marginRight: "20px"}}
                    htmlType="submit"
                  >
                    {t("保存")}
                  </Button>
                  <Button
                    shape="round"
                    className={styles.cancelBtn}
                    onClick={() => setIsUpdate(false)}
                  >
                    {t("取消")}
                  </Button>
                </Form.Item>
              ) : (
                <Form.Item>
                  <Button
                    shape="round"
                    className={styles.cancelBtn}
                    onClick={(e) => {
                      e.preventDefault()
                      setIsUpdate(true)
                    }}
                  >
                    {t("编辑详情")}
                  </Button>
                </Form.Item>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
    </Spin>
  );
};

export default Account;
