

export const SAVE_USER_INFO = 'SAVE_USER_INFO'

export const DELETE_USER_INFO = 'DELETE_USER_INFO'

// export const SET_LOGIN_VISIBLE = 'SET_LOGIN_VISIBLE'

export const SET_LANG_TYPE = 'SET_LANG_TYPE'

export const SET_ZH_EXPLAIN = 'SET_ZH_EXPLAIN'

export const SET_EN_EXPLAIN = 'SET_EN_EXPLAIN'

export const SET_SOURCE_OF_FUN = 'SET_SOURCEOFFUN'

export const SET_REMIT_PURPOSE = 'SET_REMIT_PURPOSE'

export const SET_REMIT_SYS_PARAMS = 'SET_REMIT_SYS_PARAMS'

export const SET_BANK_DATA = 'SET_BANK_DATA'

export interface IUserInfoActions {
  type: typeof SAVE_USER_INFO & typeof DELETE_USER_INFO;
}
