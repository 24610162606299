import React, { FC, useState, useEffect, useMemo } from 'react'
import { Form, Input, Button, Steps, message } from 'antd'
import AccountCom from './components/AccountCom/AccountCom';
import IdentityCom from './components/IdentityCom/IdentityCom';
import InformationCom from './components/InformationCom/InformationCom';
import DocumentsCom from './components/DocumentsCom/DocumentsCom';
import SelfiePhoto from './components/SelfiePhoto';
import ProofAddress from './components/ProofAddress';
import EnterpriseCom from './components/EnterpriseCom';
import BackIcon from '@/assets/image/login/back-icon.png';
import { isMobile } from '@/utils/utils';
import './RegisterStep.scss'
import { useHistory, useLocation } from 'react-router-dom';
import Card1 from '@/assets/image/login/card1-1.png';
import Card2 from '@/assets/image/login/card1-2.png';
import IncorporationIcon from '@/assets/image/login/incorporation.png';
import BusinessIcon from '@/assets/image/login/business.png';
import KprIcon from '@/assets/image/login/kpr.png';
import _ from 'lodash';
import moment from 'moment';
import { updateUserInfo } from '@/api/register';
import { useDispatch, useMappedState } from 'redux-react-hook';
import { IState } from '@/redux/interface';
import { SAVE_USER_INFO } from '@/redux/action';
import { useTranslation } from 'react-i18next';

const { Step } = Steps;

interface IRegisterStepProps{
  
}

const RegisterStep: FC<IRegisterStepProps> = (props) => {
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [fileStep, setFileStep] = useState(1);
  const [enterpriseStep, seEnterpriseStep] = useState(1);
  const [formData, setFormData] = useState<any>();
  const history = useHistory();
  const [form] = Form.useForm();
	const [isUpdate, setIsUpdate] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const isCompany = formData?.custType === 3;

  // 第三步上传身份证
  // const selectData = [
  //   { name: t("身份证正面"), icon: Card2, key: 'idFront', src: '', val: '' },
  //   { name: t("身份证背面"), icon: Card1, key: 'idBack', src: '', val: '' },
  // ]
	// const passportData = [
  //   { name: t("请上传护照首页"), icon: Card2, key: 'passportImg', src: '', val: '' },
  // ]
	const [fileData, setFileData] = useState<any>([])

  // 第3步上传自拍的
  // const selfiePhotoData = [
  //   { name: t("请在这里上传个人照片"), icon: Card2, key: 'selfie', src: '', val: '', tips: t("请在这里上传个人照片") },
  // ]
	const [selfiePhotoFile, setSelfiePhotoFile] = useState<any>([])

  // 第3步上传银行账单的
  // const proofAddressData = [
  //   { name: t("上传文件应小于20MB"), icon: Card2, key: 'doc1', src: '', val: '', tips: t("请上传文件") },
  // ]
	const [proofAddressFile, setProofAddressFile] = useState<any>()

  // 第4步上传公司文件的
  // const enterpriseData = [
  //   { name: t("公司注册证书"), icon: IncorporationIcon, key: 'certOfIncorporation', src: '', val: '' },
  //   { name: t("KRA税务证明"), icon: KprIcon, key: 'kraPin', src: '', val: '' },
  //   { name: t("营业执照编号"), icon: BusinessIcon, key: 'businessNumber', src: '', val: '' },
  // ]
	const [enterpriseFile, setEnterpriseFile] = useState<any[]>([])

  // 第四步上传公司文件的
  const [fileList, setFileList] = useState<any[]>([]);

  useEffect(() => {
    setTimeout(() => {
      // const newSelectData = _.cloneDeep(selectData);
      const selectData = [
        { name: t("身份证正面"), icon: Card2, key: 'idFront', src: '', val: '' },
        { name: t("身份证背面"), icon: Card1, key: 'idBack', src: '', val: '' },
      ]
      setFileData(selectData)

      const selfiePhotoData = [
        { name: t("请在这里上传个人照片"), icon: Card2, key: 'selfie', src: '', val: '', tips: t("请在这里上传个人照片") },
      ]
      setSelfiePhotoFile(selfiePhotoData);

      const proofAddressData = [
        { name: t("上传文件应小于20MB"), icon: Card2, key: 'doc1', src: '', val: '', tips: t("请上传文件") },
      ]
      setProofAddressFile(proofAddressData);

      const enterpriseData = [
        { name: t("公司注册证书"), icon: IncorporationIcon, key: 'certOfIncorporation', src: '', val: '' },
        { name: `${t("上传")} KRA PIN`, icon: KprIcon, key: 'kraPin', src: '', val: '' },
        { name: t("营业执照编号"), icon: BusinessIcon, key: 'businessNumber', src: '', val: '' },
      ]
      setEnterpriseFile(enterpriseData)
    }, 10)
  }, [])

  const goBack = () => {
    if (currentStep === 0) {
      history.goBack()
    } else {
      // if (currentStep === 2) setFileStep(1);
      if ([2].includes(currentStep)) {
        setFileStep(1);
        seEnterpriseStep(1);
      }
      setCurrentStep(currentStep - 1);
    }
  }

  const uploadTips = (fileArr: any) => {
    const { name, tips } = fileArr.find((item: any) => !item.val) || {};
    if (name) {
      message.warning(tips ? tips : t("请上传") + name);
      return false;
    }
    return true;
  }

  const fileStepChange = (fileArr: any, setStepFn?: (num: number) => void, stepNum?: number) => {
    if(!uploadTips(fileArr)) return false;
    const newFormData = formData ? _.cloneDeep(formData) : {};
    fileArr.forEach((item: any) => {
      newFormData[item.key] = item.val;
    });
    setFormData(newFormData);
    if (stepNum) setStepFn?.(stepNum);
    return true;
  }

  const handleSubmit = async (setLoading?: (flag: boolean) => void, attachmentInformation?: string, isSubmit?: boolean) => {
    const newFormData: any = _.cloneDeep(formData);
    const { birth } = formData || {};
    const { roles, userName } = userInfoState || {};
    if(isSubmit) {
      selfiePhotoFile.forEach((item: any) => {
        newFormData[item.key] = item.val;
      });
    };
    try {
      newFormData.dateOfBirth = birth ? moment(birth).format('MM/DD/yyyy') : '';
      newFormData.title = 'TITLE';
      newFormData.id = userInfoState?.id;
      newFormData.roles = roles;
      newFormData.userName = userName;
      newFormData.handPhone = userName;
      if (attachmentInformation) newFormData.attachmentInformation = attachmentInformation;
      delete newFormData?.birth;
      setLoading?.(true)
      const res = await updateUserInfo(newFormData)
      // setFormData(newFormData);
      // @ts-ignore
      const expires = new Date().toGMTString ? `expires=${moment().add(7, 'd').toDate().toGMTString()}` : ''
      document.cookie = `token=${res.token};path=/;${expires}`
      window.localStorage.setItem('userInfo', JSON.stringify(res.user))
      dispatch({ type: SAVE_USER_INFO, userInfo: res.user })
      setLoading?.(false)
      message.success(t('更新成功'));
      history.push('/registerSuccess')
    } catch (error) {
      setLoading?.(false)
    }
  }

  const onFinish = async (values: any) => {
    if (currentStep === 1) {
      setFormData(formData ? Object.assign(formData, values) : values);
		  setCurrentStep(2);
    } else if (currentStep === 2) {
      if (isCompany) {
        if (enterpriseStep === 1) {
          fileStepChange(enterpriseFile, seEnterpriseStep, 2);
        }
      } else {
        if (fileStep === 1) {
          const { identificationTypes } = values || {};
          const newFormData = formData ? _.cloneDeep(formData) : {};
          if(!uploadTips(fileData)) return;
          fileData.forEach((item: any) => {
            newFormData[item.key] = item.val;
          });
          Object.assign(newFormData, values);
          if (identificationTypes === 1) {
            if (newFormData?.passportImg) delete newFormData?.passportImg;
          } else {
            if (newFormData?.idFront) delete newFormData?.idFront;
            if (newFormData?.idBack) delete newFormData?.idBack;
          }
          setFormData(newFormData);
          setFileStep(2);
        } else if (fileStep === 2) {
          fileStepChange(proofAddressFile, setFileStep, 3);
        } else if (fileStep === 3) {
          if(!fileStepChange(selfiePhotoFile, setFileStep)) return;
          if (formData?.custType === 1) {
            handleSubmit(setSubmitLoading, undefined, true);
          } else {
            setCurrentStep(3)
          }
        }
      }
    }
	}

  const onFileChange = async ( e: any, record: any, fileDataArr: any[], setFileDataArr: (arr: any[]) => void) => {
    const { response, status, originFileObj, type, name } = e.file;
		let url = await new Promise(resolve => {
			const reader = new FileReader();
			reader.readAsDataURL(originFileObj);
			reader.onload = () => resolve(reader.result);
		});
    if (status === 'done') {
			const { data = [] } = response || {}
			const newArr = [...fileDataArr]
			const fileSrc = data && data.length ? data[0]?.filePath : '';
			if (!fileSrc) return;
			newArr.forEach((item: any) => {
				if (item.key === record.key) {
					item.val = fileSrc;
					item.src = url;
          item.fileType = type;
          item.fileName = name;
					form.setFieldsValue({ [item.key]: url });
				}
			})
			setFileDataArr(newArr);
      setIsUpdate(!isUpdate)
		}
  }

  useMemo(() => {
    form.setFieldsValue(formData)
    setTimeout(() => {
      setIsUpdate(!isUpdate)
    }, 500);
  }, [currentStep])

  return (
    <div className='register-step-warp'>
      {
        !isMobile() && (
          <div className='go-back' onClick={goBack}>
            <img src={BackIcon} className='back-icon' alt='' />
            <span>{t("返回")}</span>
          </div>
        )
      }
			<div className='default-step-warp'>
        <Steps
          size="small"
          className='default-step'
          labelPlacement={!isMobile() ? 'horizontal' : 'vertical'}
          current={currentStep}
        >
          <Step title={t("账户")} />
          <Step title={t("信息")} />
          { formData?.custType === 1 && (<Step title={t("身份")} />) }
          { isCompany && (<Step title={t("文件")} />) }
        </Steps>
      </div>
      <Form onValuesChange={() => setIsUpdate(!isUpdate)} initialValues={{ identificationTypes: 1 }} form={form} onFinish={onFinish} className="login-form">
        { currentStep === 0 && <AccountCom setFormData={setFormData} setCurrentStep={setCurrentStep} /> }
        { currentStep === 1 && <InformationCom formData={formData} form={form} /> }
        { 
          currentStep === 2 && (
            <>
              {
                isCompany ? (
                  <>
                    { 
                      enterpriseStep === 1 && (
                        <EnterpriseCom
                          setFileData={setEnterpriseFile}
                          fileData={enterpriseFile}
                          onFileChange={onFileChange}
                        />
                      )
                    }
                    { enterpriseStep === 2 && <DocumentsCom setFileList={setFileList} fileList={fileList} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} /> }
                  </>
                ) : (
                  <>
                    {
                      fileStep === 1 && (
                        <IdentityCom
                          setFileData={setFileData}
                          // passportData={passportData}
                          // selectData={selectData}
                          fileData={fileData}
                          form={form}
                          onFileChange={onFileChange}
                        />
                      )
                    }
                    { 
                      fileStep === 2 && (
                        <ProofAddress
                          setFileData={setProofAddressFile}
                          fileData={proofAddressFile}
                          onFileChange={onFileChange}
                        />
                      )
                    }
                    { 
                      fileStep === 3 && (
                        <SelfiePhoto
                          setFileData={setSelfiePhotoFile}
                          fileData={selfiePhotoFile}
                          submitLoading={submitLoading}
                          onFileChange={onFileChange}
                          formData={formData}
                        />
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
        {/* { 
          currentStep === 3 && (
            <>
              { 
                enterpriseStep === 1 && (
                  <EnterpriseCom
                    setFileData={setEnterpriseFile}
                    fileData={enterpriseFile}
                    onFileChange={onFileChange}
                  />
                )
              }
              { enterpriseStep === 2 && <DocumentsCom setFileList={setFileList} fileList={fileList} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} /> }
            </>
          )
        } */}
      </Form>
      
		</div>
  )
}

export default RegisterStep
