import React, { FC } from 'react'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import NoDataCom from '@/components/NoDataCom';
import InvitationSuccessIcon from "@/assets/image/login/register-success.png";
import styles from './InvitationSuccess.module.scss'
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getParameters } from '@/utils/mainUtils';

interface IInvitationSuccessProps{
}

const InvitationSuccess: FC<IInvitationSuccessProps> = (props) => {
	const history = useHistory();
	const {t, i18n} = useTranslation();
	const { fullName } = getParameters();

  return (
    <div className={styles.root}>
			<HeadComponent isHideHome />
			<div className={styles.rootContent}>
				<NoDataCom
					title={t("提交成功")}
					subtitle={`${t('恭喜您，您的资料已成功提交。请通知')}${fullName ? `${fullName.replace("-"," ")}` : ''}${t("您已完成资料提交。")}`}
					imgSrc={InvitationSuccessIcon}
					// btnTitle={t("完成")}
					// btnClick={() => history.push('/home')}
				/>
			</div>
			<FooterComponent />
    </div>
  )
}

export default InvitationSuccess
