import React, { FC, useState } from 'react'
import { Button, message } from 'antd'
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next';
import pdfIcon from '@/assets/image/login/pdf-icon.png';

interface IEnterpriseComProps{
	setFileData: (arr: any[]) => void;
	fileData: any[];
	onFileChange: (e: any, record: any, fileDataArr: any[], setFileDataArr: (arr: any[]) => void) => void;
}

const EnterpriseCom: FC<IEnterpriseComProps> = (props) => {
	const { setFileData, fileData, onFileChange } = props;
  const {t, i18n} = useTranslation();

  return (
    <div className={styles.root}>
      <div className={styles.registerTitle}>{t("请提供以下文件")}</div>
			<div className={styles.subtitle}>{t("我们需要这些文件来认证您的账户")}</div>
      <div className='login-form-container'>
				<div className={`${styles.selectList}`}>
					{
						fileData.map((item: any, index: number) => (
							<div
								className={styles.selectLi}
								key={`file-${index}`}
							>
								<UploadComponent
									onChange={(e) => onFileChange(e, item, fileData, setFileData)}
									maxCount={1}
									showUploadList={false}
									accept=".jpg,.png,.jpeg,.pdf"
									fileSize={20}
								>
									{
										item.src ? (
											<>
												{
													item.fileType === 'application/pdf' ? (
														<div className={`${styles.pdfWarp} ${styles.picWarp}`}>
															<div>
																<img src={pdfIcon} className={styles.pdfIcon} alt='' />
																<div className={styles.name}>{item.fileName}</div>
																<div className={styles.text}>{t("重新上传")}</div>
															</div>
														</div>
													) : (
														<div className={styles.picWarp}>
															<img src={item.src} className={styles.pic} alt='' />
															<div className={styles.text}>{t("重新上传")}</div>
														</div>
													)
												}
											</>
										) : (
											<div className={styles.uploadWarp}>
												<div>
													<img src={item.icon} className={styles.icon} alt='' />
													<div className={styles.fileName}>{item.name}</div>
													<div className={styles.subName}>{t("文件格式支持JPG、JPEG、PNG、PDF，文件大小必须小于5MB")}</div>
												</div>
											</div>
										)
									}
								</UploadComponent>
							</div>
						))
					}
				</div>
				<Button type="primary" disabled={fileData.filter((item: any) => item.src).length === 3 ? false : true}  htmlType="submit" className='submit-button'>
					{t("下一步")}
				</Button>
      </div>
    </div>
  )
}

export default EnterpriseCom
