import React, {FC, useState} from 'react'
import styles from './index.module.scss'
import OrderDetail from '@/pages/Order/components/OrderDetail/OrderDetail';
import { status as statusArr } from '@/pages/Order/Order';


interface ISubOrderProps {}

const SubOrder: FC<ISubOrderProps> = (props) => {
	const [refreshkey, setRefreshkey] = useState(1)
	const getParams = (u: string) => {
    const params: Record<string, string | undefined> = {}
    const str = u.substring(1)
    str.split('&').forEach(v => {
      const [key, value] = v.split("=")
      params[key] = value
    })
    return params.id
  }
  const returnState = (status: string) => {
    const v = +status ?? 0;
    let s = '';
    if ([0, 1].includes(v)) {
      s = 'Waiting'
    } else if ([2, 3, 7].includes(v)) {
      s = 'Processing'
    } else if ([4, 10, -1].includes(v)) {
      s = 'Failed'
    } else if ([8, 11].includes(v)) {
      s = 'Complete'
    } else if ([9].includes(v)) {
      s = 'Cancelled'
    }
    return s
  }
	return (
		<div className={styles.root}>
			<OrderDetail
        status={statusArr}
        getParamsId={getParams}
        getStatus={returnState}
				isSubOrder
        setTableKey={(key) => setRefreshkey(key)} />
		</div>
	)
}

export default SubOrder
