import React, {FC, useEffect, useState} from 'react'
import {Divider, message, Select} from 'antd';
import {useTranslation} from "react-i18next";
import {useRemittanceContext} from "@/pages/Remittance/RemittanceContext";
import {formatCurrency} from "@/utils/utils";
import CurrencyExchange from "../CurrencyExchange/CurrencyExchange";
import {deliveryOptions} from '@/utils/defaultData';
import _ from 'lodash';
import styles from "./Amount.module.scss";

interface IAmountProps {
  currency: any;
  placement: string;
}

const {Option} = Select;

const Amount: FC<IAmountProps> = ({currency, placement}) => {
  const {t} = useTranslation();
  const {payment, setPayment} = useRemittanceContext();

  const [dOps, setDOps] = useState(deliveryOptions)

  const paymentLabel = {
    service: t("服务费"),
    pay: t("总付款金额"),
    receive: t("对方可收到")
  }
  const returnAmount = (key: string) => {
    let amount = '';
    if (key === 'service') {
      amount = formatCurrency(+payment.commission ?? 0);
    }
    if (key === 'pay') {
      amount = formatCurrency((+payment.sendingAmount ?? 0) + payment.commission);
    }
    if (key === 'receive') {
      amount = formatCurrency(+payment.instAmount ?? 0);
    }
    return amount
  }
  const optionItem = (v: any, sts: React.CSSProperties) =>
    <div className={`flex-center`} style={sts}>
      <div className={styles.opImg}>
        <img src={v.icon} style={{height: 26}} alt="" />
      </div>
      <span>{v.label}</span>
    </div>

  const returnPaymentEl = () => Object.entries(paymentLabel).map(([key, value], index) =>
    <div key={key} className={styles.payment}>
      <span className={styles.paymentKey} style={{fontSize: index === 1 ? 16 : 14}}>{value}</span>
      <span className={styles.paymentValue}>
        <span style={index === 1 ? {fontSize: 22, fontWeight: 600} : {}}>{returnAmount(key)} </span>
        <span>{key === 'receive' ? payment.instCurrency : payment.sendingCurrency}</span>
      </span>
    </div>)

  useEffect(() => {
    if (payment.instCurrency !== 'KES') {
      const ops = dOps.map(v => ({...v, disabled: v.value === 2 ? true : v.disabled}))
      setDOps(ops)
    } else {
      setDOps(_.cloneDeep(deliveryOptions))
    }
  }, [payment.instCurrency])

  return (
    <div className={`${styles.amountRoot} flex-column-center`}>
      {placement === 'vertical' && <Divider />}
      <div className={styles.tips}>{t("请输入汇款金额")}</div>
      <div className={styles.content}>
        <CurrencyExchange defaultValue={currency} onChange={(t) => {}} />
        <div className={styles.method}>
          <span className={styles.label}>{t("收款方式")}</span>
          <Select
            optionLabelProp="label"
            dropdownMatchSelectWidth={false}
            getPopupContainer={(triggerNode: {parentNode: any;}) => triggerNode.parentNode || document.body}
            {...(payment.deliveryMethod ? {value: payment.deliveryMethod} : {})}
            onChange={(e: number) => {
              const current = dOps.filter(v => e === v.value)[0]
              if (current.disabled) {
                message.warning(t(current.value === 2 ? 'M-PESA仅支持肯尼亚国家' : '即将推出...'))
                return
              }
              setPayment({
                ...payment,
                deliveryMethod: e,
                deliveryMethodName: current.label,
                deliveryMethodIcon: current.icon,
                beneficiaryNo: '',
                recipientName: ''
              })
            }}
          >
            {
              dOps.map(v =>
                <Option
                  value={v.value}
                  key={v.value}
                  // disabled={v.disabled}
                  {...({label: optionItem(v, {height: '100%'})})}>
                  {optionItem(v, {color: v.disabled ? '#ccc' : 'inherit'})}
                </Option>)
            }
          </Select>
        </div>
        <Divider />
        {returnPaymentEl()}
      </div>
    </div>
  )
}

export default Amount;