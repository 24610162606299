import React, { FC, useState } from 'react'
import {IState} from '@/redux/interface'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import RegisterStep from './components/RegisterStep/RegisterStep';
import styles from './Register.module.scss'

interface IRegisterProps{
}

const Register: FC<IRegisterProps> = (props) => {

  return (
    <div className={`register-container ${styles.root}`}>
      <HeadComponent />
			<div className='register-wrapper'>
				{/* {
					!showStep ? <CreatAccount setShowStep={setShowStep} /> : <RegisterStep setShowStep={setShowStep} />
				} */}
				<RegisterStep />
			</div>
			<FooterComponent />
    </div>
  )
}

export default Register
