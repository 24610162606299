import React, { FC, useState } from 'react'
import { Form, Input} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './DefaultInput.scss'
import { useTranslation } from 'react-i18next';

interface IDefaultInputProps{
	setPasswordVal?: (str: string) => void;
	formItemName?: string;
	formItemLabel?: string;
	formItemTips?: string;
	placeholder?: string;
	notes?: string;
	tipsIsAbsolute?: boolean;
	inputClassName?: string;
	isPassword?: boolean;
	inputType?: string;
	tipsStyle?: React.CSSProperties;
	regular?: any;
	inputBlurFn?: (str: string) => void;
	disabled?: boolean;
}

const DefaultInput: FC<IDefaultInputProps> = (props) => {
	const { setPasswordVal, formItemName, notes, formItemTips, placeholder, tipsIsAbsolute, inputClassName = '', isPassword, inputType = 'text', tipsStyle, regular, inputBlurFn, disabled, formItemLabel = '' } = props;
  const [haveInput, setHaveInput] = useState<boolean>(true);
  const [iSInputFocus, setIsInputFocus] = useState<boolean>(false);
  const {t, i18n} = useTranslation();
	const [tipsMessage, setTipsMessage] = useState(`${t("请输入")}${placeholder}`)

	const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
		if (regular) {
			fn(regular.test(value) ? true : false)
			setTipsMessage(`${t("请输入正确的")}${placeholder}`)
		} else {
			fn(value ? true : false)
		}
  }

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  return (
    <div className='form-item-warp'>
			<Form.Item
				name={formItemName}
				label={formItemLabel}
				className={`${!haveInput && 'form-item-tips'} ${iSInputFocus && 'form-item-focus'}`}
			>
				{
					isPassword ? (
						<Input.Password
							placeholder={placeholder}
							className={inputClassName}
							disabled={disabled}
							onChange={(e) => {
								onInputChange(e, setHaveInput)
								setPasswordVal?.(e?.target?.value);
							}}
							onFocus={() => onInputFocus(setIsInputFocus, true)}
							onBlur={(e) => {
								onInputFocus(setIsInputFocus, false)
								inputBlurFn?.(e?.target?.value);
							}}
						/>
					) : (
						<Input
							placeholder={placeholder}
							className={inputClassName}
							type={inputType}
							disabled={disabled}
							onChange={(e) => {
								onInputChange(e, setHaveInput)
								setPasswordVal?.(e?.target?.value);
							}}
							onFocus={() => onInputFocus(setIsInputFocus, true)}
							onBlur={(e) => {
								onInputFocus(setIsInputFocus, false)
								inputBlurFn?.(e?.target?.value);
							}}
						/>
					)
				}
			</Form.Item>
			{
				!haveInput && (
					<span style={!tipsIsAbsolute ? tipsStyle : {}} className={!tipsIsAbsolute ? 'tips' : 'tips-relative'}>
						<ExclamationCircleOutlined /> {formItemTips ? formItemTips : tipsMessage}
					</span>
				)
			}
			{
				notes && (
					<div className='notes'>
						{notes}
					</div>
				)
			}
		</div>
  )
}

export default DefaultInput
