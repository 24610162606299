import $request from '@/request';

export interface IRecipientParams {
  Status: number;
  receiveType?: number;
  nationality?: string;
  pageSize?: number;
  pageNum: number;
}

export interface IRecipientRow {
  accountCurrency: null | string;
  accountName: null | string;
  accountNo: string;
  accountType: number;
  address1: null | string;
  bankBranch: string;
  bankBranchCode: null | number
  bankCode: string;
  bankName: string;
  bankReference: null | string;
  beneficiaryId: null | number;
  beneficiaryNo: string;
  birthDay: null | string;
  chineseName: null | string;
  city: null | string;
  cityId: null | number;
  comments: string;
  country: string;
  createBy: string;
  createTime: string;
  dateOfBirth: null | string;
  district: null | string;
  email: string;
  firstName: string;
  fomoBeneRefNo: null | number;
  fomoCustRefNo: null | number;
  gender: string;
  handPhone: string;
  id: number;
  idPhoto: null | string;
  idProof: string;
  identificationExpiryDate: null | string;
  identificationNumber: null | string;
  identificationType: string;
  lastName: string;
  middleName: null | string;
  nationality: string;
  occupation: null | string;
  params: any;
  passNo: null | number;
  pd: null | string;
  postalCode: null | number;
  processDateTime: string;
  province: null | string;
  receiveType: number;
  relationshipToCustomer: string;
  remark: null | string;
  remarkName: null | string;
  resultCode: number;
  searchValue: null | string;
  snippet: null | string;
  status: number;
  swiftCode: null | number;
  thirdPartId: null | number;
  typeOfPersonal: number;
  updateBy: string;
  updateTime: null | string;
  userId: number;
}

export interface IBatchOrder {
  total: number;
  code: number;
  rows: any[];
  msg?: string;
}

export interface ISubOrder {
  total: number;
  code: number;
  list: any[];
  msg?: string;
}

export const queryBatchOrder = (d: any): Promise<IBatchOrder> => {
  return $request.post('/remit-service/remit/order/list', {
    status: d.Status,
    pd: {
      pageSize: d.pageSize ?? 10,
      pageNum: d.pageNum,
      orderByColumn: 'create_time',
      isAsc: 'desc'
    }
  })
}

export const querySubOrder = (d: any): Promise<ISubOrder> => {
  return $request.post('/remit-service/remit/transaction/list/page', {
    orderId: d.orderId,
    pd: {
      pageSize: d.pageSize ?? 10,
      pageNum: d.pageNum,
      orderByColumn: 'createTime',
      isAsc: 'desc'
    }
  })
}

export const submitRecipient = (data: any): Promise<any> => {
  return $request.post('/remit-service/remit/beneficiary/add', data)
}

export const importWechatOrder = (data: any): Promise<any> => {
  return $request.post('/remit-service/remit/order/importWechatOrder', data, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
