import React from 'react'
import {message} from 'antd';
import {Modal} from '@/components';
import {useTranslation} from "react-i18next";
import copyCom from "copy-to-clipboard";
import styles from './index.module.scss'

interface IInviteLinkProps {
  slugLink: string;
  setVisible: (b: boolean) => void;
}

const InviteLink = (props: IInviteLinkProps) => {
  const {slugLink, setVisible} = props
  const {t} = useTranslation();
  const handleCopy = (value: string) => {
    copyCom(value);
    message.success(t("链接已复制！"))
  }
  return (
    <div className={styles.root}>
      <Modal
        title={t("通过链接邀请")}
        setIsModalVisible={setVisible}
        maskClosable={true}
        modalClassName={styles.linkModal}
      >
        <div className={styles.linkWarp}>
          <span className={styles.linkTitle}>{t("请将下面的链接分享给收款人（点击自动复制）")}</span>
          <span className={styles.linkText} onClick={() => handleCopy(slugLink)}>
            {slugLink}
          </span>
        </div>
      </Modal>
    </div>
  )
}

export default InviteLink;
