import React, { FC, useState } from 'react'
import Individual from '@/assets/image/login/Individual-icon.png';
import Business from '@/assets/image/login/Business-icon.png';
import styles from './AccountCom.module.scss'
import { isMobile } from '@/utils/utils';
import { useTranslation } from 'react-i18next';

interface IAccountComProps{
  setCurrentStep: (num: number) => void;
  setFormData: (data: any) => void;
}

const AccountCom: FC<IAccountComProps> = (props) => {
  const { setCurrentStep, setFormData } = props;
  const {t, i18n} = useTranslation();
  const selectData = [
    { name: t("个人账户"), icon: Individual, introduce: t("如果您正在转账自己的汇款，请选择注册为个人账户"), key: 1 },
    { name: t("公司账户"), icon: Business, introduce: t("如果您正在进行生意转账，您可以选择此选项"), key: 3 },
  ]

  const onClickSel = (item: any) => {
    setFormData({custType: item.key});
    setCurrentStep(1);
  }

  return (
    <div className={styles.root}>
			<div className={styles.registerTitle}>{t("请问您想要开什么类型的账户？")}</div>
			<div className={styles.registerSubtitle}>{t("请选择您的账户类型")}</div>
      <div className={ `${styles.selectList} ${isMobile() && styles.selectListMobile}`}>
        {
          selectData.map((item) => (
            <div
              className={styles.selectLi}
              key={item.name}
              onClick={() => onClickSel(item)}
            >
              <img src={item.icon} alt='' className={styles.icon} />
              <div>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.introduce}>{item.introduce}</div>
              </div>
            </div>
          ))
        }
      </div>
		</div>
  )
}

export default AccountCom
