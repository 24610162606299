import React, { FC, ReactElement, useState } from 'react'
import { Form, Button, message, Upload, Progress  } from 'antd'
import DocumentsIcon1 from '@/assets/image/login/documents-icon1.png';
import DocumentsIcon2 from '@/assets/image/login/documents-icon2.png';
import DeleteIcon from '@/assets/image/login/delete-icon.png';
import styles from './DocumentsCom.module.scss'
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const { Dragger } = Upload;

interface IDocumentsComProps{
  setFormData: (data: any) => void;
  formData: any;
  fileList: any[];
  setFileList: (data: any[]) => void;
  handleSubmit: (setLoading?: (flag: boolean) => void, attachmentInformation?: string) => void;
}

const DocumentsCom: FC<IDocumentsComProps> = (props) => {
  const { setFormData, formData, fileList, setFileList, handleSubmit } = props;
  const [form] = Form.useForm();
  // const [fileList, setFileList] = useState<any[]>();
	const [submitLoading, setSubmitLoading] = useState(false);
  const {t} = useTranslation();
  const fileTitleList = [
    { label: t("一份公司注册证书复印件") },
    { label: t("一份公司KRA税务证明复印件") },
    { label: t("一份公司合规税务证明复印件") },
    { label: t("一份公司组织大纲和章程复印件") },
    { label: t("一份不超过3个月的CR12认真复印件"), subLabel: `(${t("文件需显示董事和股东")})` },
    { label: t("董事和股东的纳税证明") },
    { label: t("受益所有人名单及其身份证明文件"), subLabel: `(${t("直接和/或间接拥有10%及以上的股东")})` },
    { label: t("公司董事会关于授权与Wapi Pay合作的决议") },
  ]

	const onFinish = async () => {
    // setFormData(formData ? Object.assign(formData, values) : values);
    const attachmentInformation: any[] = [];
    if (fileList && fileList.length) {
      fileList.forEach((item: any) => {
        const { response } = item || {};
        const { data = [] } = response || {}
        if (data && data.length) {
          attachmentInformation.push(data[0]?.filePath);
        }
      })
    } else {
      message.warning(t("请至少上传一个文件。"));
      return;
    }
    handleSubmit(setSubmitLoading, attachmentInformation.length ? attachmentInformation.join(',') : '');
	}
  
  const onFileChange = ( e: any ) => {
    const { status } = e.file;
    setFileList(e.fileList);
    if (status === 'done') {
			
    }
  }

  const deleteFile = (i: number) => {
    const newList = _.cloneDeep(fileList);
    newList?.splice(i, 1);
    setFileList(newList);
  }

  const onRemove = (file:any, fileList: any) => {
    const i = fileList.findIndex((item: any) => item.uid === file.uid);
    const newList = _.cloneDeep(fileList);
    newList?.splice(i, 1);
    setFileList(newList);
  }

  return (
    <div className={styles.root}>
      <div className={styles.registerTitle}>{t("请提供额外的文件")}</div>
			<div className={styles.subtitle}>{t("您提供的文件越详尽，我们审核您账户的速度会越快")}</div>
      <div className={styles.uploadWarp}>
        <div className={styles.uploadWarpL}>
          <div className={styles.WarpLeftTitle}>
            <img alt='' src={DocumentsIcon1} className={styles.icon} />
            {t("需要的文件")}
          </div>
          <div className={styles.fileTitleList}>
            {
              fileTitleList.map((item: any, index: number) => (
                <div className={styles.fileTitleLi} key={`file-title-${index}`}>
                  <div className={styles.circular} />
                  <div className={styles.labelWarp}>
                    <div className={styles.label}>{item.label}</div>
                    { item.subLabel && (<div className={styles.subLabel}>{item.subLabel}</div>) }
                  </div>
                </div>
              ))
            }
          </div>
        </div>
        <div className={styles.uploadWarpR}>
          <UploadComponent
            onChange={(e) => onFileChange(e)}
            multiple={true}
            maxCount={10}
            setFileList={setFileList}
            itemRender={(originNode: ReactElement, file: any, fileList: any) => (
              <div key={`file-li-${file.uid}`} className={`${styles.fileLi} ${file.status === 'error' && styles.errorFile}`}>
                <div className={styles.fileLiL}>
                  <div className={styles.fileName}>{file?.name}</div>
                  {/* { item.percent < 100 && (<Progress className={styles.progress} showInfo={false} percent={item.percent} />) } */}
                </div>
                <img alt='' src={DeleteIcon} className={styles.deleteIcon} onClick={() => onRemove(file, fileList)} />
              </div>
            )}
            fileList={fileList}
            disabled={fileList && fileList.length >= 10}
          >
            <div className={styles.uploadBox}>
              <img alt='' src={DocumentsIcon2} className={styles.uploadIcon} />
              <p className="ant-upload-text">{t("请点击或者拖拽文件到此处以上传")}</p>
              <p className="ant-upload-hint">{t("支持JPG、JPGE、PNG")} </p>
            </div>
          </UploadComponent>
          {/* <div className={styles.fileList}>
            {
              fileList && fileList.map((item: any, index: number) => (
                <div key={`file-li-${index}`} className={`${styles.fileLi} ${item.status === 'error' && styles.errorFile}`}>
                  <div className={styles.fileLiL}>
                    <div className={styles.fileName}>{item?.name}</div>
                  </div>
                  <img alt='' src={DeleteIcon} className={styles.deleteIcon} onClick={() => deleteFile(index)} />
                </div>
              ))
            }
          </div> */}
        </div>
      </div>
      <Button className='submit-button' loading={submitLoading} onClick={onFinish}>{t("提交")}</Button>
    </div>
  )
}

export default DocumentsCom
