import React, {FC} from 'react'
import { Select } from 'antd';
import styles from './index.module.scss'
import { DefaultSelect } from '@/components';
import { IDefaultSelectProps } from '../DefaultSelect/DefaultSelect'

const { Option } = Select;

interface ISelectPictureProps {
	optionData: any;
	valueKey?: string;
	disabled?: boolean;
	onChange?: (val: any) => void;
}

const SelectPicture: FC<ISelectPictureProps & IDefaultSelectProps> = (props) => {
	const { optionData = [], valueKey = 'value', disabled, onChange } = props;

  return (
    <DefaultSelect onChange={onChange} disabled={disabled} {...props}>
			{ 
				optionData.map((item: any) => (
					<Option
						value={item[valueKey]}
						key={`${item.value}`}
						disabled={item.disabled}
						label={
							<div className='flex-center'>
								<img
									src={item.icon}
									className="country-logo"
									alt=''
									style={{ width: 24, marginRight: 5 }}
								/> <span>{item.label}</span>
							</div>
						}
					>
						<div className='flex-center'>
							<img
								src={item.icon}
								className="country-logo"
								alt=''
								style={{ width: 28, marginRight: 8 }}
							/> {item.label}
						</div>
					</Option>
				))
			}
		</DefaultSelect>
  )
}

export default SelectPicture
