import React, {FC} from 'react'
import styles from './index.module.scss'

interface IFooterComponentProps {
	className?: string;
}

const FooterComponent: FC<IFooterComponentProps> = (props) => {
	const { className } = props;

  return (
    <div className={`${styles.footerCom} ${className}`}>
			2019-2021 © Wapi Pay All rights reserved
		</div>
  )
}

export default FooterComponent
