import React, {FC, useEffect, useState} from 'react'
import {Modal, Descriptions, Button, Input} from 'antd';
import {history} from "@/route";
import {isMobile} from '@/utils/utils';
import Kenya from "@/assets/image/login/Kenya.png";
import M1 from "@/assets/image/remittance/m-1.png";
import M2 from "@/assets/image/remittance/m-2.png";
import M3 from "@/assets/image/remittance/m-3.png";
import {remittanceApi} from '@/api'
import {useTranslation} from "react-i18next";
import {formatCurrency} from "@/utils/utils";
import styles from "./ConfirmModal.module.scss";

interface IConfirmModalProps {
  visible: boolean;
  setVisible: (finish: boolean) => void;
  title?: string;
  type: number | string;
  dataSource: any;
  isPayment?: boolean;
}

const submit = {
  trnNo: '交易号',
  recipientName: '收款人 ',
  totalAmount: '金额',
}

const bank = {
  BankName: {
    key: '银行名称',
    value: 'Ecobank Kenya',
  },
  AccountName: {
    key: '账户名称',
    value: 'Wapi Capital Limited',
  },
  AccountNoKes: {
    key: '账号(KES)',
    value: '6682002172',
  },
  AccountNoUsd: {
    key: '账号(USD)',
    value: '6682002173',
  },
}

const ConfirmModal: FC<IConfirmModalProps> = (props) => {
  const {t} = useTranslation();
  const {visible = false, setVisible, type = 2, dataSource = {}, isPayment = false} = props
  const [loading, setLoading] = useState(false);
  const [referenceNo, setReferenceNo] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  const MPESAToPay = async () => {
    try {
      const data = await remittanceApi.transactionPayMPESA({trnNo: dataSource.trnNo, phone: `254${phoneNum}`})
      if (data) setVisible(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const submitPayment = async () => {
    try {
      const data = await remittanceApi.updateBankRefNO({
        trnNo: dataSource.trnNo,
        bankRefNo: referenceNo
      })
      if (data) setVisible(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (visible) {
      setLoading(false)
      setReferenceNo('')
      setPhoneNum('')
    }
  }, [visible])

  const mPesaContent = () =>
    <div className={styles.container}>
      <div className={`${styles.item} flex-between`}>
        <div className='flex-center'>
          <img src={Kenya} alt="" className={styles.country} />
          <span>+254</span>
        </div>
        <Input
          placeholder={t('手机号码')}
          value={phoneNum}
          onChange={(e) => setPhoneNum(e.target.value)}
          onBlur={() => {
            if (phoneNum && phoneNum.charAt(0) === '0') {
              setPhoneNum(phoneNum.slice(1))
            }
          }}
        />
      </div>
      <div className={styles.b1}>
        <Button
          type="primary"
          shape="round"
          className={`${styles.subBtn} ${styles.sb1} ${phoneNum ? styles.sb2 : ''}`}
          disabled={phoneNum === ''}
          loading={loading}
          onClick={() => {
            setLoading(true);
            MPESAToPay()
          }}>
          {t('提交支付请求')}
        </Button>
        <Button
          type="primary"
          shape="round"
          className={`${styles.subBtn} ${styles.sb2}`}
          onClick={() => {
            history.push('/order')
          }}>
          {t('稍后付款')}
        </Button>
      </div>
    </div>

  const bankContent = () =>
    <div className={styles.container}>
      <div className={styles.tips}>{t('银行转账订单提交成功')} <br /> {t('付款至以下银行信息即可完成转账')}</div>
      <div className='flex-center' style={{paddingLeft: 6}}>
        <img src={M1} alt="" />
        <span className={styles.t2}>{t('需要银行转账')}</span>
      </div>
      <div className={`${styles.t3} flex-center`}>
        <img src={M2} alt="" />
        <span>1.{t('请通过您选用的网上支付平台完成支付')} <br />2.{t('请从支付确认凭据中找到参考号并在“输入支付参考”处输入该号码')}</span>
      </div>
      <Descriptions column={1}>
        {
          Object.entries(bank).map(([key, value], index) =>
            <Descriptions.Item key={index} label={t(value.key)}>
              <span>{value.value}</span>
            </Descriptions.Item>)
        }
      </Descriptions>
      <div className={`${styles.t4} flex-center`}>
        <img src={M3} alt="" />
        <span className={styles.t2}>{t('输入付款编号')}</span>
      </div>
      <Input placeholder={t('输入付款编号 ')} value={referenceNo} onChange={(e) => {
        setReferenceNo(e.target.value)
      }} />
    </div>

  return (
    <Modal
      wrapClassName={styles.submitRoot}
      footer={null}
      width={430}
      title={t(type === 2 ? 'M-PESA转账' : '银行转账')}
      visible={visible}
      onCancel={() => {
        setVisible(false)
        if (isPayment) {
          history.push(`/order?id=${dataSource.id}`)
        }
      }}
      maskClosable={false}
      centered={true}
    >
      <div className={`${styles.modalBox} ${type === 2 ? styles.modalBox1 : ''}`}>
        <Descriptions column={1}>
          {
            Object.entries(submit).map(([key, value], index) =>
              <Descriptions.Item key={index} label={t(value)}>
                <span>{key === 'totalAmount' ? formatCurrency(dataSource[key]) : dataSource[key]}</span>
                {key === 'totalAmount' && <span> &nbsp;{dataSource.sendingCurrency}</span>}
              </Descriptions.Item>)
          }
        </Descriptions>
        {
          type === 2 ? mPesaContent() : bankContent()
        }
      </div>
      <div className={`${styles.bd} ${type === 2 ? styles.bd1 : styles.bd2}`} />
      {type !== 2 &&
        <div className={styles.b2}>
          <Button
            type="primary"
            shape="round"
            className={`${styles.subBtn} ${referenceNo ? styles.conBtn : ''} ellipsis`}
            loading={loading}
            onClick={() => {
              setLoading(true);
              submitPayment()
            }}>
            {t('提交付款')}
          </Button>
        </div>}
    </Modal>
  )
}

export default ConfirmModal;