import React, { FC } from 'react'
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import styles from './index.module.scss'

interface ITermsProps{
	
}

const Terms: FC<ITermsProps> = (props) => {
 
  return (
    <div className={styles.root}>
      <HeadComponent />
      <div className={styles.contentWarp}>
        {/* <p><br /></p> */}
        <p className={styles.bigTitle}><span>Terms &ndash; Wapi Payments</span></p>
        {/* <p><br /></p> */}
        <div className={styles.headWarp}>
          <p>Please read carefully before using this service.</p>
          <p>Jambo, welcome to Wapi!</p>
          {/* <p><br /></p> */}
          <p className='line-height24'>The Wapi Pay service, website and/or software application (collectively, the &ldquo;Service&rdquo;) enables you to send, receive or transfer money between your supported bank account, mobile money account or Wechat account as well as pay for products and services offered by third parties (&ldquo;Products&rdquo;). These Products may include pre-paid electricity or water provided by local utilities, pre-paid airtime offered by mobile telecommunications companies, or other selected digital products and services offered by merchants. You may also use the Service to facilitate the transfer of credit from your mobile money account to the account of another user, or receive credit from other users. The Service is operated by Wapi Pay Pte. Limited and its affiliates (&ldquo;Wapi&rdquo;), subject to these Terms of Service (&ldquo;Terms&rdquo;).</p>
          {/* <p><br /></p> */}
          <p className='line-height24'>TERMS OF SERVICE</p>
        </div>
        <p>(Last Updated: January 2, 2020)</p>
        {/* <p><br /></p> */}
        <p className={styles.firstSubTitle}><span className={styles.subtitle}>1. This is a contract.</span></p>
        <p>These Terms constitute a contract between you and Wapi. You may not use the Service if you do not accept these Terms. By using any part of the Service you accept these Terms. If you are under sixteen (16) years of age, or at least sixteen (16) years of age but a minor where you live, you may not use the Service.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>2. You must register an account with valid information.</span></p>
        <p>To use the Service you must: (a) provide a valid mobile phone number; (b) agree to the creation of an account associated with such number; (c) accept the present Terms; and (d) submit such other and additional information as Wapi may request. You agree to provide true, accurate and complete information about yourself as prompted by Wapi during the account registration process. You will be given the option of receiving a unique PIN for purposes of reusing your account. You are responsible for keeping your PIN secure.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>3. Third party merchants offer the goods &amp; services.</span></p>
        <p>The Service provides you with a method of discovering offers from independent third party merchants (&ldquo;Merchants&rdquo;). These Merchants sell their respective Products subject to their own terms and conditions. If you decide to purchase Products from Merchants, you do so at your own risk and subject to the relevant Merchant&rsquo;s terms and conditions. The Products are not investigated, monitored, or checked for accuracy, reliability, appropriateness or completeness by Wapi. By permitting you to discover Products via the Service, Wapi does not imply approval or endorsement of such Products. You agree that Wapi is not responsible or liable for the Products you purchase from Merchants.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>4. Payments are processed by third parties.</span></p>
        <p>In order to facilitate your purchases from Merchants, Wapi has integrated its Service with the billing solutions of certain mobile money providers and credit card issuing banks (&ldquo;Payment Processors&rdquo;). If you purchase a Product from a Merchant using your supported mobile money account or credit card, payments will be processed by such Payment Processors. Payment Processors have their own terms and conditions governing your use of their payment services. You understand and agree that Wapi does not process payments and is not responsible or liable for any transactions in which you engage. By engaging in a purchase transaction using your mobile money account or credit card, you authorize the Merchant (or Wapi on its behalf) to bill your account or card for the purchase amount.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>5. Your privacy is important to us.</span></p>
        <p>Wapi takes the matters of protection and security of its users&rsquo; information very seriously. Wapi privacy policy governing the Service is attached to these Terms (&ldquo;Privacy Policy&rdquo;). The Privacy Policy is incorporated into these Terms by this reference. By using the Service, you agree to the use of your data in accordance with Wapi&rsquo;s Privacy Policy. The Privacy Policy addresses only the information collected by Wapi in providing the Service to you. Merchants and Payment Processors have their own information gathering practices that will apply when you choose to purchase Products.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>6. Product inquiries should be submitted to the relevant Merchant.</span></p>
        <p>If you have any questions, concerns or requests related to a Product you have purchased from a Merchant, you should contact the Merchant directly. Each Merchant maintains its own customer contact desk for handling such requests. If you wish to submit a complaint about the practices of a Merchant offering its Products via the Service, you may contact us by email at <a href="mailto:jambo@wapipay.com">jambo@wapipay.com</a></p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>7. You must not use the Service to violate any laws.</span></p>
        <p>You must not use the Service to violate or infringe the rights of any other person, including the rights of other users, Wapi&rsquo;s rights in the Service or Merchants&rsquo; rights in their Products. You must not breach any laws or regulations when using the Service or attempt to disrupt or interfere with the security or functionality of the Service.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>8. Wapi may contact you regarding your account or the Service.</span></p>
        <p>You expressly agree that, as part of the Service, you may, from time to time, receive communications from Wapi via email, instant message, telephone, text message (SMS) or other means. You may stop receiving promotional messages by emailing your request to opt-out, along with your cell phone number, to <a href="mailto:jambo@wapipay.com">jambo@wapipay.com</a>, or following the opt-out instructions in the message. Even if you choose to opt out of receiving promotional messages, you may not opt out of receiving service-related messages.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>9. Wapi may discontinue the Service.</span></p>
        <p>Wapi may in its sole discretion and at any time terminate your access to the Service or discontinue providing the Service or any part of the Service, with or without notice. You agree that Wapi will not be responsible or liable to you or any third party for modifying or discontinuing the Service, or for terminating or suspending your access to the Service.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>10. The Service is provided without any warranties or guarantees.</span></p>
        <p>THE SERVICE IS PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OF ANY KIND. WAPI AND ITS SUPPLIERS AND AFFILIATES DISCLAIM ALL WARRANTIES WITH REGARD TO THE SERVICE, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE, OR WITH ANY OF THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SERVICE.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>11. Wapi is not liable for any damages you may incur as a result of using the Services.</span></p>
        <p>IN NO EVENT SHALL WAPI OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SERVICE, WITH THE DELAY OR INABILITY TO USE THE SERVICE, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THE SERVICE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHERWISE, EVEN IF WAPI OR ANY OF ITS SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>12. This contract is based on Kenyan law.</span></p>
        <p>These Terms are subject to, and shall be governed by, and construed in accordance with the laws of the Republic of Kenya, without reference to the principles of conflict of laws thereof. Any matters arising concerning the interpretation, validity or implementation of these Terms shall be submitted to arbitration in the English language before a sole arbitrator to take place in Nairobi, Kenya as the seat of the arbitration. The arbitration shall be conducted pursuant to the Rules of Arbitration of the Chartered Institute of Arbitration (Kenya Chapter). The arbitrator shall be appointed by agreement between the parties or in default of such agreement within sixty (60) days of the notification of a dispute, by the Chairman of the aforementioned arbitral body. To the extent permitted by applicable law, the arbitral decision shall be final and binding on the parties</p>
        {/* <p><br /></p> */}
        <p><span className={styles.subtitle}>13. Wapi may modify these Terms.</span></p>
        <p>These Terms and related policies (including but not limited to the Privacy Policy) may be modified by Wapi without notice at any time in the future. Changes will be posted. By using the Service, you agree to be bound by the latest version of these Terms. It is your responsibility to remain informed of any changes.</p>
      </div>
      <FooterComponent />
    </div>
  )
}

export default Terms
