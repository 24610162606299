import React, {FC, useState, useMemo, useEffect} from 'react'
import { Pagination, Modal, message, Spin, Image } from 'antd';
import styles from './index.module.scss'
import {NoDataCom, Table} from '@/components';
import {PlusOutlined} from '@ant-design/icons';
import UploadWechatOrder from './components/UploadWechatOrder'
import {isMobile} from '@/utils/utils';
import {useTranslation} from "react-i18next";
import {queryBatchOrder} from '@/api/batchOrder';
import {useMappedState} from "redux-react-hook";
import {IState} from "@/redux/interface";
import {useHistory} from 'react-router-dom';
import {RegistrationStatus} from '@/api/auth/auth'
import UploadComponent from '@/components/UploadComponent/UploadComponent';
import { localFormat } from '@/utils/moment';

const { confirm } = Modal;

interface IBatchOrderProps {}

const BatchOrder: FC<IBatchOrderProps> = (props) => {
	const userInfoState = useMappedState(
		(state: IState) => state.UserInfoReducer.userInfo
	);
	const {registrationStatus} = userInfoState || {};
	const {t, i18n} = useTranslation();
	const [isUpdate, setIsUpdate] = useState(false);
	const history = useHistory();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [recipientData, setBatchOrderData] = useState<any>([]);
	const [loading, setLoading] = useState(false);
	const totalTips = (total: any) => `Total ${total} batch order`;
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 8,
		total: 0,
		showTotal: (total: any) => totalTips(total),
	});

	const getList = async (current: number = 1, pageSize: number = 8) => {
		try {
			setLoading(true);
			const {total, rows = []} = await queryBatchOrder({
				Status: 0,
				pageSize,
				pageNum: current
			})
			setBatchOrderData(rows ?? [])
			setPagination({...pagination, current, pageSize, total})
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}
	}

	useEffect(() => {
		if (registrationStatus === RegistrationStatus.Finished) getList(1, 8)
	}, [])

	const onShowSizeChange = (current?: number, pageSize?: number) => {
		if (current) {
			getList(current, pageSize);
		}
	};

	const gotoSubOrder = (item?: any) => {
		if (item.successNum === 0) return;
		history.push(`/batchOrders/subOrder?orderId=${item?.id}&fileName=${item.fileName ?? ''}`)
	}

	const columns = [
		{
			title: t("文件名"),
			dataIndex: 'fileName',
			render: (text: any, records: any) => <span>{text ?? '--'}</span>,
		},
		{
			title: t("成功数"),
			dataIndex: 'successNum',
		},
		{
			title: t("失败数"),
			dataIndex: 'failureNum',
		},
		{
			title: t("创建时间"),
			dataIndex: 'createTimeStr',
      render: (text: any, records: any) => text ? localFormat(text, true) : '',
		},
		{
			title: t("操作"),
			key: 'action',
			fixed: 'right',
			width: 130,
			render: (text: any, record: any) => (
				<span className={`${styles.subOrderText} ${record?.successNum === 0 && styles.subOrderBtn}`} onClick={() => gotoSubOrder(record)}>{t("子订单")}</span>
			)
		},
	];

	useMemo(() => {
		document.body.style.overflow = isModalVisible ? 'hidden' : 'auto'
	}, [isModalVisible])

	const onFileChange = async ( e: any) => {
    const { response, status } = e.file;
    if (status === 'done') {
			const { data = [], code, msg } = response || {}
			if (code === 200) {
				message.info(msg)
				getList(1, 8)
			} else {
				message.error(msg);
				getList(1, 8)
			}
		}
  }



	return (
		<div className={`${styles.root} ${recipientData.length ? '' : styles.NoData}`}>
			<Spin spinning={loading} tip="Loading">
				{
					recipientData.length > 0 ? (
						<>
							{
								isMobile() ? (
									<div className={styles.recipientWarp}>
										<div className={styles.title}>{t("批量订单")}</div>
										<div className={styles.recipientList}>
											{
												recipientData.map((item: any, index: number) => (
													<div className={styles.recipientLi} key={`${index}`}>
														<div className={`${styles.fileName} ellipsis`}>{item.fileName ?? '--'}</div>
														<div className={styles.contentWarp}>
															<div className={styles.liLeft}>
																{item.createTime ? localFormat(item.createTime, true) : ''}
															</div>
															<div className={styles.liRight}>
																<span className={`${styles.subOrderText} ${item?.successNum === 0 && styles.subOrderBtn}`} onClick={() => gotoSubOrder(item)}>{t("子订单")}</span>
															</div>
														</div>
													</div>
												))
											}
										</div>
									</div>
								) : (
									<Table
										columns={columns}
										// getListFun={queryBatchOrders}
										dataSource={recipientData}
										isUpdate={isUpdate}
										isShowAddBtn
										setIsUpdate={setIsUpdate}
										pageTitle={t("批量订单")}
										totalTips="batch order"
										showPagination={false}
										pageHeadBtn={
											<div className={styles.btnWarp}>
												<UploadComponent
                          // accept={each.accept ?? accept}
                          action="remit-service/remit/order/importOrder"
                          onChange={(e) => onFileChange(e)}
                          maxCount={1}
                          showUploadList={false}
													userId={userInfoState?.id || ''}
													accept=".xls,.xlsx"
													isNoMessage={true}
                        >
                          <div className={styles.addBtnWarp}>
														<PlusOutlined className={styles.addIconClass} /><span>{t("添加银行订单")} </span>
													</div>
                        </UploadComponent>
												<div className={`${styles.addBtnWarp} ${styles.wechatBtn}`} onClick={() => {
													setIsModalVisible(true)
												}}>
													<PlusOutlined className={styles.addIconClass} /><span>{t("添加微信订单")} </span>
												</div>
											</div>
										}
									/>
								)
							}
							{
								recipientData.length > 0 && (
									<div className={styles.pageSizeBox}>
										<Pagination
											onChange={onShowSizeChange}
											// size="small"
											{...pagination}
										/>
									</div>
								)
							}
							{
								isMobile() && (
									<div className={`${styles.btnWarp} ${styles.btnWarpMobile}`}>
										<UploadComponent
											// accept={each.accept ?? accept}
											action="remit-service/remit/order/importOrder"
											onChange={(e) => onFileChange(e)}
											maxCount={1}
											showUploadList={false}
											userId={userInfoState?.id || ''}
											accept=".xls,.xlsx"
											isNoMessage={true}
										>
											<div className={styles.addBtnWarp}>
												<PlusOutlined className={styles.addIconClass} /><span>{t("添加银行订单")} </span>
											</div>
										</UploadComponent>
										<div className={`${styles.addBtnWarp} ${styles.wechatBtn}`} onClick={() => {
											setIsModalVisible(true)
										}}>
											<PlusOutlined className={styles.addIconClass} /><span>{t("添加微信订单")} </span>
										</div>
									</div>
								)
							}
						</>
					) : (
						<>
							{
								loading ? (
									<div style={{ minHeight: 500 }} />
								) : (
									<NoDataCom
										title={t('没有批量订单')}
										subtitle={t('你还没有批量订单。')}
										subBtnTitle={t('添加微信订单')}
										subBtnClick={() => {
                      if (registrationStatus === RegistrationStatus.Unfinished) {
                        message.warning(t("请完成注册后操作！"))
                        // history.push('/register');
                      } else {
												if (userInfoState.id) {
													setIsModalVisible(true)
												} else {
													message.warning(t("您未登录，请登录后操作！"))
												}
											}
                    }}
									>
										{
											registrationStatus === RegistrationStatus.Finished ? (
												<UploadComponent
													// accept={each.accept ?? accept}
													action="remit-service/remit/order/importOrder"
													onChange={(e) => onFileChange(e)}
													maxCount={1}
													showUploadList={false}
													userId={userInfoState?.id || ''}
													accept=".xls,.xlsx"
													isNoMessage={true}
												>
													<div className={styles.addBtnNoData}>
														{t("添加银行订单")}
													</div>
												</UploadComponent>
											) : (
												<div className={styles.addBtnNoData} onClick={() => {
													if (registrationStatus === RegistrationStatus.Unfinished) {
														message.warning(t("请完成注册后操作！"))
														// history.push('/register');
													} else {
														if (!userInfoState.id) {
															message.warning(t("您未登录，请登录后操作！"))
														}
													}
												}}>
													{t("添加银行订单")}
												</div>
											)
										}
									</NoDataCom>
								)
							}
						</>
					)
				}
			</Spin>
			{
				isModalVisible && (
					<UploadWechatOrder
						setIsModalVisible={setIsModalVisible}
						getList={getList}
					/>
				)
			}
		</div>
	)
}

export default BatchOrder
