import React, { FC } from 'react'
import styles from './HeadComponent.module.scss'
import { useHistory } from 'react-router';
import logoImg from '@/assets/components/nav-logo.svg'
import {useTranslation} from "react-i18next";
// import HomeSVG from '@/assets/components/home.svg';
interface IHeadComponentProps{
  isHideHome?: boolean;
}

const HeadComponent: FC<IHeadComponentProps> = (props) => {
  const { isHideHome } = props || {};
  const history = useHistory();
  const {t, i18n} = useTranslation();
  return (
    <div className={styles.containerHead}>
      <div className={styles.headContent}>
        <img
          src={logoImg}
          className={styles.logo}
        />
        {
          !isHideHome && (
            <span className={styles.menuLi} onClick={() => history.push('/home')}>
              {/* <img className={styles.NavIcon} src={HomeSVG} style={{transform: 'translateY(1px)'}}/> */}
              {t('首页')}
            </span>
          )
        }
      </div>
    </div>
    
  )

}

export default HeadComponent
