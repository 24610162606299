import React, {FC, useEffect, useState, useRef} from 'react'
import {Button, message, Modal} from 'antd';
import {useTranslation} from "react-i18next";
import {history} from "@/route";
import {useMappedState, useDispatch} from "redux-react-hook";
import NoDataCom from "@/components/NoDataCom";
import Submitted from "@/assets/image/remittance/submitted.png";
import type {IState} from "@/redux/interface";
import {LeftOutlined} from '@ant-design/icons';
import {remittanceApi, userApi} from '@/api'
import BankTransfer from "@/assets/image/remittance/BankTransfer.png";
import {isMobile, formatCurrency, } from '@/utils/utils';
import {RemittanceContext} from "./RemittanceContext";
import type {PayMent} from "./RemittanceContext";
import SimpleSteps from "./components/Steps/Steps";
import Amount from "./components/Amount/Amount";
import Recipient from "./components/Recipient/Recipient";
import Reason from "./components/Reason/Reason";
import Confirm from "./components/Confirm/Confirm";
import styles from './Remittance.module.scss'
import {RegistrationStatus, Status} from '@/api/auth/auth'
import {allowCurrency} from '@/utils/defaultData'
import {SAVE_USER_INFO} from '@/redux/action'

interface IRemittanceProps {
  location: any;
}

interface IMargin {
  [key: number]: number
}

declare type Placement = 'horizontal' | 'vertical'

const Remittance: FC<IRemittanceProps> = (props) => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const [step, setStep] = useState<number>(0)
  const [finish, setFinish] = useState<boolean>(false)
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [placement, setPlacement] = useState<Placement>('horizontal')
  const [payment, setPayment] = useState<PayMent>({
    sendingAmount: '',
    sendingCurrency: 'KES',
    instAmount: '',
    exchangeRate: 0,
    instCurrency: 'CNY',
    paymentMethod: 3,
    deliveryMethod: 3,
    commission: 0,
    totalAmount: '',
    paymentTime: '',
    beneficiaryNo: '',
    remitPurpose: '',
    sourceOfFund: '',
    trnType: 'IT',
    trnSource: 2,
    recipientName: '',
    paymentMethodName: 'Bank Transfer',
    deliveryMethodName: 'Bank Transfer',
    remitPurposeName: '',
    sourceOfFundName: '',
    paymentMethodIcon: BankTransfer,
    deliveryMethodIcon: BankTransfer,
    paymentRate: 1,
    trnNo: '',
    id: undefined,
    formatSend: '',
    formatInst: '',
    inputType: 'number',
    productDescription: '',
    productPictures: ''
  })
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false)

  const rootRef = useRef(null);

  const allStep = [
    {label: t("汇款金额")},
    {label: t("收款人")},
    {label: t("付款信息")},
    {label: t("确认付款")}
  ]

  const createOrder = async () => {
    // const method = payment.paymentMethod === 2 ? remittanceApi.payMPESAToBank : remittanceApi.payBankToBank
    let method = null;
    if (payment.paymentMethod === 2) {
      method = remittanceApi.payMPESAToBank
    } else {
      if (payment.sendingCurrency === 'CNY' && payment.instCurrency === 'KES') {
        method = remittanceApi.payCnBank2KeBbank
      } else if (payment.sendingCurrency === 'CNY' && payment.instCurrency === 'KES') {
        method = remittanceApi.payKeBank2CnBank
      } else {
        method = remittanceApi.payBankToBank
      }
    }
    try {
      const data = await method({
        beneficiaryNo: payment.beneficiaryNo,
        commission: payment.commission,
        deliveryMethod: payment.deliveryMethod,
        paymentMethod: payment.paymentMethod,
        exchangeRate: payment.exchangeRate,
        fomoBeneRefNo: '',
        sendingAmount: +(payment.sendingAmount),
        sendingCurrency: payment.sendingCurrency,
        instAmount: +(payment.instAmount),
        instCurrency: payment.instCurrency,
        sourceOfFund: +(payment.sourceOfFund),
        remitPurpose: +(payment.remitPurpose),
        totalAmount: +(payment.sendingAmount) + payment.commission, // no fee
        trnType: payment.trnType,
        trnSource: payment.trnSource,
        productDescription: payment.productDescription,
        productPictures: payment.productPictures,
      })
      if (data?.trnNo) {
        setPayment({...payment, trnNo: data?.trnNo ?? '', id: data?.id})
        setConfirmVisible(true);
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const isAllowCurrency = (): boolean => {
    const {instCurrency, sendingCurrency} = payment;
    return allowCurrency.includes(instCurrency) && allowCurrency.includes(sendingCurrency)
  }

  const isDisabled = () => {
    let d = false
    const {instAmount, beneficiaryNo, sourceOfFund, remitPurpose} = payment;
    switch (step) {
      case 0:
        // d = !(Boolean(+instAmount) && isAllowCurrency())
        d = !(+instAmount)
        break
      case 1:
        d = !beneficiaryNo
        break
      case 2:
        d = !sourceOfFund || !remitPurpose
        break
      case 3:
        d = !agree
        break
    }
    return d
  }

  const margin: IMargin = {
    0: 180,
    1: 76,
    2: 220,
    3: 52
  }

  const toNextStep = () => {
    window.scrollTo({top: 0})
    setStep(step + 1)
  }

  const isOverLimit = (limit: boolean, title: string) => {
    if (limit) {
      confirmDel(title)
    } else {
      toNextStep()
    }
  }

  const confirmDel = (title: string) => {
    Modal.warning({
      title: t(title),
      okText: t('知道了'),
      maskClosable: true,
      closable: true,
      getContainer: () => rootRef.current ?? document.body,
      centered: true,
      width: 470,
    });
  }

  const limitAmount = (key: number, amount: number) => {
    if (key === 1) return amount > 150000 || amount < 100;
    if (key === 2) return amount > 18000 || amount < 100
    if (key === 3) return amount > 3000 || amount < 15
    if (key === 4) return amount > 340000 || amount < 1800
    return false
  }

  const getRate = async (localCurrency: string, foreignCurrency: string, key: string) => {
    const {list} = await remittanceApi.queryExchangeRate({
      localCurrency,
      foreignCurrency,
      custType: userInfoState.custType
    })
    if (list?.[0]) {
      const exchangeRate = list?.[0]?.exchangeRate ?? 1;
      const amount = (+payment.sendingAmount) / exchangeRate
      if (key === 'MPESA') {
        isOverLimit(limitAmount(1, amount), '抱歉，M-PESA转账到银行的的转账限额为100到15万肯尼亚先令')
      } else if (key === 'BANKTOBANK') {
        isOverLimit(limitAmount(3, amount), '抱歉，银行与银行间的转账限额为15到3000美元')
      }
    }
  }

  const step2AmountOver = () => {
    const {sendingCurrency, instCurrency, sendingAmount, deliveryMethod, paymentMethod} = payment;
    if (instCurrency !== 'KES' && deliveryMethod === 2) {
      message.warning(t('抱歉，M-PESA目前只支持肯尼亚国家'))
    } else if (deliveryMethod === 3 && paymentMethod === 2) {
      // MPESA TO BANK
      if (sendingCurrency === 'KES') {
        isOverLimit(limitAmount(1, +sendingAmount), 'M-PESA转账到银行的的转账限额为100到15万肯尼亚先令')
      } else if (sendingCurrency === 'CNY') {
        isOverLimit(limitAmount(2, +sendingAmount), '抱歉，M-PESA转账到银行的的转账限额为100到1万8千人民币')
      } else if (sendingCurrency === 'USD') {
        isOverLimit(limitAmount(3, +sendingAmount), '抱歉，M-PESA转账到银行的的转账限额为15到3千美元')
      } else {
        getRate('KES', sendingCurrency, 'MPESA')
      }
    } else if (deliveryMethod === 3 && paymentMethod === 3) {
      // BANK TO BANK
      if (sendingCurrency === 'USD') {
        isOverLimit(limitAmount(3, +sendingAmount), '抱歉，银行与银行间的转账限额为15到3000美元')
      } else if (sendingCurrency === 'CNY') {
        isOverLimit(limitAmount(2, +sendingAmount), '抱歉，银行与银行间的转账限额为100到1万8千人民币')
      } else if (sendingCurrency === 'KES') {
        isOverLimit(limitAmount(4, +sendingAmount), '银行与银行间的转账限额为1800到34万肯尼亚先令')
      } else {
        getRate('USD', sendingCurrency, 'BANKTOBANK')
      }
    } else {
      toNextStep();
    }
  }

  useEffect(() => {
    const d = props.location.state
    setPayment({
      ...payment,
      ...(d ?? {}),
      formatSend: formatCurrency(d?.sendingAmount),
      formatInst: formatCurrency(d?.instAmount),
      totalAmount: `${(+(payment.commission ?? 0) + +(d?.sendingAmount ?? 0))}`,
      inputType: d?.instAmount ? 'text' : 'number'
    })
  }, [])

  useEffect(() => {
    setPlacement(isMobile() ? 'vertical' : 'horizontal')
  }, [])

  useEffect(() => {
    const id = userInfoState?.id
    if (id && userInfoState?.registrationStatus === RegistrationStatus.Finished && userInfoState?.status !== Status.certified) {
      getNewUserInfo(id)
    }
  }, [])

  const getNewUserInfo = async (id: number) => {
    try {
      const res = await userApi.getUserInfo();
      dispatch({type: SAVE_USER_INFO, userInfo: res})
      window.localStorage.setItem('userInfo', JSON.stringify(res))
    } catch (error) {
      message.error(String(error))
    }
  }

  return (
    <RemittanceContext.Provider value={{
      confirmVisible, setConfirmVisible, step, setStep, payment, setPayment, finish, setFinish, agree, setAgree
    }}>
      {finish ?
        <NoDataCom
          title={t('转账已提交！')}
          subtitle={t('感谢您选择WapiPay。我们正在努力处理您的转账事宜。')}
          btnTitle={t('检查转账状态')}
          imgSrc={Submitted}
          btnClick={() => {
            history.push('/order')
          }}
        /> :
        <div className={styles.root} ref={rootRef}>
          {step !== 0 && <div className={styles.nav}>
            <span onClick={() => setStep(step - 1)} >
              <LeftOutlined style={{color: '#999999', marginRight: 5}} />
              <span>{t("返回")}</span>
            </span>
          </div>}
          <SimpleSteps current={step} steps={allStep} placement={placement} />
          {step === 0 && <Amount currency={props.location.state} placement={placement} />}
          {step === 1 && <Recipient setStep={toNextStep} />}
          {step === 2 && <Reason />}
          {step === 3 && <Confirm />}
          <div className={styles.btn} style={{marginBottom: isMobile() ? 0 : margin[step]}}>
            {step !== 1 && <Button
              type="primary"
              shape="round"
              className={`${styles.stepBtn}`}
              disabled={isDisabled()}
              loading={loading}
              onClick={() => {
                switch (step) {
                  case 0:
                    if (!userInfoState?.id) {
                      history.push('/login')
                      // message.warning(t('您未登录，请登录后操作！'))
                      return
                    }
                    if (userInfoState?.registrationStatus !== RegistrationStatus.Finished) {
                      message.warning(t('请完成注册后操作！'))
                      return
                    }
                    if (userInfoState?.status !== Status.certified) {
                      message.warning(t('对不起，您的账户正在审核中......'))
                      return
                    }
                    if (!isAllowCurrency()) {
                      message.warning(t('即将推出...'))
                      return
                    }
                    setPayment({...payment, inputType: 'text'})
                    toNextStep()
                    break;
                  case 2:
                    step2AmountOver();
                    break;
                  case 3:
                    // 支付为cash，不弹窗直接到成功页
                    // if (payment.paymentMethod === 'Cash') {
                    //   setFinish(true);
                    //   return
                    // }
                    setLoading(true);
                    createOrder()
                    break;
                  default:
                }
              }}>
              {t(step === 3 ? '立即汇款' : "下一步")}
            </Button>}
          </div>
        </div>
      }
    </RemittanceContext.Provider>

  )
}

export default Remittance
