import React, {FC, useState} from 'react'
import { Form, Input, message } from 'antd';
import './index.scss'
import { IDefaultSelectProps } from '../DefaultSelect/DefaultSelect'
import { ExclamationCircleOutlined, SyncOutlined  } from '@ant-design/icons';
import { sendSms } from '@/api/register';
import {useTranslation} from "react-i18next";
import {verifyTencentCaptcha} from '@/utils/mainUtils'

interface ISendCodeComProps {
	sendLoading?: boolean;
	setSendLoading?: (flag: boolean) => void;
	sendCallback?: (res: any) => void;
	form?: any;
	submitApi?: Function;
	haveForm?: boolean;
	phoneNum?: number | string;
}

const SendCodeCom: FC<ISendCodeComProps & IDefaultSelectProps> = (props) => {
	const { sendLoading, setSendLoading, sendCallback, form, submitApi = sendSms, haveForm = true, phoneNum } = props;
  const {t, i18n} = useTranslation();
  const [haveCode, setHaveCode] = useState<boolean>(true);
  const [isCodeFocus, setIsCodeFocus] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const timeCount = 60
  let timer = timeCount

	const sendCode = async () => {
    if (count || sendLoading) return;
    const { userName, prefix } = await form.getFieldsValue()
    if (!userName) {
      message.warning(t('请输入手机号码'));
      return;
    }
    try {
      const {randstr, ticket} = await verifyTencentCaptcha() as any
      setSendLoading?.(true);
			let phoneVal = Number(prefix + '' + userName);
			if (!haveForm) phoneVal = Number(phoneNum);
      let params = {
        randstr,
        ticket,
        phone: phoneVal
      }
      const res: any = await submitApi(params) || {}
      message.success(t("发送成功"))
			sendCallback?.(res);
      countTime()
      setSendLoading?.(false);
    } catch (error) {
      // message.error(t("发送失败"))
      setSendLoading?.(false);
    }
  }

	const countTime = () => {
    timer = timeCount
    setCount(timer)
    const interval = setInterval(() => {
      if (timer <= 0) {
        clearInterval(interval)
      } else {
        timer--
        setCount(timer)
      }
    }, 1000)
  }

  return (
    <div className={`form-item-warp verification-code ${!haveCode && 'form-item-tips'} ${isCodeFocus && 'form-item-focus'}`}>
			<Form.Item
				name="smsCode"
				className='sms-code-item'
			>
				<Input
					maxLength={6}
					placeholder={t('验证码')}
					type="number"
					onChange={(e) => {
						const { value } = e.target || {};
    				setHaveCode(value ? true : false)
					}}
					onFocus={() => setIsCodeFocus(true)}
					onBlur={() => setIsCodeFocus(false)}
				/>
			</Form.Item>
			<div className={`code-box ${count && 'code-time-box'}`} onClick={sendCode}>
				{
					!sendLoading ? count ? `${count	}s${t("后重发")}` : t('发送验证码')
					: (
						<SyncOutlined spin />
					)
				}
			</div>
			{
				!haveCode && (
					<span className='tips'>
						<ExclamationCircleOutlined /> {t('请输入您的验证码！')}
					</span>
				)
			}
		</div>
  )
}

export default SendCodeCom
