import React, {FC, useEffect, useMemo, useState} from 'react'
import {useTranslation} from "react-i18next";
import {message, Pagination, Input, Spin} from 'antd';
import Add from "@/assets/image/remittance/Add.png";
import CNY from "@/assets/image/login/China.png";
import KES from "@/assets/image/login/Kenya.png";
import USD from "@/assets/image/login/UnitedStates.png";
import Email from "@/assets/image/remittance/email.png";
import Phone from "@/assets/image/remittance/phone.png";
import Search from "@/assets/image/remittance/search.svg";
import cls from "classnames";
import {LinkOutlined} from '@ant-design/icons';
import UpdateRecipient from "@/pages/Recipient/components/UpdateRecipient";
import InviteLink from "@/pages/Recipient/components/InviteLink";
import {useRemittanceContext} from "@/pages/Remittance/RemittanceContext";
import {recipientApi} from '@/api'
import {useMappedState} from "redux-react-hook";
import {IState} from "@/redux/interface";
import type {IRecipientRow} from "@/api/recipient/recipient";
import styles from "./Recipient.module.scss";
import _ from 'lodash'


interface IRecipientProps {
  setStep?: () => void
}

export interface Ct {
  value: string;
  icon: string;
}

export interface CountryType {
  '1': Ct;
  '2': Ct;
  '3': Ct;
  [key: string]: any
}

const country: CountryType = {
  '1': {
    value: 'CNY',
    icon: CNY
  },
  '2': {
    value: 'KES',
    icon: KES
  },
  '3': {
    value: 'USD',
    icon: USD
  },
}

const nation: Record<string, string> = {
  CNY: '1',
  KES: '2',
  USD: '3',
  ZK: '6',
  USH: '4',
  CEDI: '5',
}

const Recipient: FC<IRecipientProps> = ({setStep}) => {
  const {t} = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [linkVisible, setLinkVisible] = useState(false);
  const [recipients, setRecipients] = useState<IRecipientRow[]>([]);
  const [loading, setLoading] = useState(false);
  const {payment, setPayment} = useRemittanceContext();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 6,
    total: 0,
    name: '',
    showTotal: (total: any) => `Total ${total} recipient`,
  });
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const {slug} = userInfoState || {};
  const fullName = `${userInfoState?.firstName}-${userInfoState?.lastName}`;
  const slugLink = `${slug}&fullName=${fullName}`;
  const getRecipients = async () => {
    try {
      setLoading(true)
      const {current: pageNum = 1, pageSize = 6, name} = pagination
      const d = payment.deliveryMethod
      const receive = d === 2 || d === 3 ? {receiveType: d} : {}
      const {total, list} = await recipientApi.queryRecipients({
        Status: 0, pageSize, pageNum, ...receive, country: nation[payment.instCurrency] ?? '', name
      })
      setRecipients(list ?? [])
      setPagination({...pagination, total})
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getRecipients()
  }, [pagination.current, pagination.name])

  useMemo(() => {
    document.body.style.overflow = isModalVisible ? 'hidden' : 'auto'
  }, [isModalVisible])

  const handleChange = _.debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    pagination.current = 1
    pagination.name = e.target.value
    setPagination({...pagination})
  }, 500)

  return (
    <Spin spinning={loading} tip="Loading">
      <div className={styles.recipientRoot}>
        <div className={styles.title}>{t("请选择收款人")}</div>
        <div className={styles.select} onClick={() => {setIsModalVisible(true)}}>
          <img src={Add} alt="" />
          <span>{t("添加收款人")}</span>
        </div>
        <div className={`flex-all-center ${styles.link}`} onClick={() => {setLinkVisible(true)}} >
          <LinkOutlined style={{fontSize: 20}} /><span style={{paddingLeft: 18}}>{t("通过链接邀请")}
          </span>
        </div>
        <div className={styles.tips}>{t("从已有收款人选择")}</div>
        <Input
          prefix={<img src={Search} />}
          placeholder='Search people'
          size='large'
          className={styles.inputSearch}
          onChange={handleChange}
        />
        {
          recipients.map((v, i) =>
            <div
              key={i}
              className={cls(styles.recipient, v.beneficiaryNo === payment.beneficiaryNo ? styles.active : '')}
              onClick={() => {
                setPayment({
                  ...payment,
                  recipientName: `${v.firstName ?? ''} ${v.lastName ?? ''}`,
                  beneficiaryNo: v.beneficiaryNo
                })
                setStep?.()
              }}
            >
              <div className={styles.leftInfo}>
                <div className={styles.name}>{`${v.firstName ?? ''} ${v.lastName ?? ''}`}</div>
                <div className={styles.center}>
                  <img src={Email} alt="" />
                  <span>{v.email ?? ''}</span>
                </div>
                <div className={styles.center}>
                  <img src={Phone} alt="" />
                  <span>{v.handPhone ?? ''}</span>
                </div>
              </div>
              <div className={styles.rightInfo}>
                <img src={country[v.country].icon} alt="" />
                <span>{country[v.country].value}</span>
              </div>
            </div>)
        }
        {pagination.total > 4 && <div className={styles.pageSizeBox}>
          <Pagination
            onChange={(current: number) => {
              setPagination({...pagination, current})
            }}
            {...pagination}
          />
        </div>}
        {isModalVisible && <UpdateRecipient
          setIsModalVisible={setIsModalVisible}
          currentRecipient={false}
          pageSize={6}
          getList={getRecipients}
          receiveType={payment.deliveryMethod}
          country={nation[payment.instCurrency]}
          onSuccess={(firstName: string, lastName: string, beneficiaryNo: string) => {
            setPayment({
              ...payment,
              recipientName: `${firstName ?? ''} ${lastName ?? ''}`,
              beneficiaryNo: beneficiaryNo
            })
          }}
        />}
        {linkVisible && <InviteLink setVisible={setLinkVisible} slugLink={slugLink} />}
      </div>
    </Spin>
  )
}


export default Recipient;