import React, { FC, useState, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import { countryOptions } from '@/utils/defaultData'
import { useHistory } from 'react-router';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { DefaultInput } from '@/components';
import { checkDisabled } from '@/utils/mainUtils'
import './index.scss'
import {useTranslation} from "react-i18next";
import pinIcon from '@/assets/image/resetPin/pin-icon.png';
import { forgetPasswordApi } from '@/api/register';
import HeadComponent from '@/components/HeadComponent/HeadComponent';
import FooterComponent from '@/components/FooterComponent';
import BackIcon from '@/assets/image/login/back-icon.png';
import { IState } from '@/redux/interface';
import { useDispatch, useMappedState } from 'redux-react-hook';
import SendCodeCom from '@/components/SendCodeCom';
import {isMobile, signOut} from '@/utils/utils'
import { DELETE_USER_INFO } from '@/redux/action';

const { Option } = Select;

interface IForgetPasswordProps{
	
}

const ForgetPassword: FC<IForgetPasswordProps> = (props) => {
  const {t, i18n} = useTranslation();
  const userInfoState = useMappedState(
    (state: IState) => state.UserInfoReducer.userInfo
  );
  const dispatch = useDispatch();
  const isLogin = userInfoState?.id ? true : false;
  const [form] = Form.useForm();
  const [havePhone, setHavePhone] = useState<boolean>(true);
  const [isPhoneFocus, setIsPhoneFocus] = useState<boolean>(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false)
	const [isUpdate, setIsUpdate] = useState(false);
  const [sendLoading, setSendLoading] = useState(false)

	const onFinish = async (values: any) => {
		try {
      const { password, confirmPassword, userName, smsCode, prefix } = values || {};
      if (password !== confirmPassword) {
        message.warning(t('您的密码和确认密码不一致。'))
        return;
      }
      const phoneVal = isLogin ? Number(userInfoState?.userName) : Number(prefix + '' + userName);
      const formData = { 
        userName: phoneVal,
        smsCode,
        password
      };
      setLoading(true)
      const res = await forgetPasswordApi(formData) || {};
      message.success(t('更新成功'))
      signOut();
      dispatch({type: DELETE_USER_INFO});
      history.replace('/login')
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
	}

	const onInputChange = (e: any, fn: (flag: boolean) => void) => {
    const { value } = e.target || {};
    fn(value ? true : false)
  }

  const onInputFocus = (fn: (flag: boolean) => void, flag: boolean) => {
    fn(flag)
  }

  useEffect(() => {
    if (userInfoState) {
      form.setFieldsValue({ userName: `+${userInfoState?.userName}` })
    }
  }, [form, userInfoState])

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ minWidth: 80 }}
        optionLabelProp="label"
        dropdownMatchSelectWidth={false}
        dropdownStyle={{ width: 250 }}
        onFocus={() => onInputFocus(setIsPhoneFocus, true)}
        onBlur={() => onInputFocus(setIsPhoneFocus, false)}
        onSelect={() => onInputFocus(setIsPhoneFocus, false)}
      >
        { 
          countryOptions.map((item: any) => (
            <Option
              value={item.value}
              key={`${item.value}`}
              label={
                <div className='flex-center'>
                  <img
                    src={item.icon}
                    className="country-logo"
                    style={{ width: 21, marginRight: 5 }}
                    alt=''
                  /> <span>{item.code}</span>
                </div>
              }
            >
              <div className='flex-center'>
                <img
                  src={item.icon}
                  className="country-logo"
                  style={{ width: 24, marginRight: 8 }}
                  alt=''
                /> {item.label} {item.code}
              </div>
            </Option>
          ))
        }
      </Select>
    </Form.Item>
  );

  return (
    <div className='reset-pin-page login-form-container'>
      {!isLogin && <HeadComponent />}
      <div className='register-wrapper'>
        {
          !isMobile() && !isLogin && (
            <div className='go-back' onClick={() => history.goBack()}>
              <img src={BackIcon} className='back-icon' alt='' /> Back
            </div>
          )
        }
        <div className='register-box'>
					<img src={pinIcon} alt='' className='pin-icon' />
          <div className='register-box-title'>{t('重置密码')}</div>
          <Form form={form} onValuesChange={() => setIsUpdate(!isUpdate)} initialValues={{ prefix: 254 }} onFinish={onFinish} className="login-form">
          <div className='form-item-warp'>
              <Form.Item
                name="userName"
                label=""
                className={`${!havePhone && 'form-item-tips'} ${isPhoneFocus && 'form-item-focus'}`}
              >
                <Input
                  placeholder= {t('手机号码')}
                  addonBefore={isLogin ? '' : prefixSelector}
                  className='phone-input'
                  disabled={isLogin}
                  onChange={(e) => {
                    onInputChange(e, setHavePhone)
                    // const { value } = e.target || {};
                    // form.setFieldsValue({ userName: value.replace(/[^\d]|^[0]/g,'') })
                  }}
                  type={isLogin ? 'text' : "number"}
                  onFocus={() => onInputFocus(setIsPhoneFocus, true)}
                  onBlur={() => {
                    onInputFocus(setIsPhoneFocus, false)
                    const phone = form.getFieldValue('userName')
                    if (phone && phone.charAt(0) === '0') {
                      form.setFieldsValue({userName: phone.slice(1)})
                    }
                  }}
                />
              </Form.Item>
              {
                !havePhone && (
                  <span className='tips'>
                    <ExclamationCircleOutlined /> {t('请输入您的手机号！')}
                  </span>
                )
              }
            </div>
            <SendCodeCom
              form={form}
              sendLoading={sendLoading}
              setSendLoading={setSendLoading}
              phoneNum={userInfoState?.userName}
              haveForm={!isLogin}
            />
            <DefaultInput
              formItemName="password"
              placeholder={t('密码')}
              formItemTips={t('请输入您的密码！')}
              tipsIsAbsolute={true}
              isPassword
              notes={t('密码应至少包含8个字符，包括1个大写字母、1个小写字母和1个数字。')}
            />
            <DefaultInput
              formItemName="confirmPassword"
              formItemTips={t('请确认您的密码')}
              placeholder={t('确认密码')}
              isPassword
            />
            <Button loading={loading} disabled={!checkDisabled(form,  isLogin ? 4 : 5)} type="primary" htmlType="submit" className='submit-button'>
              {t('提交')}
            </Button>
          </Form>
        </div>
      </div>
      {/* {!isLogin && <FooterComponent />} */}
		</div>
  )
}

export default ForgetPassword
