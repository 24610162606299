import React, {useContext} from 'react';
import type {SelectValue} from "antd/lib/select/index.d";

export interface PayMent {
  sendingAmount: string;
  sendingCurrency: string;
  instAmount: string;
  instCurrency: string;
  paymentMethod: number;
  deliveryMethod: number;
  paymentRate: number;
  exchangeRate: number;
  commission: number;
  totalAmount: string;
  trnNo: string;
  id: number | undefined;
  remitPurpose: string | number;
  paymentTime: string;
  recipientName: string;
  beneficiaryNo: string;
  sourceOfFund: string | number;
  trnType: string;
  trnSource: number;
  paymentMethodName: string;
  deliveryMethodName: string;
  remitPurposeName: string;
  sourceOfFundName: string;
  paymentMethodIcon: string | null;
  deliveryMethodIcon: string | null;
  [key: string]: any
}

export interface RemittanceContextProps {
  confirmVisible: boolean;
  setConfirmVisible: (t: boolean) => void;
  step: number;
  setStep: (t: number) => void;
  payment: PayMent;
  setPayment: (t: PayMent) => void;
  finish: boolean;
  setFinish: (t: boolean) => void;
  agree: boolean;
  setAgree: (t: boolean) => void;
}

export const RemittanceContext = React.createContext<RemittanceContextProps>({
  confirmVisible: false,
  setConfirmVisible: () => {},
  step: 0,
  setStep: () => {},
  payment: {
    sendingAmount: '',
    sendingCurrency: '',
    instAmount: '',
    instCurrency: '',
    paymentMethod: 3,
    deliveryMethod: 3,
    exchangeRate: 1,
    commission: 0,
    totalAmount: '',
    remitPurpose: '',
    paymentTime: '',
    beneficiaryNo: '',
    sourceOfFund: '',
    trnType: '',
    trnSource: 2,
    paymentRate: 1,
    recipientName: '',
    paymentMethodName: 'M-PESA',
    deliveryMethodName: 'Bank Transfer',
    remitPurposeName: '',
    sourceOfFundName: '',
    paymentMethodIcon: '',
    deliveryMethodIcon: '',
    trnNo: '',
    id: undefined
  },
  setPayment: () => {},
  finish: false,
  setFinish: () => {},
  agree: false,
  setAgree: () => {},
})

export function useRemittanceContext() {
  return useContext(RemittanceContext);
}